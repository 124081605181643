import cx from 'clsx';

import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import { ReactComponent as RotateIcon } from 'assets/icons/rotate.svg';
import { InteractionMode } from 'features/lineupProducts';

import css from './InteractionModeSwitch.module.scss';

type Props = {
  mode: InteractionMode;
  onModeChange(mode: InteractionMode): void;
};

export function InteractionModeSwitch(props: Props) {
  const { mode, onModeChange } = props;
  return (
    <div className={css.modeSwitch}>
      <button
        className={cx(css.modeButton, mode === 'move' && css.activeModeButton)}
        onClick={() => onModeChange('move')}
        type="button"
      >
        <MoveIcon className={css.moveIcon} />
      </button>
      <button
        className={cx(
          css.modeButton,
          mode === 'rotate' && css.activeModeButton
        )}
        onClick={() => onModeChange('rotate')}
        type="button"
      >
        <RotateIcon className={css.rotateIcon} />
      </button>
    </div>
  );
}

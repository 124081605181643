import { createAction } from 'deox';
import { Background } from 'aspects/api/modules/Backgrounds/types';
import {
  createMultiRemoteDataActions,
  createRemoteDataActions,
} from 'shared/remoteData';
import { TemplateInfo } from 'aspects/api/modules/Templates/types';

export const loadBackgrounds = createAction('BRIEF_TEMPLATE:LOAD_BACKGROUNDS');

export const loadBackgroundsActions = createRemoteDataActions<Background[]>(
  'BRIEF_TEMPLATE:LOAD_BACKGROUNDS_REQUEST',
  'BRIEF_TEMPLATE:LOAD_BACKGROUNDS_SUCCESS',
  'BRIEF_TEMPLATE:LOAD_BACKGROUNDS_FAILED'
);

export const loadTemplatesInfo = createAction(
  'BRIEF_TEMPLATE:LOAD_TEMPLATES_INFO'
);

export const loadTemplatesInfoActions = createRemoteDataActions<TemplateInfo[]>(
  'BRIEF_TEMPLATE:LOAD_TEMPLATES_INFO_REQUEST',
  'BRIEF_TEMPLATE:LOAD_TEMPLATES_INFO_SUCCESS',
  'BRIEF_TEMPLATE:LOAD_TEMPLATES_INFO_FAILED'
);

export const uploadBackground = createAction(
  'BRIEF_TEMPLATE:UPLOAD_BACKGROUND',
  (resolve) => (payload: {
    background: string;
    name: string;
    backgroundWidth: number;
    backgroundHeight: number;
  }) => resolve(payload)
);

export const deleteBackground = createAction(
  'BRIEF_TEMPLATE:DELETE_BACKGROUND',
  (resolve) => (payload: { id: number }) => resolve(payload)
);

export const uploadBackgroundActions = createRemoteDataActions(
  'BRIEF_TEMPLATE:UPLOAD_BACKGROUND_REQUEST',
  'BRIEF_TEMPLATE:UPLOAD_BACKGROUND_SUCCESS',
  'BRIEF_TEMPLATE:UPLOAD_BACKGROUND_FAILED',
  'BRIEF_TEMPLATE:UPLOAD_BACKGROUND_RESET'
);

export const turnIntoTemplate = createAction(
  'BRIEF_TEMPLATE:TURN_INTO_TEMPLATE',
  (resolve) => (payload: {
    templateId: string;
    name?: string;
    comment?: string;
  }) => resolve(payload)
);

export const selectBackground = createAction(
  'BRIEF_TEMPLATE:SELECT_BACKGROUND',
  (resolve) => (payload: {
    width: number;
    height: number;
    backgroundUrl: string;
  }) => resolve(payload)
);

export const turnIntoTemplateActions = createMultiRemoteDataActions(
  'BRIEF_TEMPLATE:TURN_INTO_TEMPLATE_REQUEST',
  'BRIEF_TEMPLATE:TURN_INTO_TEMPLATE_SUCCESS',
  'BRIEF_TEMPLATE:TURN_INTO_TEMPLATE_FAILED',
  'BRIEF_TEMPLATE:TURN_INTO_TEMPLATE_RESET'
);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as D from '@domain/template';
import { LineupProductsState } from './types';

export function updateLayersAccordingToProjectObjects(
  layers: D.Layer[],
  projectObjects: D.ProjectObject[]
): D.Layer[] {
  let nextProjectObjectIndexToTake = 0;

  return layers.map((layer) => {
    const areAllProjectObjectsApplied =
      nextProjectObjectIndexToTake === projectObjects.length;
    if (areAllProjectObjectsApplied) return layer;

    const updatedObjects = layer.objects.map((x) => {
      if (D.isProductLayerObject(x) && !areAllProjectObjectsApplied) {
        const projectObject = projectObjects[nextProjectObjectIndexToTake];
        // eslint-disable-next-line no-plusplus
        nextProjectObjectIndexToTake++;
        return {
          ...x,
          id: projectObject.id,
          assetId: projectObject.assetId,
          artworkId: projectObject.artworkId,
          imgUrl: projectObject.imgUrl,
        } as D.ProductLayerObject;
      }
      return x;
    });

    return {
      ...layer,
      objects: updatedObjects,
    };
  });
}

export function selectCurrentProject(
  state: LineupProductsState['data'],
  projectId: string
): D.Project | undefined {
  const { projects } = state;
  return projectId !== null ? projects[projectId] : undefined;
}

export function selectProject(
  state: LineupProductsState['data']
): D.Project | undefined {
  const { projects, selectedTemplateId } = state;
  return selectedTemplateId !== null ? projects[selectedTemplateId] : undefined;
}

export function updateCurrentProject(
  state: LineupProductsState['data'],
  projectId: string,
  updateFn: (prevProject: D.Project) => D.Project
): LineupProductsState['data'] {
  const currentProject = selectCurrentProject(state, projectId);

  if (currentProject === undefined) {
    // eslint-disable-next-line no-console
    console.error('Current project is undefined!');
    return state;
  }

  const updatedProject = updateFn(currentProject);

  if (updatedProject === currentProject) {
    return state;
  }

  return {
    ...state,
    projects: {
      ...state.projects,
      [currentProject.template.id]: updatedProject,
    },
  };
}

export function selectProjectObjectLayerSetLevelChanges(
  project: D.Project,
  objectId: string,
  maybeLayerSetId?: string,
  maybeObjectsChangesInLayerSets?: D.Project['objectsChangesInLayerSets']
): D.LayerSetLevelObjectChanges {
  const layerSetId = maybeLayerSetId || project.selectedLayerSetId;
  const objectsChangesInLayerSets =
    maybeObjectsChangesInLayerSets || project.objectsChangesInLayerSets;

  return objectsChangesInLayerSets[layerSetId][objectId];
}

export type ProjectObjectsWithChanges = [
  D.ProjectObject,
  D.LayerSetLevelObjectChanges
];

export function selectProjectObjectsWithChanges(project: D.Project) {
  const { selectedLayerSetId, objectsChangesInLayerSets } = project;
  return project.objects.map(
    (x): ProjectObjectsWithChanges => [
      x,
      objectsChangesInLayerSets[selectedLayerSetId][x.id],
    ]
  );
}

export function selectProjectObjectWithChanges(
  project: D.Project,
  object: D.ProjectObject,
  objects?: D.ProjectObject[]
): ProjectObjectsWithChanges;
export function selectProjectObjectWithChanges(
  project: D.Project,
  objectId: string,
  objects?: D.ProjectObject[]
): ProjectObjectsWithChanges;
export function selectProjectObjectWithChanges(
  project: D.Project,
  source: string | D.ProjectObject,
  objects?: D.ProjectObject[]
): ProjectObjectsWithChanges {
  const { selectedLayerSetId, objectsChangesInLayerSets } = project;
  if (typeof source === 'string') {
    const projectObject = (objects || project.objects).find(
      (x) => x.id === source
    )!;
    return [
      projectObject,
      objectsChangesInLayerSets[selectedLayerSetId!][projectObject.id],
    ];
  }
  return [source, objectsChangesInLayerSets[selectedLayerSetId][source.id]];
}

import { pxsToUnits } from '@domain/pxsToUnits';

export type ViewerRectDimensions = ReturnType<typeof getRectDimensionsInUnits>;

export function getRectDimensionsInUnits(
  width: number,
  height: number
): [width: number, height: number] {
  return [pxsToUnits(width, height), 100];
}

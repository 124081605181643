/* eslint-disable camelcase */
import { boundMethod } from 'autobind-decorator';

import { HttpActions } from '../../HttpActions';
import { Url } from './types';

export class Urls {
  private actions: HttpActions;

  constructor(actions: HttpActions) {
    this.actions = actions;
  }

  @boundMethod
  async updateUrl(url: string): Promise<Url> {
    const response = await this.actions.put<Url>('/urls', { url });

    if (response.status !== 200) {
      throw new Error('Failed to update url.');
    }

    return response.data;
  }
}

import cx from 'clsx';
import { connect } from 'react-redux';

import {
  lineupProductsActions,
  lineupProductsSelectors,
} from 'features/lineupProducts';
import { AppState } from 'store/types';
import { isBriefingTemplate } from '@domain/template';

import css from './TemplatesList.module.scss';

type Props = ReturnType<typeof mapState> & typeof actionsMap;

function TemplatesListView(props: Props) {
  const { selectedTemplateId, selectTemplate, templatesGroup } = props;
  const templateGroups = templatesGroup ? [templatesGroup] : [];

  return (
    <div className={css.templatesList}>
      {templateGroups.map((x) => (
        <div className={css.templateGroup} key={x.id}>
          {x.templates.length === 0 ? (
            <p className={css.noTemplatesMessage}>There are no templates.</p>
          ) : (
            <div className={css.templates}>
              {x.templates.map((t) => (
                <div
                  title={t.id}
                  onClick={() =>
                    selectTemplate({
                      templateId: t.id,
                      isBriefing: isBriefingTemplate(t),
                    })
                  }
                  key={t.id}
                  style={{ backgroundImage: `url(${t.template.thumbnail})` }}
                  className={cx(
                    css.templateThumbnail,
                    x.id === selectedTemplateId && css.selectedTemplate
                  )}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function mapState(state: AppState) {
  return {
    templatesGroup: lineupProductsSelectors.selectSelectedTemplatesGroup(state),
    selectedTemplateId: lineupProductsSelectors.selectSelectedTemplateId(state),
  };
}

const actionsMap = {
  selectTemplate: lineupProductsActions.selectTemplate,
};

export const TemplatesList = connect(mapState, actionsMap)(TemplatesListView);

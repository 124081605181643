/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as R from 'ramda';
import { Dispatch } from 'redux';
import createDebug from 'debug';

import { makeUrl } from 'environment';
import {
  ProductLayerObject,
  Shape,
  Project,
  ProjectObject,
  ShapeBoundingBoxDetails,
  pickProductLayerObjects,
} from '@domain/template';
import { RawOrder, ShapeAsset } from '@domain/order';
import { pxsToUnits } from '@domain/pxsToUnits';
import {
  ConfigurableShapeSettings,
  setViewerSettings,
  getViewer,
  ViewerSetupSettings,
} from './getBase64Image';
import { selectProjectObjectWithChanges } from '../redux/reducerHelpers';
import { prepareBackgroundForShape } from './viewerRendering/prepareBackgroundForShape';
import {
  SceneDimensions,
  insertObjectPositionRelatedSettings,
} from './viewerRendering/renderObjectOnBackground';
import { updateObject } from '../redux/actions';
import { getRectDimensionsInUnits } from './viewerRendering/getRectDimensionsInUnits';
import { InteractionMode } from '../redux/types';

const baseConfigurableSettings: ConfigurableShapeSettings = {
  backgroundImageUrl: null,
  // backgroundColor: 'ffffff',
  scale: 1,
  reflection: 0,
  shadow: 0,
  rotation: 0,
  angle: {
    horizontal: 0,
    vertical: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
};

export function getViewerSetupSettings(asset: ShapeAsset): ViewerSetupSettings {
  return {
    shape360Config: R.clone(asset.shape360Config),
    mp4Url: makeUrl(asset.mp4Url),
    webMUrl: makeUrl(asset.webMUrl),
    uvMappingUrls: asset.uvMappingUrls,
  };
}

export function makeObjectContainerId(selectedLayerSetId: string, id: string) {
  return `viewer-instance-${selectedLayerSetId}-${id}`;
}

type ViewerPack = [Viewer, Shape360Api, ShapeAsset];
const objectToViewerMap = new Map<string, ViewerPack>();

async function initViewer(
  objectContainerId: string,
  asset: ShapeAsset,
  container: HTMLDivElement,
  configurableSettings: ConfigurableShapeSettings
) {
  const viewerPack = await getViewer(getViewerSetupSettings(asset), container);
  const [viewer, shape] = viewerPack;

  await new Promise<void>((resolve) => {
    requestAnimationFrame(async () => {
      await viewer.renderStep();
      await setViewerSettings(viewer, shape, asset, configurableSettings);
      await viewer.renderStep();
      requestAnimationFrame(() => {
        const backgroundLayer = container?.querySelectorAll(
          '[data-name=background]'
        )?.[0] as HTMLDivElement;
        if (backgroundLayer) {
          backgroundLayer.style.display = 'none';
        }
        resolve();
      });
    });
  });

  viewer.startRenderLoop();

  objectToViewerMap.set(objectContainerId, [viewer, shape, asset]);

  return objectToViewerMap.get(objectContainerId) as ViewerPack;
}

const logRemoveViewer = createDebug('[removeViewer]');

export function removeViewer(objectContainerId: string) {
  if (objectToViewerMap.has(objectContainerId)) {
    logRemoveViewer('Removing viewer...', objectContainerId);
    const [viewer] = objectToViewerMap.get(objectContainerId)!;
    viewer.destroy();
    objectToViewerMap.delete(objectContainerId);
  }
}

export function removeAllViewers() {
  logRemoveViewer('Removing all viewers...');
  objectToViewerMap.forEach(([viewer]) => {
    viewer.destroy();
  });
  objectToViewerMap.clear();
}

export async function updateViewerSettings(
  objectId: string,
  selectedLayerSetId: string,
  configurableSettings: ConfigurableShapeSettings
) {
  const objectContainerId = makeObjectContainerId(selectedLayerSetId, objectId);
  if (objectToViewerMap.has(objectContainerId)) {
    const [viewer, shape, asset] = objectToViewerMap.get(objectContainerId)!;

    await setViewerSettings(viewer, shape, asset, configurableSettings);
    viewer.renderStep();
  }
}

export async function enableUpdatesFromShapeInteractions(
  projectId: string,
  objectId: string,
  selectedLayerSetId: string,
  dispatch: Dispatch,
  selectObject: (objectId: string) => ProductLayerObject | undefined,
  objectBaseHeight: number,
  sceneWidth: number,
  sceneHeight: number,
  shouldEnableBoundaries = true
) {
  const objectContainerId = makeObjectContainerId(selectedLayerSetId, objectId);
  if (objectToViewerMap.has(objectContainerId)) {
    const [viewer, shape] = objectToViewerMap.get(objectContainerId)!;

    shape.onViewAngleChanged$.subscribe({
      next: (viewAngle) =>
        dispatch(updateObject({ objectId, projectId, angle: viewAngle })),
    });
    shape.onPositionChanged$.subscribe({
      next: (position: { x: number; y: number }) => {
        const finalPosition: Partial<{ x: number; y: number }> = {};

        const object = selectObject(objectId);

        if (!object) return;

        if (shouldEnableBoundaries) {
          const positionBoundaries = calcPositionBoundaries(
            object,
            objectBaseHeight,
            sceneWidth,
            sceneHeight
          );

          if (
            position.x <= positionBoundaries.rightX &&
            position.x >= positionBoundaries.leftX
          ) {
            finalPosition.x = position.x;
          }

          if (
            position.y <= positionBoundaries.bottomY &&
            position.y >= positionBoundaries.topY
          ) {
            finalPosition.y = position.y;
          }
        } else {
          finalPosition.x = position.x;
          finalPosition.y = position.y;
        }

        if (R.has('x', finalPosition) || R.has('y', finalPosition)) {
          dispatch(
            updateObject({
              objectId,
              projectId,
              position: { ...shape.position, ...finalPosition },
            })
          );
        }
      },
    });

    await viewer.renderStep();
  }
}

function calcPositionBoundaries(
  object: ProductLayerObject,
  objectBaseHeight: number,
  sceneWidth: number,
  sceneHeight: number
) {
  const {
    movementBoundaries: [left, up, right, down],
    insertionPoint: [x, y, depthScale],
  } = object;

  const bounds = {
    leftX: x + left,
    rightX: x + right,
    topY: y + up,
    bottomY: y + down,
  };

  const [sceneWidthUnits, sceneHeightUnits] = getRectDimensionsInUnits(
    sceneWidth,
    sceneHeight
  );

  const objectHeight = objectBaseHeight * depthScale;

  const positionBoundaries = {
    leftX: pxsToUnits(bounds.leftX, sceneHeight) - sceneWidthUnits / 2,
    rightX: pxsToUnits(bounds.rightX, sceneHeight) - sceneWidthUnits / 2,
    topY:
      pxsToUnits(bounds.topY, sceneHeight) -
      pxsToUnits(objectHeight / 2, sceneHeight) -
      sceneHeightUnits / 2,
    bottomY:
      pxsToUnits(bounds.bottomY, sceneHeight) -
      pxsToUnits(objectHeight / 2, sceneHeight) -
      sceneHeightUnits / 2,
  };

  return positionBoundaries;
}

const interactionModeToShape360Mode: Record<
  InteractionMode,
  Shape360InteractionMode
> = {
  move: 'transform',
  rotate: 'viewAngle',
  none: 'none',
};

export async function changeShapeInteractionMode(
  objectId: string,
  selectedLayerSetId: string,
  interactionMode: InteractionMode
) {
  const objectContainerId = makeObjectContainerId(selectedLayerSetId, objectId);
  if (objectToViewerMap.has(objectContainerId)) {
    objectToViewerMap.forEach(([_, shape]) => {
      shape.setInteractionMode('none');
    });

    const [viewer, shape] = objectToViewerMap.get(objectContainerId)!;
    shape.setInteractionMode(interactionModeToShape360Mode[interactionMode]);
    shape.setPosition(shape.position);
    viewer.renderStep();
  }
}

export async function enableInteractionModeOnShape(
  objectId: string,
  selectedLayerSetId: string,
  interactionMode: InteractionMode
) {
  const objectContainerId = makeObjectContainerId(selectedLayerSetId, objectId);
  if (objectToViewerMap.has(objectContainerId)) {
    objectToViewerMap.forEach(([, shape]) => {
      shape.setInteractionMode('none');
    });

    const [, shape] = objectToViewerMap.get(objectContainerId)!;
    shape.setInteractionMode(interactionModeToShape360Mode[interactionMode]);
    shape.setPosition(shape.position);
  }
}

export function makeViewerSettings(
  configurableSettings: ConfigurableShapeSettings
) {
  return {
    previewHeight: configurableSettings?.previewHeight, // int - height and width of the resulted image in px
    includeBackground: configurableSettings?.includeBackground ?? false, // bool - is need crop background around the image (default true)

    artworkUrl: configurableSettings.artworkUrl
      ? makeUrl(configurableSettings.artworkUrl)
      : undefined,

    backgroundImageUrl: configurableSettings?.backgroundImageUrl ?? null, // The link. Can be used instead of backgroundColor
    backgroundColor: configurableSettings?.backgroundColor, // rgb for example "109fa4"

    scale: configurableSettings?.scale ?? baseConfigurableSettings.scale, // float: 0.0 ... 1.0
    reflection:
      configurableSettings?.reflection ?? baseConfigurableSettings.reflection, // float: 0.0 ... 1.0
    shadow: configurableSettings?.shadow ?? baseConfigurableSettings.shadow, // float: 0.0 ... 1.0
    rotation: baseConfigurableSettings.rotation, // float 0.0 ... 360.0
    angle: {
      ...baseConfigurableSettings.angle,
      ...configurableSettings?.angle,
      // TODO: fix type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any, // {horizontal: -90.0..+90.0, vertical: any float}
    position:
      configurableSettings?.position ?? baseConfigurableSettings.position, // {x, y} float 0 ... 360
  };
}

export async function setupViewer(
  templateObject: ProductLayerObject,
  currentProject: Project,
  order: RawOrder,
  projectObject: ProjectObject,
  shape: Shape,
  shapeBoundingBoxDetails: Record<string, ShapeBoundingBoxDetails>,
  sceneDimensions: SceneDimensions,
  artworkUrl: string | undefined,
  asset: ShapeAsset,
  container: HTMLDivElement,
  shouldEnableMovementBoundaries: boolean,
  dispatch: Dispatch
) {
  const backgroundUrl = await prepareBackgroundForShape(
    templateObject,
    currentProject,
    order,
    shapeBoundingBoxDetails,
    {
      width: sceneDimensions.sceneWidth,
      height: sceneDimensions.sceneHeight,
    }
  );

  const [, objectChanges] = selectProjectObjectWithChanges(
    currentProject,
    projectObject
  );

  const objectSizeRelatedSettings = insertObjectPositionRelatedSettings(
    templateObject.insertionPoint,
    objectChanges.insertionPointOffset,
    shape.physicalHeight,
    shapeBoundingBoxDetails[shape.assetId],
    sceneDimensions,
    {}
  );

  const viewerSettings = {
    ...makeViewerSettings({
      artworkUrl,
      includeBackground: false,
      backgroundImageUrl: backgroundUrl,
      reflection: objectChanges.reflection,
      shadow: objectChanges.shadow,
      angle: objectChanges.angle,
    }),
    ...objectSizeRelatedSettings,
  };

  await initViewer(
    makeObjectContainerId(currentProject.selectedLayerSetId, projectObject.id),
    asset,
    container,
    viewerSettings
  );

  enableUpdatesFromShapeInteractions(
    currentProject.id,
    projectObject.id,
    currentProject.selectedLayerSetId,
    dispatch,
    (objectId) =>
      pickProductLayerObjects(currentProject).find((x) => x.id === objectId),
    shape.physicalHeight,
    currentProject.sceneDimensions.width,
    currentProject.sceneDimensions.height,
    shouldEnableMovementBoundaries
  );
}

import { connect } from 'react-redux';
import cx from 'clsx';
import React, { memo, useCallback, useLayoutEffect } from 'react';
import { Rnd } from 'react-rnd';
import debounce from 'lodash.debounce';

import { Loader } from 'shared/components/Loader/Loader';
import { AppState } from 'store/types';
import {
  lineupProductsActions,
  lineupProductsSelectors,
  InteractionMode,
} from 'features/lineupProducts';
import { mapRefValue } from 'utils/mapRefValue';

import {
  SceneDimensions,
  isProductLayerObject,
  LayerObject,
  Project,
  selectImageUrl,
  Template,
  pickProductLayerObjects,
} from '@domain/template';
import { makeObjectContainerId } from '../../viewersGlobalMap';
import { InteractionModeSwitch } from '../InteractionModeSwitch/InteractionModeSwitch';
import { SelectProductArea } from './SelectProductArea';
import { useSceneOrientation } from './hooks/useSceneOrientation';

import css from './Scene.module.scss';

type OwnProps = {
  bounds?: string;
  project: Project;
};

export type Props = ReturnType<typeof mapState> & typeof actionsMap & OwnProps;

type ViewerContainerProps = {
  id: string;
};

function ViewerContainer(props: ViewerContainerProps) {
  const { id } = props;
  return <div key={id} id={id} className={css.viewerContainer} />;
}

const MemoizedViewerContainer = memo(ViewerContainer);

function SceneView(props: Props) {
  const {
    sceneDimensionsReady,
    sceneForLayerSetReady,
    containersForProductsAreReady,
    project,
    selectObject,
    selectedObjectId,
    objectToDimensionsOnScene,
    interactionMode,
    interactionModeSelected,
    selectedObjectMovementBoundaries,
    areMovementBoundariesVisible,
  } = props;

  const backgroundRef = React.useRef<HTMLImageElement>(null);
  const sceneRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const productsCountInScene = pickProductLayerObjects(project).length;

  const { template, sceneDimensions, selectedLayerSetId } = project;

  const sceneWidth =
    sceneDimensions.width > 0 ? sceneDimensions.width : undefined;
  const sceneHeight =
    sceneDimensions.height > 0 ? sceneDimensions.height : undefined;

  const templateObjects = template.layers.flatMap((l) => l.objects);

  const selectedTemplateObject =
    interactionMode !== 'none' && selectedObjectId !== null
      ? templateObjects.find((x) => x.id === selectedObjectId)
      : undefined;
  const templateObjectsToRender = selectedTemplateObject
    ? templateObjects
        .filter((x) => x.id !== selectedTemplateObject.id)
        .concat(selectedTemplateObject)
    : templateObjects;

  const sideToFit = useSceneOrientation({
    containerRef,
    sceneRef,
  });

  useLayoutEffect(
    () =>
      mapRefValue(backgroundRef, (backgroundElement) => {
        const debouncedResize = debounce(() => {
          sceneDimensionsReady({
            availableWidth: backgroundElement.clientWidth,
            availableHeight: backgroundElement.clientHeight,
            projectId: project.id,
          });
        }, 100);

        const resizeObserver = new ResizeObserver(debouncedResize);

        resizeObserver.observe(backgroundElement);

        return () => resizeObserver.unobserve(backgroundElement);
      }),
    [sceneDimensionsReady, project.id, project.selectedLayerSetId]
  );

  useLayoutEffect(() => {
    sceneForLayerSetReady({
      projectId: project.id,
      layerSetId: project.selectedLayerSetId,
    });
  }, [sceneForLayerSetReady, project.id, project.selectedLayerSetId]);

  useLayoutEffect(() => {
    containersForProductsAreReady();
  }, [containersForProductsAreReady, productsCountInScene]);

  const handleInteractionModeChange = useCallback(
    (newInteractionMode: InteractionMode) => {
      if (selectedObjectId) {
        interactionModeSelected({
          interactionMode:
            interactionMode === newInteractionMode
              ? 'none'
              : newInteractionMode,
          objectId: selectedObjectId,
          layerSetId: project.selectedLayerSetId,
        });
      }
    },
    [
      selectedObjectId,
      interactionMode,
      project.selectedLayerSetId,
      interactionModeSelected,
    ]
  );

  return (
    <div className={css.sceneContainer} ref={containerRef}>
      <div
        className={cx(
          css.scene,
          sideToFit === 'width' ? css.fitWidth : css.fitHeight
        )}
        ref={sceneRef}
      >
        {templateObjectsToRender.map((object, objectIndex) => (
          <TemplateObject
            key={makeObjectContainerId(selectedLayerSetId, object.id)}
            object={object}
            objectIndex={objectIndex}
            template={template}
            backgroundRef={backgroundRef}
            sceneDimensions={project.sceneDimensions}
            selectedLayerSetId={selectedLayerSetId}
          />
        ))}
        <div
          style={{
            width: sceneWidth,
            height: sceneHeight,
          }}
          className={css.selectionLayer}
        >
          {templateObjects.filter(isProductLayerObject).map((object) => {
            const objectDimensionsOnScene =
              objectToDimensionsOnScene[object.id];

            if (!objectDimensionsOnScene) return null;

            return (
              <SelectProductArea
                key={object.id}
                objectDimensionsOnScene={objectDimensionsOnScene}
                productId={object.id}
                interactionMode={interactionMode}
                selectedLayerSetId={selectedLayerSetId}
                projectId={project.id}
                selectObject={selectObject}
              />
            );
          })}
        </div>
        {areMovementBoundariesVisible && selectedObjectMovementBoundaries && (
          <div
            style={selectedObjectMovementBoundaries}
            className={cx(css.boundaries)}
          />
        )}
        {project.sceneLoader.shouldShow && (
          <div className={css.sceneLoaderLayer}>
            <Loader
              classes={{ root: css.sceneLoader }}
              message={`${project.sceneLoader.message}...`}
            />
          </div>
        )}
        <div className={css.interactionModeSwitch}>
          <InteractionModeSwitch
            mode={interactionMode}
            onModeChange={handleInteractionModeChange}
          />
        </div>
      </div>
    </div>
  );
}

type TemplateObjectProps = {
  object: LayerObject;
  template: Template;
  backgroundRef: React.RefObject<HTMLImageElement>;
  objectIndex: number;
  sceneDimensions: SceneDimensions;
  selectedLayerSetId: string;
};

function TemplateObject(props: TemplateObjectProps) {
  const {
    object,
    template,
    backgroundRef,
    objectIndex,
    selectedLayerSetId,
  } = props;
  switch (object.type) {
    case 'background': {
      // add two separate layers objects - background & images (like sand and the sun)
      const backgroundUrl = selectImageUrl(template, object.imgKey);
      return (
        // add one more template
        <img
          ref={backgroundRef}
          className={css.backgroundLayer}
          key={backgroundUrl}
          alt="scene"
          src={backgroundUrl}
          onDragStart={(event) => event.preventDefault()}
        />
      );
    }
    case 'image': {
      const {
        id,
        coords: [x, y],
        resolution: [width, height],
        imgKey,
      } = object;

      const imageUrl = selectImageUrl(template, imgKey);

      return (
        <Rnd
          key={id}
          default={{
            width,
            height,
            x,
            y,
          }}
          position={{
            x,
            y,
          }}
          size={{
            width,
            height,
          }}
          style={{ zIndex: objectIndex }}
          lockAspectRatio
          disableDragging
        >
          <img
            alt="random object"
            src={imageUrl}
            style={{ width: '100%' }}
            onDragStart={(event) => event.preventDefault()}
          />
        </Rnd>
      );
    }
    case 'product': {
      const { id } = object;
      const containerId = makeObjectContainerId(selectedLayerSetId, id);

      return <MemoizedViewerContainer id={containerId} key={containerId} />;
    }
    default:
      return null;
  }
}

function mapState(state: AppState, { project }: OwnProps) {
  return {
    templatesGroup: lineupProductsSelectors.selectSelectedTemplatesGroup(state),
    selectedTemplateId: lineupProductsSelectors.selectSelectedTemplateId(state),
    selectedObjectId: lineupProductsSelectors.selectSelectedObjectId(
      state,
      project.id
    ),
    objectToDimensionsOnScene: lineupProductsSelectors.selectObjectToDimensionsOnScene(
      state,
      project.id
    ),
    interactionMode: lineupProductsSelectors.selectInteractionMode(state),
    selectedObjectMovementBoundaries: lineupProductsSelectors.selectSelectedObjectMovementBoundaries(
      state,
      project.id
    ),
    areMovementBoundariesVisible: lineupProductsSelectors.selectAreMovementBoundariesVisible(
      state
    ),
  };
}

const actionsMap = {
  sceneDimensionsReady: lineupProductsActions.sceneDimensionsReady,
  sceneForLayerSetReady: lineupProductsActions.sceneForLayerSetReady,
  containersForProductsAreReady:
    lineupProductsActions.containersForProductsAreReady,
  selectObject: lineupProductsActions.selectObject,
  interactionModeSelected: lineupProductsActions.interactionModeSelected,
};

export const Scene = connect(mapState, actionsMap)(SceneView);

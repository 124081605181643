/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { asyncDetermineImageDimensions } from './asyncDetermineImageDimensions';

export async function getCanvasWithImage(
  image: string
): Promise<[HTMLCanvasElement, number, number]> {
  const { width, height, imageElement } = await asyncDetermineImageDimensions(
    image
  );
  const buffer = document.createElement('canvas');
  const bufferContext = buffer.getContext('2d')!;
  buffer.width = width;
  buffer.height = height;

  bufferContext.drawImage(imageElement, 0, 0, width, height);

  return new Promise((resolve) =>
    setTimeout(() => {
      resolve([buffer, width, height]);
    }, 100)
  );
}

import * as D from '@domain/template';
import { DEFAULT_LAYER_SET_LEVEL_CHANGES } from 'features/lineupProducts/redux/temp';
import { makeLayerId, makeLayerObjectId } from './generateBriefingTemplateInfo';

export function increaseProductLayerObjectsInLayerSet(
  template: D.AdminTemplate,
  layerSetId: string
): D.AdminTemplate {
  const layerSet = template.layerSets.find((x) => x.id === layerSetId);
  if (layerSet === undefined) return template;

  const {
    resolution: [imageWidth, imageHeight],
  } = template;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const backgroundLayer = layerSet.layers.find((x) =>
    x.objects.some(D.isATBackgroundLayerObject)
  )!;
  const layerId = makeLayerId(layerSetId);

  const newProductLayerObject: D.ATProductLayerObject = {
    id: makeLayerObjectId(layerId),
    type: 'product',
    layerId,
    insertionPoint: [imageWidth * 0.3, imageHeight * 0.9, 1],
    angle: DEFAULT_LAYER_SET_LEVEL_CHANGES.angle,
    shadow: DEFAULT_LAYER_SET_LEVEL_CHANGES.shadow,
    reflection: DEFAULT_LAYER_SET_LEVEL_CHANGES.reflection,
    movementBoundaries: [-imageWidth, -imageHeight, imageWidth, imageHeight],
    influencedByLayers: [backgroundLayer.id],
    hasBackgroundInfluence:
      DEFAULT_LAYER_SET_LEVEL_CHANGES.hasBackgroundInfluence,
    backgroundBaseColor: DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundBaseColor,
    backgroundOpacity: DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundOpacity,
  };

  const newLayer: D.ATLayer = {
    id: layerId,
    objects: [newProductLayerObject],
  };

  return {
    ...template,
    layerSets: template.layerSets.map((x) =>
      x.id === layerSet.id
        ? {
            ...x,
            layers: x.layers.concat(newLayer),
          }
        : x
    ),
  };
}

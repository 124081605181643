import * as D from '@domain/template';
import { isATProductLayerObject } from './template';

export function changeProductObjectProperties(
  template: D.AdminTemplate,
  layerSetId: string,
  objectIndex: number,
  updateFn: (prev: D.ATProductLayerObject) => D.ATProductLayerObject
): D.AdminTemplate {
  const layerSet = template.layerSets.find((x) => x.id === layerSetId);
  if (layerSet === undefined) return template;
  let currentObjectIndex = 0;

  return {
    ...template,
    layerSets: template.layerSets.map((x) =>
      x.id === layerSet.id
        ? {
            ...x,
            layers: x.layers.map((layer) => ({
              ...layer,
              objects: layer.objects.map((object) => {
                if (!isATProductLayerObject(object)) return object;

                const isTargetObject = currentObjectIndex === objectIndex;
                // eslint-disable-next-line no-plusplus
                currentObjectIndex++;
                return isTargetObject
                  ? updateFn(object as D.ATProductLayerObject)
                  : object;
              }),
            })),
          }
        : x
    ),
  };
}

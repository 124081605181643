import { remoteNotAsked } from 'shared/remoteData';
import { LineupProductsState } from './types';

export const MAX_AMOUNT_OF_PRODUCTS_IN_SCENE = 7;

export const defaultState: LineupProductsState = {
  data: {
    activeTabName: 'templates',
    order: remoteNotAsked,
    templatesGroups: remoteNotAsked,
    isBriefTemplateOpened: false,
    selectedTemplatesGroupId: null,
    selectedTemplateId: null,
    orderShapes: [],
    projects: {},
    shapesBoundingBoxes: {
      data: {},
      isSetup: false,
    },
    interactionMode: 'move',
    openingContext: 'opened-by-end-user',
    areMovementBoundariesVisible: false,
  },
  communications: {
    producePNGPreview: remoteNotAsked,
    saveTemplateChanges: {},
    applyBackgroundInfluenceChanges: {},
  },
};

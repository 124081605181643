/* eslint-disable camelcase */
import { boundMethod } from 'autobind-decorator';

import { AdminTemplate } from '@domain/template';
import { HttpActions } from '../../HttpActions';
import { ServerTemplateInfo, TemplateInfo } from './types';
import * as converters from './converters';

export class Templates {
  private actions: HttpActions;

  constructor(actions: HttpActions) {
    this.actions = actions;
  }

  @boundMethod
  async getTemplatesInfo(): Promise<TemplateInfo[]> {
    const response = await this.actions.get<{ data: ServerTemplateInfo[] }>(
      '/templates'
    );

    if (response.status !== 200) {
      throw new Error('Failed to load templates.');
    }

    return response.data.data.map(converters.convertToTemplateInfo);
  }

  @boundMethod
  async getTemplateInfo(id: number): Promise<TemplateInfo> {
    const response = await this.actions.get<{ data: ServerTemplateInfo }>(
      `/templates/${id}`
    );

    if (response.status !== 200) {
      throw new Error('Failed to load template.');
    }

    return converters.convertToTemplateInfo(response.data.data);
  }

  @boundMethod
  async createTemplateInfo(
    template: AdminTemplate,
    name: string
  ): Promise<TemplateInfo> {
    const formData = new FormData();

    const templateToSave = {
      name,
      data: template,
    };

    formData.set('template', JSON.stringify(templateToSave));

    const response = await this.actions.post<{ data: ServerTemplateInfo }>(
      `/templates`,
      formData
    );

    if (response.status !== 201) {
      throw new Error('Failed to save template.');
    }

    return converters.convertToTemplateInfo(response.data.data);
  }

  @boundMethod
  async putTemplateInfo(
    templateInfo: TemplateInfo,
    template: AdminTemplate
  ): Promise<TemplateInfo> {
    const formData = new FormData();

    const templateToSave = {
      name: templateInfo.name,
      data: template,
    };
    formData.set('template', JSON.stringify(templateToSave));

    const response = await this.actions.put<{ data: ServerTemplateInfo }>(
      `/templates/${templateInfo.id}`,
      formData
    );

    if (response.status !== 200) {
      throw new Error('Failed to put template.');
    }

    return converters.convertToTemplateInfo(response.data.data);
  }

  @boundMethod
  async deleteTemplateInfo(id: number) {
    const response = await this.actions.del(`/templates/${id}`, null);

    if (response.status !== 204) {
      throw new Error('Failed to delete template.');
    }
  }
}

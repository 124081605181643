import React from 'react';

type ExtensionContextValue = {
  extensionId: string | null;
  workGroupId: string | null;
  orderId: string | null;
};

const ExtensionContext = React.createContext<ExtensionContextValue>({
  extensionId: null,
  workGroupId: null,
  orderId: '',
});

export function ExtensionProvider({
  children,
  value,
}: React.PropsWithChildren<{ value: ExtensionContextValue }>) {
  return (
    <ExtensionContext.Provider value={value}>
      {children}
    </ExtensionContext.Provider>
  );
}

export function useExtensionContext() {
  const context = React.useContext<ExtensionContextValue>(ExtensionContext);
  if (context === undefined) {
    throw new Error(
      'useExtensionContext must be used within a ExtensionProvider'
    );
  }
  return context;
}

import ReactModal, { Props } from 'react-modal';
import css from './Modal.module.scss';

export type ModalProps = Props;

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ReactModal overlayClassName={css.overlay} className={css.modal} {...rest}>
      {children}
    </ReactModal>
  );
};

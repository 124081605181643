import { lineupProductsSelectors } from 'features/lineupProducts';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRolesContext } from '../RolesProvider/RolesContext';

export function AdminContentOnly({
  children,
}: React.PropsWithChildren<unknown>) {
  const { userRole } = useRolesContext();
  const openingContext = useSelector(
    lineupProductsSelectors.selectOpeningContext
  );

  return userRole === 'admin' ||
    openingContext === 'opened-from-lineup-admin' ? (
    <>{children}</>
  ) : null;
}

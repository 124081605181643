import { ActionType, getType } from 'deox';
import { take } from 'redux-saga/effects';
import { BATCH, BatchAction } from 'redux-batched-actions';

import { isBatchAction } from 'utils/isBatchAction';
import { TabName } from 'features/lineupProducts';

import * as actions from '../../actions';

export function* watchTabChanges(hooks: {
  onTabChanged: (hooks: { tabName: TabName }) => Generator;
}) {
  while (true) {
    const action:
      | ActionType<typeof actions.openTab>
      | BatchAction = yield take([getType(actions.openTab), BATCH]);

    const openTabActionInBatch = isBatchAction(action)
      ? (action.payload.find(
          (x) => x.type === getType(actions.openTab)
        ) as ActionType<typeof actions.openTab> | null)
      : null;

    const { tabName } = ((openTabActionInBatch || action) as ActionType<
      typeof actions.openTab
    >).payload;

    yield hooks.onTabChanged({ tabName });
  }
}

import { ActionCreator, createAction, createReducer } from 'deox';

import {
  createRemoteFailure,
  createRemoteSuccess,
  Failure,
  RemoteData,
  remoteNotAsked,
  remotePending,
  Success,
} from './remoteData';

export function createRemoteDataReducer<A, E>(
  defaultState: RemoteData<A, E>,
  actions: Omit<RemoteDataActions<A, E>, 'reset'> &
    Partial<Pick<RemoteDataActions<A, E>, 'reset'>>
) {
  return createReducer(defaultState, (handleAction) => [
    handleAction(actions.request as ActionCreator<string>, () => remotePending),
    handleAction(
      actions.success as ActionCreator<
        ReturnType<RemoteDataActions<A, E>['success']>
      >,
      (_, { payload }) => payload as Success<A>
    ),
    handleAction(
      actions.failed as ActionCreator<
        ReturnType<RemoteDataActions<A, E>['failed']>
      >,
      (_, { payload }) => payload as Failure<E>
    ),
    ...(actions.reset
      ? [
          handleAction(
            actions.reset as ActionCreator<string>,
            (_) => remoteNotAsked
          ),
        ]
      : []),
  ]);
}

export function createMultiRemoteDataReducer<A, E>(
  defaultState: { [entityId: string]: RemoteData<A, E> },
  actions: ReturnType<typeof createMultiRemoteDataActions>
) {
  return createReducer(defaultState, (handleAction) => [
    handleAction(actions.request, (state, { payload }) => ({
      ...state,
      [payload.entityId]: remotePending,
    })),
    handleAction(actions.success, (state, { payload }) => ({
      ...state,
      [payload.value.entityId]: createRemoteSuccess(
        payload.value.data
      ) as Success<A>,
    })),
    handleAction(actions.failed, (state, { payload }) => ({
      ...state,
      [payload.error.entityId]: createRemoteFailure(
        payload.error.error
      ) as Failure<E>,
    })),
    handleAction(actions.reset, (state, { payload }) => ({
      ...state,
      [payload.entityId]: remoteNotAsked,
    })),
  ]);
}

type RemoteDataActions<A, E> = {
  request: () => { type: string };
  success: (payload: A) => { type: string; payload: Success<A> };
  failed: (payload: E) => { type: string; payload: Failure<E> };
  reset: () => { type: string };
};

export function createRemoteDataActions<A = void, E = string>(
  requestType: string,
  successType: string,
  failureType: string
): Omit<RemoteDataActions<A, E>, 'reset'>;
export function createRemoteDataActions<A = void, E = string>(
  requestType: string,
  successType: string,
  failureType: string,
  resetType: string
): RemoteDataActions<A, E>;
export function createRemoteDataActions<A = void, E = string>(
  requestType: string,
  successType: string,
  failureType: string,
  resetType?: string
): Omit<RemoteDataActions<A, E>, 'reset'> {
  return {
    request: createAction(requestType),
    success: createAction(successType, (resolve) => (payload: A) =>
      resolve(createRemoteSuccess(payload))
    ),
    failed: createAction(failureType, (resolve) => (payload: E) =>
      resolve(createRemoteFailure(payload))
    ),
    ...(typeof resetType === 'undefined'
      ? {}
      : { reset: createAction(resetType) }),
  };
}

// why E is string | unknown = https://stackoverflow.com/a/57350744
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createMultiRemoteDataActions<A = any, E = string | any>(
  requestType: string,
  successType: string,
  failureType: string,
  resetType: string // TODO: think about how to make it optional and preserve type-safety
) {
  return {
    request: createAction(
      requestType,
      (resolve) => (payload: { entityId: string | number }) => resolve(payload)
    ),
    success: createAction(
      successType,
      (resolve) => (payload: { entityId: string | number; data: A }) =>
        resolve(createRemoteSuccess(payload))
    ),
    failed: createAction(
      failureType,
      (resolve) => (payload: { entityId: string | number; error: E }) =>
        resolve(createRemoteFailure(payload))
    ),
    reset: createAction(
      resetType,
      (resolve) => (payload: { entityId: string | number }) => resolve(payload)
    ),
  };
}

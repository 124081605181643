export function downloadImage(image: string, name?: string) {
  const link = document.createElement('a');
  link.href = image;
  link.setAttribute('download', name || 'image.png');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadJSONFile(json: string, name?: string) {
  const link = document.createElement('a');
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', name || 'image.png');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

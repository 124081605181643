import { ActionType, getType } from 'deox';
import * as R from 'ramda';
import {
  put,
  select,
  takeEvery,
  takeLatest,
  take,
  fork,
  call,
} from 'redux-saga/effects';
import { isSuccess, Success } from 'shared/remoteData';
import { ReturnPromisedType } from 'shared/utilTypes';
import { SagaDependencies } from 'store/types';
import {
  extractAllShapeAssets,
  getAdjustedImageDimensionsBasedOnAspectRatio,
  RawOrder,
  GENERIC_SHAPE,
} from '@domain/order';
import {
  requestPreviewOrder,
  requestRegularOrder,
} from 'aspects/grip/extension';

import { renderPNGPreview } from 'features/lineupProducts/Editor/viewerRendering/prepareBackgroundForShape';
import { downloadImage } from 'utils/downloadImage';
import { lineupProductsSelectors } from 'features/lineupProducts';
import { briefTemplateSelectors } from 'features/briefTemplate';
import {
  applyLineupChangesToAdminTemplate,
  isBriefingTemplate,
  TemplateGroup,
} from '@domain/template';
import { removeAllViewers } from 'features/lineupProducts/Editor/viewersGlobalMap';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { getRenderFarmOrder } from '../../Editor/getRenderFarmOrder';
import { calculateShapesBoundingBoxDetails } from '../../Editor/calculateShapesBoundingBoxDetails';
import { handleMessagesFromGrip } from './handleMessagesFromGrip';
import { setupProject } from './setupProject';

export function* lineupProductsSaga(deps: SagaDependencies) {
  yield fork(calcShapesBoundingBoxes);
  yield fork(handleMessagesFromGrip);
  yield takeLatest(getType(actions.loadOrder), loadOrder, deps);
  yield takeLatest(
    getType(actions.loadTemplatesGroups),
    loadTemplatesGroups,
    deps
  );
  yield takeLatest(getType(actions.openTemplate), openTemplate, deps);
  yield takeEvery(
    getType(actions.saveTemplateChanges),
    saveTemplateChanges,
    deps
  );
  yield takeEvery(getType(actions.requestPreviewImage), requestPreviewImage);
  yield takeLatest(getType(actions.requestFinalOrder), requestRegularOrderSaga);

  yield fork(function* cleanupViewers() {
    while (true) {
      yield take([
        getType(actions.selectTemplate),
        getType(actions.changeLayerSet),
      ]);
      removeAllViewers();
    }
  });

  yield takeLatest(
    [getType(actions.selectTemplate), getType(actions.changeLayerSet)],
    setupProject,
    deps
  );
}

function* calcShapesBoundingBoxes() {
  const areShapesBoundingBoxesSetup: NonNullable<
    ReturnType<typeof selectors.selectAreShapesBoundingBoxesSetup>
  > = yield select(selectors.selectAreShapesBoundingBoxesSetup);
  let orderRD: ReturnType<typeof selectors.selectOrder> = yield select(
    selectors.selectOrder
  );
  if (!isSuccess(orderRD)) {
    yield take(getType(actions.loadOrderActions.success));
  }
  orderRD = yield select(selectors.selectOrder);
  const order = (orderRD as Success<RawOrder>).value;

  if (!areShapesBoundingBoxesSetup) {
    const shapeAssets = extractAllShapeAssets(order).concat(GENERIC_SHAPE);
    const shapesBoundingBoxDetails = R.indexBy(
      R.prop('assetId'),
      (yield call(
        calculateShapesBoundingBoxDetails,
        shapeAssets
      )) as ReturnPromisedType<typeof calculateShapesBoundingBoxDetails>
    );

    yield put(actions.shapesBoundingBoxDetailsReady(shapesBoundingBoxDetails));
  }
}

function* requestPreviewImage({
  payload: { projectId, extensionId },
}: ActionType<typeof actions.requestPreviewImage>) {
  const project: ReturnType<typeof selectors.selectProject> = yield select(
    selectors.selectProject,
    projectId
  );
  const orderRD: ReturnType<typeof selectors.selectOrder> = yield select(
    selectors.selectOrder
  );

  if (project && isSuccess(orderRD) && extensionId !== null) {
    try {
      const imageDimensions = getAdjustedImageDimensionsBasedOnAspectRatio(
        project.sceneDimensions.width,
        project.sceneDimensions.height,
        1080
      );
      if (process.env.REACT_APP_SHOULD_DOWNLOAD_PREVIEW_IMAGE) {
        // just for debugging purposes in the development
        const shapesBoundingBoxes: NonNullable<
          ReturnType<typeof selectors.selectShapesBoundingBoxes>
        > = yield select(selectors.selectShapesBoundingBoxes);

        const image: ReturnPromisedType<typeof renderPNGPreview> = yield call(
          renderPNGPreview,
          project,
          orderRD.value,
          shapesBoundingBoxes,
          imageDimensions
        );

        downloadImage(image, `preview-image-${new Date().toISOString()}.png`);
      }

      yield put(actions.producePNGPreviewActions.request());
      yield call(
        requestPreviewOrder,
        {
          width: imageDimensions.width,
          height: imageDimensions.height,
          price: 1,
        },
        extensionId
      );
      yield put(actions.producePNGPreviewActions.reset());
    } catch (e) {
      yield put(
        actions.producePNGPreviewActions.failed(
          'Failed to produce PNG preview.'
        )
      );
    }
  }
}

function* requestRegularOrderSaga({
  payload: { projectId, extensionId },
}: ActionType<typeof actions.requestFinalOrder>) {
  const project: NonNullable<
    ReturnType<typeof selectors.selectProject>
  > = yield select(selectors.selectProject, projectId);
  const orderRD: ReturnType<typeof selectors.selectOrder> = yield select(
    selectors.selectOrder
  );

  if (!isSuccess(orderRD) || extensionId === null) {
    return;
  }
  const order = orderRD.value;

  const shapesBoundingBoxes: NonNullable<
    ReturnType<typeof selectors.selectShapesBoundingBoxes>
  > = yield select(selectors.selectShapesBoundingBoxes);

  const [width, height] = project.template.resolution;
  const {
    width: renderWidth,
    height: renderHeight,
  } = getAdjustedImageDimensionsBasedOnAspectRatio(width, height, 5000);

  const [, orderToDownload]: ReturnPromisedType<
    typeof getRenderFarmOrder
  > = yield call(getRenderFarmOrder, project, shapesBoundingBoxes, order, {
    renderWidth,
    renderHeight,
  });

  yield call(
    requestRegularOrder,
    {
      priceList: [
        { format: 'png', maxWidth: 1080, maxHeight: 1080, price: 1 },
        { format: 'png', maxWidth: 5000, maxHeight: 5000, price: 2 },
        { format: 'png', maxWidth: 11000, maxHeight: 11000, price: 5 },
        { format: 'psd', maxWidth: 5000, maxHeight: 5000, price: 3 },
        { format: 'psd', maxWidth: 11000, maxHeight: 11000, price: 7 },
      ],
      width: renderWidth,
      height: renderHeight,
      aspectRatio: width / height,
      renderSettings: orderToDownload,
    },
    extensionId
  );
}

function* loadOrder(
  { api }: SagaDependencies,
  action: ActionType<typeof actions.loadOrder>
) {
  const order: ReturnType<typeof selectors.selectOrder> = yield select(
    selectors.selectOrder
  );

  if (!isSuccess(order)) {
    try {
      yield put(actions.loadOrderActions.request());

      const order: ReturnPromisedType<
        typeof api.extensions.getOrder
      > = yield call(api.extensions.getOrder, action.payload.orderId);

      yield put(actions.loadOrderActions.success(order));
    } catch (err) {
      yield put(actions.loadOrderActions.failed('Failed to load order.'));
    }
  }
}

function* loadTemplatesGroups({ api }: SagaDependencies) {
  const templatesGroups: ReturnType<
    typeof selectors.selectTemplatesGroups
  > = yield select(selectors.selectTemplatesGroups);

  if (!isSuccess(templatesGroups)) {
    try {
      yield put(actions.loadTemplatesGroupsActions.request());

      const templatesInfo: ReturnPromisedType<
        typeof api.templates.getTemplatesInfo
      > = yield call(api.templates.getTemplatesInfo);

      const groupToTemplates = R.groupBy(
        (template) => template.template.categoryName ?? 'Uncategorized',
        templatesInfo
      );
      const groups: TemplateGroup[] = Object.entries(groupToTemplates).map(
        ([categoryName, templates], index) => ({
          id: `templates-group-${index}`,
          name: categoryName,
          templates: templates.filter((x) => x.approved),
        })
      );

      yield put(actions.loadTemplatesGroupsActions.success(groups));
    } catch (err) {
      yield put(
        actions.loadTemplatesGroupsActions.failed('Failed to load templates.')
      );
    }
  }
}

function* openTemplate(
  { api }: SagaDependencies,
  action: ActionType<typeof actions.openTemplate>
) {
  const { templateId } = action.payload;

  const templateInfo: ReturnPromisedType<
    typeof api.templates.getTemplateInfo
  > = yield call(api.templates.getTemplateInfo, Number(templateId));

  const orderNullObject: RawOrder = {
    assets: [GENERIC_SHAPE],
    orderId: 'whatever-order-id',
    createdBy: 'whatever-createdBy-id',
    createdAt: 'whatever-createdAt-id',
    groupId: 'whatever-group-id',
  };

  yield put(actions.loadOrderActions.success(orderNullObject));
  const TEMPLATE_GROUP_ID = 'whatever-id';
  yield put(
    actions.loadTemplatesGroupsActions.success([
      {
        id: TEMPLATE_GROUP_ID,
        name: 'Whatever-name',
        templates: [templateInfo],
      },
    ])
  );
  yield put(actions.selectTemplateGroup({ groupId: TEMPLATE_GROUP_ID }));
  yield put(
    actions.selectTemplate({
      templateId: templateInfo.id,
      isBriefing: isBriefingTemplate(templateInfo),
    })
  );
}

function* saveTemplateChanges(
  { api }: SagaDependencies,
  { payload: { templateId } }: ActionType<typeof actions.saveTemplateChanges>
) {
  const selectedTemplateInfo: ReturnType<
    typeof lineupProductsSelectors.selectSelectedTemplateInfo
  > = yield select(lineupProductsSelectors.selectSelectedTemplateInfo);
  const templatesInfo: ReturnType<
    typeof briefTemplateSelectors.selectTemplatesInfo
  > = yield select(briefTemplateSelectors.selectTemplatesInfo);

  if (selectedTemplateInfo !== null && isSuccess(templatesInfo)) {
    try {
      const project: NonNullable<
        ReturnType<typeof selectors.selectProject>
      > = yield select(selectors.selectProject, templateId);

      yield put(
        actions.saveTemplateChangesActions.request({ entityId: templateId })
      );

      yield call(
        api.templates.putTemplateInfo,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        templatesInfo.value.find((x) => x.id === selectedTemplateInfo.id)!,
        applyLineupChangesToAdminTemplate(
          selectedTemplateInfo.template,
          project.objects,
          project.objectsChangesInLayerSets,
          project.sceneDimensions.widthDownscaleFactor,
          project.sceneDimensions.heightDownscaleFactor
        )
      );

      yield put(
        actions.saveTemplateChangesActions.reset({
          entityId: templateId,
        })
      );
    } catch (e) {
      yield put(
        actions.saveTemplateChangesActions.failed({
          entityId: templateId,
          error: `Failed.`,
        })
      );
    }
  }
}

import { nanoid } from 'nanoid';
import { makeUrl } from 'environment';

import * as D from '@domain/template';
import { calcDownscaledDimensionsBasedOnHeight } from '@domain/template/calcDownscaledDimensions';
import { ShapeAsset } from '@domain/order';

export const DEFAULT_LAYER_SET_LEVEL_CHANGES: D.LayerSetLevelObjectChanges = {
  angle: {
    horizontal: 0,
    vertical: 0,
  },
  insertionPointOffset: [0, 0] as D.InsertionPointOffset,
  hasBackgroundInfluence: true,
  shadow: 0.4,
  reflection: 0.4,
  position: {
    x: 0,
    y: 0,
  },
  movementBoundaries: [0, 0, 0, 0],
  backgroundOpacity: 1,
  backgroundBaseColor: '#ffffff',
};

export function makeLayerSetLevelChanges(
  changes?: Partial<D.LayerSetLevelObjectChanges>
): D.LayerSetLevelObjectChanges {
  return {
    ...DEFAULT_LAYER_SET_LEVEL_CHANGES,
    ...changes,
  };
}

export function initOrderShape(
  x: ShapeAsset
): D.Project['orderShapes'][number] {
  return {
    id: nanoid(),
    assetId: x.assetId,
    name: x.name,
    thumbnailUrl: makeUrl(x.thumbnailUrl),
    physicalHeight: 0,
    horizontalRotationStep: 360 / x.shape360Config.hSteps,
    verticalRotationStep: 180 / x.shape360Config.vSteps,
  };
}

export function getSceneDimensionsBasedOnAvailableRect(
  floorAvailableHeight: number,
  resolution: D.Template['resolution']
): D.SceneDimensions {
  const [width, height] = resolution;
  const [floorAvailableWidth] = calcDownscaledDimensionsBasedOnHeight(
    resolution,
    floorAvailableHeight
  );
  const finalFloorAvailableHeight = Math.min(floorAvailableHeight, height);
  const finalFloorAvailableWidth = Math.min(floorAvailableWidth, width);

  const resolutionHeightToAvailableHeightRatio =
    height / finalFloorAvailableHeight;
  const resolutionWidthToAvailableWidthRatio = width / finalFloorAvailableWidth;
  const availableHeight = Math.ceil(finalFloorAvailableHeight);
  const availableWidth = Math.ceil(finalFloorAvailableWidth);

  return {
    widthDownscaleFactor: resolutionWidthToAvailableWidthRatio,
    heightDownscaleFactor: resolutionHeightToAvailableHeightRatio,
    width: availableWidth,
    height: availableHeight,
  };
}

import * as R from 'ramda';
import { GENERIC_SHAPE } from '.';

export type ShapeAsset = {
  assetId: string;
  name: string;
  relatedArtworks: ArtworkAsset[];
  shape360Config: {
    id: string;
    curve: number[][];
    fadeWidth: number;
    fadeHeight: number;
    offset: [number, number];
    pos: [number, number];
    hSteps: number;
    vSteps: number;
  };
  thumbnailUrl: string;
  uvMappingUrls: unknown;
  webMUrl: string;
  mp4Url: string;
};

export type ArtworkAsset = {
  artworkUrl: string;
  assetId: string;
  name: string;
  thumbnailUrl: string;
  relatedShapes: ShapeAsset[];
};

export type Asset = ShapeAsset | ArtworkAsset;

export type RawOrder = {
  assets: (ShapeAsset | ArtworkAsset)[];
  orderId: string;
  createdBy: string;
  createdAt: string;
  groupId: string;
};

export function isShapeAsset(x: Asset): x is ShapeAsset {
  return R.has('shape360Config', x);
}

export function isArtworkAsset(x: Asset): x is ArtworkAsset {
  return !isShapeAsset(x);
}

export function getUniqShapeAssets(
  orderOrAssets: Asset[],
  excludeBriefingShape?: boolean
): ShapeAsset[];
export function getUniqShapeAssets(
  orderOrAssets: RawOrder,
  excludeBriefingShape?: boolean
): ShapeAsset[];
export function getUniqShapeAssets(
  orderOrAssets: RawOrder | Asset[],
  excludeBriefingShape?: boolean
): ShapeAsset[] {
  const assets = Array.isArray(orderOrAssets)
    ? orderOrAssets
    : orderOrAssets.assets;
  return R.uniqBy(
    (x) => x.assetId,
    extractAllShapeAssets(assets, excludeBriefingShape)
  );
}
export function extractAllShapeAssets(
  orderOrAssets: Asset[],
  excludeBriefingShape?: boolean
): ShapeAsset[];
export function extractAllShapeAssets(
  orderOrAssets: RawOrder,
  excludeBriefingShape?: boolean
): ShapeAsset[];
export function extractAllShapeAssets(
  orderOrAssets: RawOrder | Asset[],
  excludeBriefingShape = false
): ShapeAsset[] {
  const assets = (Array.isArray(orderOrAssets)
    ? orderOrAssets
    : orderOrAssets.assets
  ).concat(...(excludeBriefingShape ? [] : [GENERIC_SHAPE]));
  return assets.reduce((acc: ShapeAsset[], asset) => {
    if (isShapeAsset(asset)) return acc.concat(asset);
    return acc.concat(
      asset.relatedShapes.map((x) => ({ ...x, relatedArtworks: [] }))
    );
  }, []);
}

export function extractAllArtworkAssets(order: RawOrder): ArtworkAsset[] {
  return order.assets.reduce((acc: ArtworkAsset[], asset) => {
    if (isArtworkAsset(asset)) return acc.concat(asset);
    return acc.concat(
      asset.relatedArtworks.map((x) => ({ ...x, relatedShapes: [] }))
    );
  }, []);
}

export function getShapeArtworks(
  order: RawOrder,
  shapeAssetId: string
): ArtworkAsset[] {
  const artworks = order.assets.reduce((acc: ArtworkAsset[], asset) => {
    if (isArtworkAsset(asset)) {
      const relatedShape = asset.relatedShapes.find(
        (x) => x.assetId === shapeAssetId
      );
      if (relatedShape) {
        const relatedArtworks = relatedShape.relatedArtworks || []; // FIXME: relatedShape.relatedArtworks is not added on backend yet
        return acc.concat(
          [asset, ...relatedArtworks].map((x) => ({
            ...x,
            relatedShapes: [],
          }))
        );
      }
      return acc;
    }

    if (isShapeAsset(asset) && asset.assetId === shapeAssetId) {
      return acc.concat(
        asset.relatedArtworks.map((x) => ({ ...x, relatedShapes: [] }))
      );
    }

    return acc;
  }, []);

  return R.uniqBy((x) => x.assetId, artworks);
}

import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { enableBatching } from 'redux-batched-actions';

import { lineupProductsReducer } from 'features/lineupProducts';
import { briefTemplateReducer } from 'features/briefTemplate';
import { api } from 'aspects/api/Api';

import { AppState } from './types';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: (options?: unknown) => StoreEnhancer;
  }
}

function createStoreEnhancer(): StoreEnhancer {
  const enhancers: StoreEnhancer[] = [applyMiddleware(sagaMiddleware)];

  if (
    process.env.NODE_ENV !== 'production' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    // eslint-disable-next-line no-underscore-dangle
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return enhancers.length > 1 ? compose(...enhancers) : enhancers[0];
}

export const store = createStore(
  enableBatching(
    combineReducers<AppState>({
      lineupProducts: lineupProductsReducer,
      briefTemplate: briefTemplateReducer,
    })
  ),
  createStoreEnhancer()
);

sagaMiddleware.run(rootSaga, { dispatch: store.dispatch, api });

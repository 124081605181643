import cx from 'clsx';

import { Button } from 'shared/components/Button/Button';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { useLimitedInputController } from './hooks/useLimitedInputController';

import css from './LimitedInput.module.scss';

export type LimitedInputConfig = {
  min: number;
  max: number;
  value: number;
  valueToDisplay?: number;
};

type LimitedInputProps = {
  config: LimitedInputConfig;
  controlsView?: 'plus-minus' | 'arrow';
  disableManualEditing?: boolean;
  onChange(amount: number): void;
};

export function LimitedInput(props: LimitedInputProps) {
  const {
    config: { min, max, value, valueToDisplay },
    controlsView = 'arrow',
    onChange,
  } = props;

  const shouldDisableLeftControl = value <= min;
  const shouldDisableRightControl = value >= max;

  const limitedInputController = useLimitedInputController({
    defaultValue: value,
    minValue: min,
    maxValue: max,
    onApply: onChange,
  });

  return (
    <div className={css.limitedInputControls}>
      <Button
        classes={{ root: css.limitedInputControl }}
        onClick={limitedInputController.onPrev}
        disabled={shouldDisableLeftControl}
      >
        {controlsView === 'arrow' ? (
          <ArrowIcon
            className={cx(
              css.arrowIcon,
              css.arrowLeft,
              shouldDisableLeftControl && css.disabled
            )}
          />
        ) : (
          '-'
        )}
      </Button>
      {valueToDisplay !== undefined ? (
        <span className={cx(css.limitedInputControl, css.displayValue)}>
          {valueToDisplay}
        </span>
      ) : (
        <input
          className={cx(css.limitedInputControl, css.displayValue)}
          value={limitedInputController.value}
          onChange={limitedInputController.onInputChange}
          onBlur={limitedInputController.onInputBlur}
          onKeyPress={limitedInputController.onInputKeyPress}
        />
      )}
      <Button
        classes={{ root: css.limitedInputControl }}
        onClick={limitedInputController.onNext}
        disabled={shouldDisableRightControl}
      >
        {controlsView === 'arrow' ? (
          <ArrowIcon
            className={cx(
              css.arrowIcon,
              css.arrowRight,
              shouldDisableRightControl && css.disabled
            )}
          />
        ) : (
          '+'
        )}
      </Button>
    </div>
  );
}

// https://lillo.dev/articles/slaying-a-ui-antipattern/1-fetch-data-with-elm-pattern/
// http://blog.jenkster.com/2016/06/how-elm-slays-a-ui-antipattern.html

import { exhaustiveCheck } from '../../utils/exhaustiveCheck';

export type NotAsked = { type: 'notAsked' };
export type Pending = { type: 'pending' };
export type Failure<E = string> = { type: 'failure'; error: E };
export type Success<A> = { type: 'success'; value: A };
export type RemoteData<A, E = string> =
  | NotAsked
  | Pending
  | Failure<E>
  | Success<A>;

export const remoteNotAsked: NotAsked = { type: 'notAsked' };
export const remotePending: Pending = { type: 'pending' };

export function createRemoteSuccess<A>(value: A): Success<A> {
  return { type: 'success', value };
}

export function createRemoteFailure<E>(error: E): Failure<E> {
  return { type: 'failure', error };
}

export function isNotAsked(x: RemoteData<unknown, unknown>): x is NotAsked {
  return x.type === 'notAsked';
}

export function isPending(x: RemoteData<unknown, unknown>): x is Pending {
  return x.type === 'pending';
}

export function isFailure<A, E>(x: RemoteData<A, E>): x is Failure<E> {
  return x.type === 'failure';
}

export function isSuccess<A, E>(x: RemoteData<A, E>): x is Success<A> {
  return x.type === 'success';
}

export function bindRemote<R, E>(x: RemoteData<RemoteData<R, E>>) {
  return isSuccess(x) ? x.value : x;
}

export function fromNullableRemote<T>(
  x: T | undefined,
  failureMessage: string
): RemoteData<T, string> {
  return x === undefined
    ? createRemoteFailure(failureMessage)
    : createRemoteSuccess(x);
}

export function mapRemote<R, E, O>(
  x: RemoteData<R, E>,
  fn: (data: R) => O
): RemoteData<O, E> {
  return isSuccess(x) ? createRemoteSuccess(fn(x.value)) : x;
}

export function foldRemote<R, E, O>(
  x: RemoteData<R, E>,
  hooks: {
    onNotAsked: () => O;
    onPending: () => O;
    onFailure: (error: E) => O;
    onSuccess: (data: R) => O;
  }
): O {
  switch (x.type) {
    case 'notAsked': {
      return hooks.onNotAsked();
    }
    case 'pending': {
      return hooks.onPending();
    }
    case 'failure': {
      return hooks.onFailure(x.error);
    }
    case 'success': {
      return hooks.onSuccess(x.value);
    }
    default: {
      return exhaustiveCheck(x);
    }
  }
}

export function foldRemoteOption<O, R>(
  x: RemoteData<R, unknown>,
  onNone: () => O,
  onSome: (x: R) => O
): O {
  if (isSuccess(x)) {
    return onSome(x.value);
  }
  return onNone();
}

export function combineRemote<A, B, E>(
  a: RemoteData<A, E>,
  b: RemoteData<B, E>
): RemoteData<[A, B], E>;
export function combineRemote<A, B, C, E>(
  a: RemoteData<A, E>,
  b: RemoteData<B, E>,
  c: RemoteData<C, E>
): RemoteData<[A, B, C], E>;
export function combineRemote<A, B, C, D, E>(
  a: RemoteData<A, E>,
  b: RemoteData<B, E>,
  c: RemoteData<C, E>,
  d: RemoteData<D, E>
): RemoteData<[A, B, C, D], E>;
export function combineRemote<T, E>(
  ...list: RemoteData<T, E>[]
): RemoteData<T[], E> {
  const firstFailure = list.find(isFailure);
  if (firstFailure) {
    return firstFailure;
  }
  const firstPending = list.find(isPending);
  if (firstPending) {
    return firstPending;
  }
  if (list.every(isSuccess)) {
    return createRemoteSuccess(list.map((x) => x.value));
  }
  return remoteNotAsked;
}

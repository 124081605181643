/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useMemo } from 'react';
import cx from 'clsx';
import { connect } from 'react-redux';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import { makeUrl } from 'environment';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { Classes } from 'shared/style';
import * as D from '@domain/template';
import { AppState } from 'store/types';
import {
  lineupProductsActions,
  lineupProductsSelectors,
  TabName,
} from 'features/lineupProducts';
import { foldRemoteOption } from 'shared/remoteData';

import { extractAllArtworkAssets, getShapeArtworks } from '@domain/order';
import { briefTemplateSelectors } from 'features/briefTemplate';
import css from './RightPanel.module.scss';
import { AdjustSection } from './AdjustSection';

export type ProductControlsValues = Pick<
  D.LayerSetLevelObjectChanges,
  | 'angle'
  | 'reflection'
  | 'shadow'
  | 'hasBackgroundInfluence'
  | 'backgroundOpacity'
  | 'insertionPointOffset'
> &
  Pick<D.ProductLayerObject, 'influencedByLayers' | 'movementBoundaries'> &
  Pick<D.Shape, 'horizontalRotationStep' | 'verticalRotationStep'> & {
    backgroundBaseColor: string;
  };

export type RightPanelProps = ReturnType<typeof mapState> &
  typeof actionsMap & {
    classes?: Classes<'root' | 'wrapper'>;
  };

const TAB_ORDER: TabName[] = ['templates', 'artworks', 'adjust'];

function RightPanelView(props: RightPanelProps) {
  const {
    projectId,
    classes,
    openTab,
    selectTemplateGroup,
    order,
    updateObject,
    openBriefingCreator,
    selectedProjectObject,
    activeTabName,
    selectedTemplateId,
    selectedTemplatesGroupId,
    isBriefTemplateOpened,
    templatesGroups: templatesGroupsRD,
    currentProject,
    openingContext,
    isBriefing,
  } = props;

  const {
    artworkId: selectedArtworkId,
    assetId: selectedAssetId,
    id: selectedObjectId,
  } = selectedProjectObject || { artworkId: null, assetId: null, id: null };

  const handleActiveTabNameChange = useCallback(
    (tabName: string | number | React.Key[]) => {
      openTab({ tabName: tabName as TabName });
    },
    [openTab]
  );

  const handleTemplateGroupSelect = useCallback(
    (groupId) => {
      selectTemplateGroup({ groupId });
    },
    [selectTemplateGroup]
  );

  const allArtworks = useMemo(
    () => foldRemoteOption(order, () => [], extractAllArtworkAssets),
    [order]
  );

  const artworks = selectedAssetId
    ? foldRemoteOption(
        order,
        () => allArtworks,
        (order) => getShapeArtworks(order, selectedAssetId)
      )
    : allArtworks;

  const handleArtworkSelect = useCallback(
    (artworkId: string) => {
      if (selectedObjectId && projectId) {
        updateObject({ objectId: selectedObjectId, artworkId, projectId });
      }
    },
    [updateObject, selectedObjectId, projectId]
  );

  return (
    <aside className={cx(css.rightPanel, classes?.root)}>
      <Tabs
        className={css.tabs}
        selectedIndex={TAB_ORDER.findIndex((x) => x === activeTabName)}
        onSelect={(index) => handleActiveTabNameChange(TAB_ORDER[index])}
      >
        <TabList className={css.tabsList}>
          <Tab
            selectedClassName={css.selectedTab}
            disabledClassName={css.disabledTab}
            className={css.tab}
            disabled={openingContext === 'opened-from-lineup-admin'}
          >
            Templates
          </Tab>
          <Tab
            selectedClassName={css.selectedTab}
            disabledClassName={css.disabledTab}
            className={css.tab}
            disabled={
              selectedTemplateId === null ||
              openingContext === 'opened-from-lineup-admin' ||
              isBriefing
            }
          >
            Artworks
          </Tab>
          <Tab
            selectedClassName={css.selectedTab}
            disabledClassName={css.disabledTab}
            className={css.tab}
            disabled={selectedTemplateId === null}
          >
            Adjust
          </Tab>
        </TabList>
        <div className={css.tabPanelContainer}>
          <TabPanel>
            <div>
              {foldRemoteOption(
                templatesGroupsRD,
                () => null,
                (templatesGroups) =>
                  templatesGroups.map((x) => (
                    <div
                      className={cx(
                        css.templatesListItem,
                        x.id === selectedTemplatesGroupId &&
                          !isBriefTemplateOpened &&
                          css.selected
                      )}
                      key={x.id}
                      onClick={() => handleTemplateGroupSelect(x.id)}
                    >
                      <span>{x.name}</span>
                      <ArrowIcon className={css.arrowIcon} />
                    </div>
                  ))
              )}
              <button
                type="button"
                className={cx(
                  css.briefTemplateButton,
                  isBriefTemplateOpened && css.selected
                )}
                onClick={openBriefingCreator}
              >
                <span>Brief a template</span>
                <PlusIcon className={css.plusIcon} />
              </button>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={css.artworks}>
              {artworks.map((artwork) => (
                <div
                  key={artwork.artworkUrl}
                  className={cx(
                    css.artwork,
                    artwork.artworkUrl === selectedArtworkId &&
                      css.selectedArtwork
                  )}
                  onClick={() => handleArtworkSelect(artwork.artworkUrl)}
                >
                  <img
                    alt="artwork"
                    className={css.artworkThumbnail}
                    key={artwork.assetId}
                    src={makeUrl(artwork.thumbnailUrl)}
                  />
                  <label className={css.artworkLabel}>{artwork.name}</label>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            {currentProject && <AdjustSection projectId={currentProject.id} />}
          </TabPanel>
        </div>
      </Tabs>
    </aside>
  );
}

function mapState(state: AppState) {
  const projectId = lineupProductsSelectors.selectSelectedTemplateId(state);
  return {
    projectId,
    currentProject: projectId
      ? lineupProductsSelectors.selectProject(state, projectId)
      : null,
    activeTabName: lineupProductsSelectors.selectActiveTabName(state),
    order: lineupProductsSelectors.selectOrder(state),
    selectedProjectObject: projectId
      ? lineupProductsSelectors.selectSelectedObject(state, projectId)
      : null,
    selectedTemplateId: lineupProductsSelectors.selectSelectedTemplateId(state),
    selectedTemplatesGroupId: lineupProductsSelectors.selectSelectedTemplatesGroupId(
      state
    ),
    isBriefTemplateOpened: lineupProductsSelectors.selectIsBriefTemplateOpened(
      state
    ),
    templatesGroups: lineupProductsSelectors.selectTemplatesGroups(state),
    openingContext: lineupProductsSelectors.selectOpeningContext(state),
    isBriefing: projectId
      ? briefTemplateSelectors.selectIsTemplateBriefing(state, projectId)
      : false,
  };
}

const actionsMap = {
  openTab: lineupProductsActions.openTab,
  selectTemplateGroup: lineupProductsActions.selectTemplateGroup,
  updateObject: lineupProductsActions.updateObject,
  openBriefingCreator: lineupProductsActions.openBriefingCreator,
};

export const RightPanel = connect(mapState, actionsMap)(RightPanelView);

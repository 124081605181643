import { ActionType } from 'deox';
import { select, take, call } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';

import { isSuccess } from 'shared/remoteData';
import { ReturnPromisedType } from 'shared/utilTypes';

import { getAdjustedImageDimensionsBasedOnAspectRatio } from '@domain/order';
import { renderPNGPreview } from '../../Editor/viewerRendering/prepareBackgroundForShape';
import * as actions from '../actions';
import * as selectors from '../selectors';

export function createGripMessagesChannel() {
  return eventChannel((emitter) => {
    const listener = (event: MessageEvent) => {
      if (!event) {
        // eslint-disable-next-line no-console
        console.error('The message is empty. Please provide data.');
      }

      if (event.data.type === 'RequestPreview') {
        emitter(actions.gripRequestsPreview({ port: event.ports[0] }));
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });
}

export function* handleMessagesFromGrip() {
  const chan: EventChannel<unknown> = yield call(createGripMessagesChannel);

  while (true) {
    const action: ActionType<typeof actions.gripRequestsPreview> = yield take(
      chan
    );

    if (action.type === 'LINEUP_PRODUCTS:GRIP_REQUESTS_PREVIEW') {
      const { port } = action.payload;
      const projectId: ReturnType<
        typeof selectors.selectSelectedProjectId
      > = yield select(selectors.selectSelectedProjectId);

      const project: ReturnType<typeof selectors.selectProject> = yield select(
        selectors.selectProject,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectId!
      );

      const order: ReturnType<typeof selectors.selectOrder> = yield select(
        selectors.selectOrder
      );

      const shapesBoundingBoxes: NonNullable<
        ReturnType<typeof selectors.selectShapesBoundingBoxes>
      > = yield select(selectors.selectShapesBoundingBoxes);

      if (isSuccess(order)) {
        const image: ReturnPromisedType<typeof renderPNGPreview> = yield call(
          renderPNGPreview,
          project,
          order.value,
          shapesBoundingBoxes,
          getAdjustedImageDimensionsBasedOnAspectRatio(
            project.sceneDimensions.width,
            project.sceneDimensions.height,
            1080
          )
        );

        try {
          port.postMessage(image.replace(/^data:image\/png;base64,/, ''));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error({ error });
          port.postMessage({ error });
        }
      }
    }
  }
}

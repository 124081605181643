import { Project } from '@domain/template';
import { SceneDimensions } from './renderObjectOnBackground';
import { getRectDimensionsInUnits } from './getRectDimensionsInUnits';

export function getSceneDimensionsForRender(
  currentProject: Project
): SceneDimensions {
  const {
    sceneDimensions: { width: sceneWidth, height: sceneHeight },
  } = currentProject;

  const [sceneWidthUnits, sceneHeightUnits] = getRectDimensionsInUnits(
    sceneWidth,
    sceneHeight
  );

  return {
    sceneWidth,
    sceneHeight,
    sceneWidthUnits,
    sceneHeightUnits,
  };
}

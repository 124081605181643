/* eslint-disable camelcase */
import { boundMethod } from 'autobind-decorator';

import { dataURItoBlob } from 'utils/dataURItoBlob';
import { HttpActions } from '../../HttpActions';
import { Background, ServerBackground } from './types';
import * as converters from './converters';

export class Backgrounds {
  private actions: HttpActions;

  constructor(actions: HttpActions) {
    this.actions = actions;
  }

  @boundMethod
  async getBackgrounds(): Promise<Background[]> {
    const response = await this.actions.get<{ data: ServerBackground[] }>(
      '/backgrounds'
    );

    if (response.status !== 200) {
      throw new Error('Failed to load backgrounds.');
    }

    return response.data.data.map(converters.convertToBackground);
  }

  @boundMethod
  async uploadBackground(
    background: string,
    backgroundName: string
  ): Promise<Background> {
    const formData = new FormData();

    formData.set('image', dataURItoBlob(background), backgroundName);

    const response = await this.actions.post<{ data: ServerBackground }>(
      `/backgrounds`,
      formData
    );

    if (response.status !== 201) {
      throw new Error('Failed to upload background.');
    }

    return converters.convertToBackground(response.data.data);
  }

  @boundMethod
  async deleteBackground(id: number) {
    const response = await this.actions.del(`/backgrounds/${id}`, null);

    if (response.status !== 204) {
      throw new Error('Failed to delete background.');
    }
  }
}

import { ShapeBoundingBoxDetails } from '@domain/template';
import { ShapeAsset } from '@domain/order';
import { asyncDetermineImageDimensions } from './asyncDetermineImageDimensions';
import { getShapeRect } from './getShapeRect';
import { renderImage } from '../renderImage';

export async function asyncGetShapeBondingBoxDetails(
  asset: ShapeAsset
): Promise<ShapeBoundingBoxDetails> {
  const shapeBoundingBoxImage = (await renderImage({
    objectId: `whatever-object-id-${Date.now()}`,
    asset,
    partialConfigurableSettings: {
      scale: 1,
      position: { x: 0, y: 0 },
      angle: { vertical: 0, horizontal: 0 },
    },
    destroyAfterRenderingImage: true,
  })) as string;

  const {
    height: shapeBoundingBoxImageHight,
  } = await asyncDetermineImageDimensions(shapeBoundingBoxImage);

  const shapeRect = await getShapeRect(
    shapeBoundingBoxImage,
    shapeBoundingBoxImageHight,
    shapeBoundingBoxImageHight
  );

  const paddingTop = shapeRect.topmostY;
  const shapeHeight = shapeRect.bottommostY - shapeRect.topmostY;
  const paddingBottom = shapeBoundingBoxImageHight - shapeRect.bottommostY;
  const shapeWidth = shapeRect.rightmostX - shapeRect.leftmostX;
  const paddingLeft = shapeRect.leftmostX;
  const paddingRight = shapeBoundingBoxImageHight - shapeRect.rightmostX;

  const {
    height: imageHeight,
    width: imageWidth,
  } = await asyncDetermineImageDimensions(shapeBoundingBoxImage);

  return {
    assetId: asset.assetId,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    shapeHeight,
    shapeWidth,
    imageHeight,
    imageWidth,
    boundingBoxHeight: shapeBoundingBoxImageHight,
  };
}

import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import Bowser from 'bowser';

import {
  Editor,
  lineupProductsActions,
  lineupProductsSelectors,
} from 'features/lineupProducts';
import { combineRemote, foldRemote } from 'shared/remoteData';
import { Loader } from 'shared/components/Loader/Loader';
import { RolesProvider, ADMIN_USERS_IDS } from 'aspects/roles';
import { ExtensionProvider } from 'aspects/grip';
import { AppState } from 'store/types';
import {
  briefTemplateActions,
  briefTemplateSelectors,
} from 'features/briefTemplate';

import { isArtworkAsset } from '@domain/order';
import css from './App.module.scss';
import './style/index.scss';
import { extractAppConfig } from './extractAppConfig';

const browser = Bowser.getParser(window.navigator.userAgent);

type Props = ReturnType<typeof mapState> & typeof actionsMap;

function AppView(props: Props) {
  if (browser.is('safari')) {
    return renderMessage(
      'Safari is currently not supported, please use Chrome.'
    );
  }

  const {
    loadOrder,
    order,
    openTemplate,
    loadTemplatesGroups,
    loadTemplatesInfo,
    templatesInfo,
  } = props;
  const {
    orderId,
    workGroupId,
    userId,
    extensionId,
    templateId,
  } = extractAppConfig();

  if (templateId === null ? !orderId : false) {
    return renderMessage(`No orderid was specified. Please either add it to the url after
    domain name (domain/orderid) or open this page from Grip by making a
    lineup.`);
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  if (templateId !== null) {
    useEffect(() => {
      openTemplate({ templateId });
      loadTemplatesInfo();
    }, [openTemplate, templateId, loadTemplatesInfo]);
  } else if (orderId !== null) {
    useEffect(() => {
      loadOrder({ orderId });
      loadTemplatesGroups();
      loadTemplatesInfo();
    }, [orderId, loadOrder, loadTemplatesGroups, loadTemplatesInfo]);
  }

  const finalUserId = templateId !== null ? ADMIN_USERS_IDS[0] : userId;

  const extensionData = useMemo(() => ({ orderId, extensionId, workGroupId }), [
    orderId,
    extensionId,
    workGroupId,
  ]);
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <div className={css.app}>
      {foldRemote(combineRemote(order, templatesInfo), {
        onNotAsked: () => <Loader classes={{ root: css.loader }} />,
        onPending: () => <Loader classes={{ root: css.loader }} />,
        onFailure: (error) => renderMessage(<>{error}</>),
        onSuccess: ([order]) => {
          if (order.assets.length === 0) {
            return renderMessage(<>There are no assets in your order.</>);
          }
          const artworkAssetsWithoutRelatedShapes = order.assets
            .filter(isArtworkAsset)
            .reduce(
              (acc: string[], x) =>
                x.relatedShapes === undefined || x.relatedShapes.length === 0
                  ? acc.concat(x.assetId)
                  : acc,
              []
            );
          if (artworkAssetsWithoutRelatedShapes.length !== 0) {
            return renderMessage(
              <>
                There are artworks (
                {artworkAssetsWithoutRelatedShapes.join(', ')}) without linked
                shapes. Please get in touch with us.
              </>
            );
          }

          return (
            <ExtensionProvider value={extensionData}>
              <RolesProvider userId={finalUserId}>
                <Editor
                  groupId={workGroupId || undefined}
                  templateId={templateId}
                />
              </RolesProvider>
            </ExtensionProvider>
          );
        },
      })}
    </div>
  );
}

function renderMessage(message: React.ReactNode) {
  return <p className={css.message}>{message}</p>;
}

function mapState(state: AppState) {
  return {
    order: lineupProductsSelectors.selectOrder(state),
    templatesInfo: briefTemplateSelectors.selectTemplatesInfo(state),
  };
}

const actionsMap = {
  loadOrder: lineupProductsActions.loadOrder,
  loadTemplatesGroups: lineupProductsActions.loadTemplatesGroups,
  openTemplate: lineupProductsActions.openTemplate,
  loadTemplatesInfo: briefTemplateActions.loadTemplatesInfo,
};

export default connect(mapState, actionsMap)(AppView);

export async function runSequentialAsync<A>(
  value: A,
  ...tasks: ((x: A) => Promise<A>)[]
): Promise<A> {
  if (tasks.length === 0) {
    return value;
  }
  const [task, ...rest] = tasks;
  const nextValue = await task(value);
  return runSequentialAsync(nextValue, ...rest);
}

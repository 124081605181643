import React from 'react';
import { mapRefValue } from 'utils/mapRefValue';

export function useSceneOrientation(params: {
  containerRef: React.RefObject<HTMLElement>;
  sceneRef: React.RefObject<HTMLElement>;
}) {
  const [sideToFit, setSideToFit] = React.useState<'width' | 'height'>('width');

  React.useEffect(
    () =>
      mapRefValue(params.sceneRef, (sceneElement) =>
        mapRefValue(params.containerRef, (containerElement) => {
          const resizeObserver = new ResizeObserver(() => {
            // when devtools opens or resize delta is big
            // viewer doesn't respect it, so we fire one more event
            requestAnimationFrame(() => {
              window.dispatchEvent(new Event('resize'));
            });
            const sceneRect = sceneElement.getBoundingClientRect();
            const containerRect = containerElement.getBoundingClientRect();

            const heightDiff = sceneRect.height - containerRect.height;
            const widthDiff = sceneRect.width - containerRect.width;
            const isThereNoDiff = !widthDiff && !heightDiff;

            if (heightDiff > 0) {
              setSideToFit('height');
            } else if (isThereNoDiff) {
              setSideToFit('width');
            }
          });

          resizeObserver.observe(containerElement);

          return () => resizeObserver.disconnect();
        })
      ),
    [params.containerRef, params.sceneRef]
  );

  return sideToFit;
}

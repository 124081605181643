import { TakeableChannel } from 'redux-saga';
import {
  actionChannel,
  ActionPattern,
  fork,
  take,
  takeLatest,
} from 'redux-saga/effects';

export type TakeChannelAfterAction<Worker> = {
  waitFor: string;
  buffer: string[];
  worker: Worker;
};

export function takeChannelAfterAction<
  Worker extends (action: unknown) => void,
  Actions extends ActionPattern
>(params: TakeChannelAfterAction<Worker>) {
  return fork(function* forkedWatcher() {
    const channel: TakeableChannel<Actions> = yield actionChannel(
      params.buffer
    );

    yield take(params.waitFor);

    yield takeLatest(channel, params.worker);
  });
}

import { AdminTemplate, ATProductLayerObject } from './adminTemplate';
import {
  InsertionPoint,
  isATProductLayerObject,
  Project,
  ProjectObject,
} from './template';

export function applyLineupChangesToAdminTemplate(
  template: AdminTemplate,
  products: ProjectObject[],
  layerSetsLevelChanges: Project['objectsChangesInLayerSets'],
  widthDownscaleFactor: number,
  heightDownscaleFactor: number
): AdminTemplate {
  return {
    ...template,
    layerSets: template.layerSets.map((layerSet) => {
      const layerSetLevelChanges = layerSetsLevelChanges[layerSet.id];
      let nextProductToTake = 0;
      return {
        ...layerSet,
        layers: layerSet.layers.map((layer) => ({
          ...layer,
          objects: layer.objects.map((object) => {
            if (isATProductLayerObject(object)) {
              const changes =
                layerSetLevelChanges[products[nextProductToTake].id];
              const x =
                object.insertionPoint[0] +
                changes.insertionPointOffset[0] * widthDownscaleFactor;
              const y =
                object.insertionPoint[1] +
                changes.insertionPointOffset[1] * heightDownscaleFactor;
              const newInsertionPoint: InsertionPoint = [
                x,
                y,
                object.insertionPoint[2],
              ];

              const updatedObject: ATProductLayerObject = {
                ...object,
                angle: changes.angle,
                insertionPoint: newInsertionPoint,
                movementBoundaries: changes.movementBoundaries,
                backgroundBaseColor: changes.backgroundBaseColor,
                backgroundOpacity: changes.backgroundOpacity,
                shadow: changes.shadow,
                reflection: changes.reflection,
                hasBackgroundInfluence: changes.hasBackgroundInfluence,
              };
              // eslint-disable-next-line no-plusplus
              nextProductToTake++;
              return updatedObject;
            }
            return object;
          }),
        })),
      };
    }),
  };
}

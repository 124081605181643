import { TemplateInfo } from 'aspects/api/modules/Templates/types';

// name with @ due to existing 'domain' name in nodejs typings
export * from './template';
export * from './adminTemplate';
export * from './templateGroup';
export { applyLineupChangesToAdminTemplate } from './applyLineupChangesToAdminTemplate';
export {
  generateBriefingTemplateInfo,
  generateLayerSetBasedOnExistingOne,
} from './generateBriefingTemplateInfo';

export function isBriefingTemplate(x: TemplateInfo): boolean;
export function isBriefingTemplate(
  xOrXs: TemplateInfo[],
  templateId: string
): boolean;
export function isBriefingTemplate(
  xOrXs: TemplateInfo | TemplateInfo[],
  templateId?: string
): boolean {
  if (Array.isArray(xOrXs)) {
    const template = xOrXs.find((x) => x.id === templateId);

    return template !== undefined
      ? xOrXs.some((x) => x.id === templateId && isBriefing(x))
      : true;
  }
  return isBriefing(xOrXs);
}

function isBriefing(x: TemplateInfo) {
  return x.template.type === 'custom' && !x.approved;
}

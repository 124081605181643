import { combineReducers } from 'redux';
import {
  createMultiRemoteDataReducer,
  createRemoteDataReducer,
  remoteNotAsked,
} from 'shared/remoteData';

import * as actions from './actions';
import { BriefTemplateState } from './types';

const defaultState: BriefTemplateState = {
  data: {
    backgrounds: remoteNotAsked,
    templatesInfo: remoteNotAsked,
  },
  communications: {
    uploadBackground: remoteNotAsked,
    turnIntoTemplate: {},
  },
};

const dataReducer = combineReducers<BriefTemplateState['data']>({
  backgrounds: createRemoteDataReducer(
    defaultState.data.backgrounds,
    actions.loadBackgroundsActions
  ),
  templatesInfo: createRemoteDataReducer(
    defaultState.data.templatesInfo,
    actions.loadTemplatesInfoActions
  ),
});

const communicationsReducer = combineReducers<
  BriefTemplateState['communications']
>({
  uploadBackground: createRemoteDataReducer(
    defaultState.communications.uploadBackground,
    actions.uploadBackgroundActions
  ),
  turnIntoTemplate: createMultiRemoteDataReducer(
    defaultState.communications.turnIntoTemplate,
    actions.turnIntoTemplateActions
  ),
});

export const briefTemplateReducer = combineReducers<BriefTemplateState>({
  data: dataReducer,
  communications: communicationsReducer,
});

import cx from 'clsx';
import { Classes } from 'shared/style';
import css from './Loader.module.scss';

type Props = {
  classes?: Classes<'root'>;
  message?: string;
};

export function Loader(props: Props) {
  const { message, classes } = props;
  return (
    <div className={cx(css.loader, classes?.root && classes.root)}>
      <div className={css['lds-roller']}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <span className={css.message}>{message}</span>
    </div>
  );
}

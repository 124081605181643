import { useCallback, useEffect, useState } from 'react';
import cx from 'clsx';
import { usePrevious } from 'react-use';

import { exhaustiveCheck } from 'utils/exhaustiveCheck';

import css from './Slider.module.scss';
import { InputProps } from './Slider';

export type SliderInputProps = InputProps;

export function SliderInput(props: SliderInputProps) {
  const {
    className,
    value,
    onValueChanged,
    normalizeValue,
    inputValueType,
  } = props;
  const [localValue, setLocalValue] = useState(value);

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (prevValue !== value && value !== localValue) {
      setLocalValue(value);
    }
  }, [prevValue, value, localValue]);

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const value = Number((event.target as HTMLInputElement).value);
      if (!Number.isNaN(value)) {
        setLocalValue(value);
      }
    },
    []
  );

  const handleBlur = useCallback(() => {
    const normalizedValue = normalizeValue(localValue);
    onValueChanged(normalizedValue);
    setLocalValue(normalizeValue);
  }, [localValue, onValueChanged, normalizeValue]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleBlur();
      }
    },
    [handleBlur]
  );

  return (
    <div className={css.percentInputContainer}>
      <input
        type="number"
        className={cx(css.percentInput, className)}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
      />
      {inputValueType && renderValueType(inputValueType)}
    </div>
  );
}

export function renderValueType(
  valueType: NonNullable<InputProps['inputValueType']>
): React.ReactNode {
  switch (valueType) {
    case 'percent': {
      return <>&#37;</>;
    }
    case 'percent-placeholder': {
      return <span style={{ visibility: 'hidden' }}>&#37;</span>;
    }
    // eslint-disable-next-line no-lone-blocks
    default: {
      return exhaustiveCheck(valueType);
    }
  }
}

import { ShapeAsset } from './order';
import devGenericShape from './devBoxShapeAsset.json';
import prodGenericShape from './prodBoxShapeAsset.json';

export * from './order';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const GENERIC_SHAPE: ShapeAsset =
  process.env.REACT_APP_ENV === 'prod'
    ? (prodGenericShape as any)
    : (devGenericShape as any); // may get stale
/* eslint-enable @typescript-eslint/no-explicit-any */

export function getAdjustedImageDimensionsBasedOnAspectRatio(
  width: number,
  height: number,
  targetLength: number
): { width: number; height: number } {
  let finalWidth = targetLength;
  let finalHeight = targetLength;
  if (width > height) {
    finalWidth = targetLength;
    finalHeight = (height * finalWidth) / width;
  }
  if (height > width) {
    finalHeight = targetLength;
    finalWidth = (width * finalHeight) / height;
  }

  return {
    width: Math.round(finalWidth),
    height: Math.round(finalHeight),
  };
}

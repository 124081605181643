import { runSequentialAsync } from 'utils/runSequentialAsync';

import { ShapeBoundingBoxDetails } from '@domain/template';
import { getUniqShapeAssets, ShapeAsset } from '@domain/order';
import { asyncGetShapeBondingBoxDetails } from './viewerRendering/asyncGetShapeBondingBoxDetails';

export async function calculateShapesBoundingBoxDetails(assets: ShapeAsset[]) {
  const tasksToCalculateBoundingBox = getUniqShapeAssets(assets).map(
    (asset) => async (acc: ShapeBoundingBoxDetails[]) => {
      const boundingBoxDetails = await asyncGetShapeBondingBoxDetails(asset);
      return acc.concat(boundingBoxDetails);
    }
  );

  return runSequentialAsync([], ...tasksToCalculateBoundingBox);
}

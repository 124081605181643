import * as R from 'ramda';
import { foldRemote, RemoteData } from './remoteData';

export function shouldLoadRemoteData(x: RemoteData<unknown, unknown>): boolean {
  return foldRemote(x, {
    onNotAsked: R.T,
    onPending: R.F,
    onFailure: R.T,
    onSuccess: R.F,
  });
}

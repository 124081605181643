import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from 'react-use';

import { AppState } from 'store/types';
import { Button } from 'shared/components/Button/Button';
import { isFailure, isPending, isSuccess } from 'shared/remoteData';
import {
  lineupProductsActions,
  lineupProductsSelectors,
} from 'features/lineupProducts';
import {
  briefTemplateActions,
  briefTemplateSelectors,
} from 'features/briefTemplate';
import { AdminContentOnly, useRolesContext } from 'aspects/roles';
import { useExtensionContext } from 'aspects/grip';

import css from './RightPanel.module.scss';
import { ProductControlsContainer } from './ProductControlsContainer';
import { ProductsArrangementControls } from './ProductsArrangementControls';
import {
  SubmitData,
  TurnIntoTemplateModal,
} from '../TurnIntoTemplateModal/TurnIntoTemplateModal';
import { InsertionPointControls } from './InsertionPointControls';

type OwnProps = {
  projectId: string;
};

export type AdjustSectionProps = ReturnType<typeof mapState> &
  typeof actionsMap &
  OwnProps;

function AdjustSectionView(props: AdjustSectionProps) {
  const {
    currentProject,
    duplicateObject,
    rearrangeObjects,
    changeLayerSet,
    changeProductsAmountInScene,
    changePixelsInOneRelativeUnit,
    maxPixelsInOneRelativeUnit,
    selectObject,
    productControlsValues,
    layerSetsControlValues,
    productsAmountInSceneControlValues,
    pixelsInOneRelativeUnit,
    currentSelection,
    requestPreviewImage,
    producePNGPreviewComm,
    requestFinalOrder,
    selectedProjectObject,
    projectId,
    updateObject,
    addLayerSet,
    removeLayerSet,
    layerSetChangeDirectionOnRemoval,
    shouldDisableRemoveProductsComposition,
    turnIntoTemplate,
    turnIntoTemplateComm,
    insertionPointValues,
    openingContext,
    saveTemplateChangesComm,
    saveTemplateChanges,
    isBriefing,
    areMovementBoundariesVisible,
    setMovementBoundariesVisibility,
    applyBackgroundInfluenceChangesComm,
  } = props;

  const { id: selectedObjectId } = selectedProjectObject || {
    artworkId: null,
    assetId: null,
    id: null,
  };

  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    selectedObjectId
  );
  const [
    isTurnIntoTemplateModalOpened,
    setIsTurnIntoTemplateModalOpened,
  ] = useState(false);

  const { extensionId } = useExtensionContext();
  const { userRole } = useRolesContext();

  const prevSelectedObjectId = usePrevious(selectedObjectId);
  useEffect(() => {
    if (
      selectedObjectId &&
      prevSelectedObjectId !== selectedObjectId &&
      selectedProductId !== selectedObjectId
    ) {
      setSelectedProductId(selectedObjectId);
    }
  }, [selectedProductId, selectedObjectId, prevSelectedObjectId]);

  const handleTurnIntoTemplateClick = useCallback(() => {
    setIsTurnIntoTemplateModalOpened(true);
  }, []);

  const handleTurnIntoTemplate = useCallback(
    ({ name, comment }: SubmitData) => {
      setIsTurnIntoTemplateModalOpened(false);
      turnIntoTemplate({ templateId: currentProject.id, name, comment });
    },
    [turnIntoTemplate, currentProject.id]
  );

  const isRegularTemplate = !isBriefing;

  return (
    <>
      <section className={css.adjustSection}>
        <h5 className={css.adjustSectionHeader}>Products arrangement</h5>
        <ProductsArrangementControls
          isBriefing={isBriefing}
          onSelectedProductChange={setSelectedProductId}
          selectedProductId={selectedProductId}
          selectedSceneObjectId={selectedObjectId}
          selectSceneObject={selectObject}
          currentProject={currentProject}
          duplicateObject={duplicateObject}
          rearrangeObjects={rearrangeObjects}
          changeProductsAmountInScene={changeProductsAmountInScene}
          changeLayerSet={changeLayerSet}
          layerSetsControlValues={layerSetsControlValues}
          productsAmountInSceneControlValues={
            productsAmountInSceneControlValues
          }
          currentSelection={currentSelection}
          addLayerSet={addLayerSet}
          removeLayerSet={removeLayerSet}
          layerSetChangeDirectionOnRemoval={layerSetChangeDirectionOnRemoval}
          shouldDisableRemoveProductsComposition={
            shouldDisableRemoveProductsComposition
          }
        />
      </section>
      <section className={css.adjustSection}>
        <h5 className={css.adjustSectionHeader}>Controls</h5>
        <div className={css.controlsButtons}>
          {openingContext === 'opened-from-lineup-admin' && (
            <>
              <Button
                classes={{ root: css.renderButton }}
                onClick={() => saveTemplateChanges({ templateId: projectId })}
                disabled={isPending(saveTemplateChangesComm)}
                theme="red"
              >
                {isPending(saveTemplateChangesComm)
                  ? 'Saving changes...'
                  : 'Save changes'}
              </Button>
              {isFailure(saveTemplateChangesComm) && (
                <div className={css.producePNGPreviewError}>
                  {saveTemplateChangesComm.error}
                </div>
              )}
            </>
          )}
          {isRegularTemplate && openingContext === 'opened-by-end-user' && (
            <>
              <Button
                classes={{ root: css.renderButton }}
                onClick={() => requestPreviewImage({ projectId, extensionId })}
                disabled={isPending(producePNGPreviewComm)}
              >
                {isPending(producePNGPreviewComm)
                  ? 'Creating PNG Preview...'
                  : 'PNG Preview'}
              </Button>
              {isFailure(producePNGPreviewComm) && (
                <div className={css.producePNGPreviewError}>
                  {producePNGPreviewComm.error}
                </div>
              )}
            </>
          )}
          {isBriefing && openingContext !== 'opened-from-lineup-admin' && (
            <>
              <Button
                classes={{ root: css.renderButton }}
                onClick={handleTurnIntoTemplateClick}
                disabled={
                  isPending(turnIntoTemplateComm) ||
                  isSuccess(turnIntoTemplateComm)
                }
                theme="red"
              >
                {isPending(turnIntoTemplateComm)
                  ? 'Turning into a template...'
                  : 'Turn into a template'}
              </Button>
              {isFailure(turnIntoTemplateComm) && (
                <div className={css.producePNGPreviewError}>
                  {turnIntoTemplateComm.error}
                </div>
              )}
              {isSuccess(turnIntoTemplateComm) && (
                <div className={css.producePNGPreviewMessage}>
                  You&apos;ve already requested to turn this briefing into a
                  template. From now on changes you make here will not be saved.
                </div>
              )}
            </>
          )}
          {isRegularTemplate && openingContext === 'opened-by-end-user' && (
            <Button
              classes={{ root: css.renderButton }}
              onClick={() => requestFinalOrder({ projectId, extensionId })}
              theme="red"
            >
              Order
            </Button>
          )}
        </div>
        <AdminContentOnly>
          {currentProject.sceneDimensions.width !== 0 &&
            insertionPointValues &&
            selectedObjectId && (
              <InsertionPointControls
                classes={{ root: css.insertionPointControls }}
                movementBoundaries={insertionPointValues.movementBoundaries}
                insertionPointDepth={insertionPointValues.insertionPointDepth}
                updateObject={updateObject}
                projectId={currentProject.id}
                objectId={selectedObjectId}
                sceneWidth={currentProject.sceneDimensions.width}
                sceneHeight={currentProject.sceneDimensions.height}
              />
            )}
        </AdminContentOnly>
        {productControlsValues !== null &&
          selectedObjectId &&
          (isRegularTemplate || userRole === 'admin') && (
            <ProductControlsContainer
              values={productControlsValues}
              shouldShowShapesScaleSlider={
                openingContext === 'opened-from-lineup-admin'
              }
              shouldShowMovementBoundariesControls={
                openingContext === 'opened-from-lineup-admin'
              }
              shouldShowBackgroundControls={
                openingContext === 'opened-from-lineup-admin'
              }
              isBackgroundInfluenceBeingApplied={isPending(
                applyBackgroundInfluenceChangesComm
              )}
              projectId={currentProject.id}
              selectedObjectId={selectedObjectId}
              pixelsInOneRelativeUnit={pixelsInOneRelativeUnit}
              maxPixelsInOneRelativeUnit={maxPixelsInOneRelativeUnit}
              updateObject={updateObject}
              changePixelsInOneRelativeUnit={changePixelsInOneRelativeUnit}
              areMovementBoundariesVisible={areMovementBoundariesVisible}
              setMovementBoundariesVisibility={setMovementBoundariesVisibility}
            />
          )}
      </section>
      <TurnIntoTemplateModal
        isOpen={isTurnIntoTemplateModalOpened}
        onCancel={() => setIsTurnIntoTemplateModalOpened(false)}
        onTurn={handleTurnIntoTemplate}
      />
    </>
  );
}

function mapState(state: AppState, { projectId }: OwnProps) {
  const selectedProjectObject = lineupProductsSelectors.selectSelectedObject(
    state,
    projectId
  );
  return {
    currentProject: lineupProductsSelectors.selectProject(state, projectId),
    order: lineupProductsSelectors.selectOrder(state),
    maxPixelsInOneRelativeUnit: lineupProductsSelectors.selectMaxPixelsInOneRelativeUnit(
      state,
      projectId
    ),
    selectedProjectObject,
    productControlsValues: lineupProductsSelectors.selectProductControlsValues(
      state,
      projectId
    ),
    layerSetsControlValues: lineupProductsSelectors.selectLayerSetsControlValues(
      state,
      projectId
    ),
    productsAmountInSceneControlValues: lineupProductsSelectors.selectProductsAmountInSceneControlValues(
      state,
      projectId
    ),
    pixelsInOneRelativeUnit: lineupProductsSelectors.selectPixelsInOneRelativeUnit(
      state,
      projectId
    ),
    currentSelection: lineupProductsSelectors.selectCurrentSelection(
      state,
      projectId
    ),
    producePNGPreviewComm: lineupProductsSelectors.selectProducePNGPreviewComm(
      state
    ),
    layerSetChangeDirectionOnRemoval: lineupProductsSelectors.selectLayerSetChangeDirectionOnRemoval(
      state,
      projectId
    ),
    shouldDisableRemoveProductsComposition: lineupProductsSelectors.selectShouldDisableRemoveProductsComposition(
      state,
      projectId
    ),
    turnIntoTemplateComm: briefTemplateSelectors.selectTurnIntoTemplateComm(
      state,
      projectId
    ),
    insertionPointValues: lineupProductsSelectors.selectInsertionPointValues(
      state,
      projectId
    ),
    openingContext: lineupProductsSelectors.selectOpeningContext(state),
    saveTemplateChangesComm: lineupProductsSelectors.selectSaveTemplateChangesComm(
      state,
      projectId
    ),
    applyBackgroundInfluenceChangesComm: lineupProductsSelectors.selectApplyBackgroundInfluenceChangesComm(
      state,
      selectedProjectObject?.id
    ),
    isBriefing: briefTemplateSelectors.selectIsTemplateBriefing(
      state,
      projectId
    ),
    areMovementBoundariesVisible: lineupProductsSelectors.selectAreMovementBoundariesVisible(
      state
    ),
  };
}

const actionsMap = {
  duplicateObject: lineupProductsActions.duplicateObject,
  rearrangeObjects: lineupProductsActions.rearrangeObjects,
  updateObject: lineupProductsActions.updateObject,
  changeLayerSet: lineupProductsActions.changeLayerSet,
  changeProductsAmountInScene:
    lineupProductsActions.changeProductsAmountInScene,
  changePixelsInOneRelativeUnit:
    lineupProductsActions.changePixelsInOneRelativeUnit,
  selectObject: lineupProductsActions.selectObject,
  requestPreviewImage: lineupProductsActions.requestPreviewImage,
  addLayerSet: lineupProductsActions.addLayerSet,
  removeLayerSet: lineupProductsActions.removeLayerSet,
  turnIntoTemplate: briefTemplateActions.turnIntoTemplate,
  requestFinalOrder: lineupProductsActions.requestFinalOrder,
  saveTemplateChanges: lineupProductsActions.saveTemplateChanges,
  setMovementBoundariesVisibility:
    lineupProductsActions.setMovementBoundariesVisibility,
};

export const AdjustSection = connect(mapState, actionsMap)(AdjustSectionView);

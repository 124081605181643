import React, { useState, useCallback } from 'react';
import cx from 'clsx';
import { makeObjectContainerId } from '../../viewersGlobalMap';
import { Props } from './Scene';

import css from './Scene.module.scss';

type SelectProductAreaProps = {
  objectDimensionsOnScene: NonNullable<
    Props['objectToDimensionsOnScene'][string]
  >;
  productId: string;
  projectId: string;
  selectObject: Props['selectObject'];
  selectedLayerSetId: string;
  interactionMode: Props['interactionMode'];
};

export function SelectProductArea(props: SelectProductAreaProps) {
  const {
    objectDimensionsOnScene,
    productId,
    interactionMode,
    projectId,
    selectObject,
    selectedLayerSetId,
  } = props;
  const {
    selectionAreaHeight,
    selectionAreaWidth,
    selectionAreaX,
    selectionAreaY,
  } = objectDimensionsOnScene;

  const [isMoving, setIsMoving] = useState(false);

  const delegateEventToViewer = useCallback(
    (event: MouseEvent) => {
      const rootElement = document.getElementById(
        makeObjectContainerId(selectedLayerSetId, productId)
      );

      const moveArea = rootElement?.querySelector('div[data-name="moveArea"]');
      const viewAngleInteraction = rootElement?.querySelector(
        'div[data-name="viewAngleInteraction"]'
      );

      moveArea?.dispatchEvent(new MouseEvent(event.type, event));
      viewAngleInteraction?.dispatchEvent(new MouseEvent(event.type, event));
    },
    [productId, selectedLayerSetId]
  );

  React.useEffect(() => {
    const handleMouseUp = () => setIsMoving(false);
    document.addEventListener('mouseup', handleMouseUp);

    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  return (
    <div
      key={productId}
      className={cx(css.selectionArea, isMoving && css.dragging)}
      style={{
        width: selectionAreaWidth,
        height: selectionAreaHeight,
        transform: `translate(${selectionAreaX}px, ${selectionAreaY}px)`,
      }}
      onMouseDown={(event: React.MouseEvent) => {
        if (interactionMode !== 'none') {
          setIsMoving(true);
          selectObject({
            objectId: productId,
            projectId,
          });

          delegateEventToViewer(event.nativeEvent);
        }
      }}
    />
  );
}

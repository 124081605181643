import React, { useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';

type UseLimitedInputControllerParams = {
  defaultValue: number;
  minValue: number;
  maxValue: number;
  onApply(value: number): void;
};

export const useLimitedInputController = ({
  minValue,
  maxValue,
  defaultValue,
  onApply,
}: UseLimitedInputControllerParams) => {
  const [value, setValue] = useState(String(defaultValue));

  useEffect(() => {
    setValue(String(defaultValue));
  }, [defaultValue]);

  const onValueSet = useCallback(
    (nextNotCheckedValue: number) => {
      const limitedValue = R.clamp(minValue, maxValue, nextNotCheckedValue);
      const nextValue = R.defaultTo(defaultValue, limitedValue);
      const nextInputValue = String(nextValue);

      setValue(nextInputValue);
      onApply(nextValue);
    },
    [defaultValue, minValue, maxValue, onApply]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (/^\d*$/.test(value)) {
        setValue(value);
      }
    },
    []
  );

  const onInputBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseInt(event.target.value, 10);
      onValueSet(parsedValue);
    },
    [onValueSet]
  );

  const onInputKeyPress = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const onPrev = useCallback(() => {
    onValueSet(Number(value) - 1);
  }, [value, onValueSet]);

  const onNext = useCallback(() => {
    onValueSet(Number(value) + 1);
  }, [value, onValueSet]);

  return {
    value,
    onInputChange,
    onInputKeyPress,
    onInputBlur,
    onPrev,
    onNext,
  };
};

import React from 'react';
import cx from 'clsx';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc';

import { ProjectObject } from '@domain/template';
import { makeUrl } from 'environment';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import css from './ProductsList.module.scss';

export type OnProductSelectValue = {
  productId: string;
  isOutOfSelection: boolean;
};

type ProductItemProps = {
  items: ProjectObject[];
  currentSelectionCount: number;
  selectedSceneObjectId: string | null;
  selectedProductId: string | null;
  onProductSelect(value: OnProductSelectValue): void;
  onProductDuplicate(): void;
};

const ProductDragHandler = SortableHandle(() => <div className={css.handle} />);

const ProductItemComponent: React.FC<
  Omit<ProductItemProps, 'items'> & {
    value: ProjectObject;
    indexHack: number;
  } & SortableElementProps
> = (props) => {
  const {
    value: x,
    indexHack: index,
    currentSelectionCount,
    selectedSceneObjectId,
    selectedProductId,
    onProductSelect,
    onProductDuplicate,
  } = props;

  const isOutOfSelection = index >= currentSelectionCount;
  const isProductActive = selectedProductId === x.id;

  return (
    <div
      className={cx(
        css.product,
        isProductActive && css.selectedProduct,
        x.id === selectedSceneObjectId && css.selectedObject
      )}
      onClick={() => onProductSelect({ productId: x.id, isOutOfSelection })}
      key={x.id}
    >
      <ProductDragHandler />
      <div
        className={cx(
          css.productMainContent,
          isOutOfSelection && css.outOfSelection
        )}
      >
        <img
          className={css.productThumbnail}
          alt={x.name}
          src={makeUrl(x.thumbnailUrl)}
        />
        <span className={css.productName}>{x.name}</span>
      </div>
      {isProductActive && (
        <button
          type="button"
          className={css.copyIconButton}
          onClick={onProductDuplicate}
        >
          <CopyIcon className={css.copyIcon} />
        </button>
      )}
    </div>
  );
};

const ProductItem = SortableElement(ProductItemComponent);

const ProductsListComponent: React.FC<
  ProductItemProps & SortableContainerProps
> = (props) => {
  const {
    items,
    currentSelectionCount,
    selectedSceneObjectId,
    selectedProductId,
    onProductSelect,
    onProductDuplicate,
  } = props;
  return (
    <div>
      {items.map((value, index) => (
        <ProductItem
          key={value.id}
          value={value}
          index={index}
          indexHack={index}
          selectedSceneObjectId={selectedSceneObjectId}
          selectedProductId={selectedProductId}
          currentSelectionCount={currentSelectionCount}
          onProductSelect={onProductSelect}
          onProductDuplicate={onProductDuplicate}
        />
      ))}
    </div>
  );
};
export const ProductsList = SortableContainer(ProductsListComponent);

// TODO: refactor reducer and remote eslint disable rule below
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createReducer, ActionCreator } from 'deox';
import * as R from 'ramda';
import { nanoid } from 'nanoid';
import { arrayMoveImmutable } from 'array-move';

import {
  createRemoteSuccess,
  foldRemoteOption,
  isNotAsked,
  isSuccess,
  mapRemote,
} from 'shared/remoteData';
import { AppState } from 'store/types';

import * as D from '@domain/template';
import { downscaleLayerObjects } from '@domain/template/downscaleLayerObjects';

import { decreaseProductLayerObjectsInLayerSet } from '@domain/template/decreaseProductLayerObjectsInLayerSet';
import { increaseProductLayerObjectsInLayerSet } from '@domain/template/increaseProductLayerObjectsInLayerSet';
import { changeProductObjectProperties } from '@domain/template/changeProductObjectProperties';
import { getUniqShapeAssets, RawOrder } from '@domain/order';
import {
  AdminTemplate,
  generateLayerSetBasedOnExistingOne,
} from '@domain/template';
import { InsertionPointLevelChanges, TopLevelUpdates } from './types';
import * as actions from './actions';
import {
  selectCurrentProject,
  selectProjectObjectsWithChanges,
  updateCurrentProject,
  updateLayersAccordingToProjectObjects,
} from './reducerHelpers';
import { calcPhysicalHeightsForShapes } from '../Editor/editorRendering/calcPhysicalHeightsForShapes';
import { selectSelectedTemplateInfo } from './selectors';
import {
  initOrderShape,
  DEFAULT_LAYER_SET_LEVEL_CHANGES,
  getSceneDimensionsBasedOnAvailableRect,
} from './temp';
import { defaultState, MAX_AMOUNT_OF_PRODUCTS_IN_SCENE } from './initial';
import { convertAdminTemplateToTemplate } from './convertAdminTemplateToTemplate';

import { projectsReducer } from './projectsReducer';
import { getInitialProjectProductsData } from './getInitialProjectProductsData';

export const dataReducer = createReducer(defaultState.data, (handleAction) => [
  handleAction(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions.loadOrderActions.success as ActionCreator<any>,
    (state, { payload: order }) => ({
      ...state,
      order,
      orderShapes: getUniqShapeAssets(order.value as RawOrder).map(
        initOrderShape
      ),
    })
  ),
  handleAction(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions.loadTemplatesGroupsActions.success as ActionCreator<any>,
    (state, { payload: templatesGroups }) => ({
      ...state,
      templatesGroups,
      selectedTemplatesGroupId: templatesGroups.value[0].id,
    })
  ),
  handleAction(actions.openTemplate, (state) => ({
    ...state,
    openingContext: 'opened-from-lineup-admin' as const,
  })),
  handleAction(
    actions.openTab,
    (state, { payload: { tabName: newTabName } }) => ({
      ...state,
      activeTabName: newTabName,
      ...(newTabName === 'templates'
        ? {
            selectedTemplateId: null,
          }
        : {}),
    })
  ),
  handleAction(
    actions.setMovementBoundariesVisibility,
    (state, { payload }) => ({
      ...state,
      areMovementBoundariesVisible: payload,
    })
  ),
  handleAction(
    actions.selectTemplateGroup,
    (state, { payload: { groupId } }) => ({
      ...state,
      selectedTemplatesGroupId: groupId,
      isBriefTemplateOpened: false,
    })
  ),
  handleAction(
    actions.addBriefing,
    (state, { payload: { template: templateInfoToAdd } }) => {
      const templatesGroups = isNotAsked(state.templatesGroups)
        ? createRemoteSuccess([])
        : state.templatesGroups;

      return foldRemoteOption(
        templatesGroups,
        () => state,
        (templatesGroups) => {
          const briefingsGroup = templatesGroups.find(
            (x) => x.name === D.BRIEFINGS_GROUP_NAME
          );

          const briefingsGroupId = briefingsGroup?.id || nanoid();

          return {
            ...state,
            templatesGroups: createRemoteSuccess(
              briefingsGroup
                ? templatesGroups.map((x) =>
                    x.name === D.BRIEFINGS_GROUP_NAME
                      ? {
                          ...x,
                          templates: x.templates.concat(templateInfoToAdd),
                        }
                      : x
                  )
                : templatesGroups.concat({
                    name: D.BRIEFINGS_GROUP_NAME,
                    id: briefingsGroupId,
                    templates: [templateInfoToAdd],
                  })
            ),
            selectedTemplatesGroupId: briefingsGroupId,
            selectedTemplateId: null,
            isBriefTemplateOpened: false,
          };
        }
      );
    }
  ),
  handleAction(
    actions.selectTemplate,
    (state, { payload: { templateId, isBriefing } }) => {
      const {
        selectedTemplatesGroupId,
        templatesGroups: templatesGroupsRD,
        projects,
        order: orderRD,
      } = state;

      if (!isSuccess(templatesGroupsRD) || !isSuccess(orderRD)) return state;

      const order = orderRD.value;
      const templatesGroups = templatesGroupsRD.value;
      const selectedGroup = templatesGroups.find(
        (x) => x.id === selectedTemplatesGroupId
      )!;

      const selectedTemplateInfo = selectedGroup.templates.find(
        (x) => x.id === templateId
      )!;
      const { template: selectedAdminTemplate } = selectedTemplateInfo;

      const projectAtSelectedId = projects[templateId];

      if (projectAtSelectedId) {
        return {
          ...state,
          selectedTemplateId: templateId,
        };
      }

      const {
        objects,
        objectsChangesInLayerSets,
      } = getInitialProjectProductsData({
        adminTemplate: selectedAdminTemplate,
        order,
        isBriefing,
      });

      const selectedAdminLayerSet = selectedAdminTemplate.layerSetIdOpenedByDefault
        ? selectedAdminTemplate.layerSets.find(
            (x) => x.id === selectedAdminTemplate.layerSetIdOpenedByDefault
          )!
        : selectedAdminTemplate.layerSets[0];

      const selectedTemplate: D.Template = convertAdminTemplateToTemplate(
        selectedTemplateInfo,
        selectedAdminLayerSet
      );

      return {
        ...state,
        isBriefTemplateOpened: false,
        selectedTemplateId: templateId,
        projects: {
          ...state.projects,
          [templateId]: {
            id: templateId,
            objects,
            objectsChangesInLayerSets,
            selectedLayerSetId: selectedAdminLayerSet.id,
            selectedLayerSetIndex: selectedAdminTemplate.layerSets.findIndex(
              (x) => x.id === selectedAdminLayerSet.id
            ),
            selectObjectId: objects[0]?.id ?? null,
            sceneDimensions: {
              widthDownscaleFactor: 1,
              heightDownscaleFactor: 1,
              width: 0,
              height: 0,
            },
            isDownscaled: false,
            orderShapes: state.orderShapes,
            template: {
              ...selectedTemplate,
              layers: updateLayersAccordingToProjectObjects(
                selectedTemplate.layers,
                objects
              ),
            },
            sceneLoader: {
              shouldShow: false,
              message: '',
            },
          },
        },
      };
    }
  ),
  handleAction(
    actions.selectObject,
    (state, { payload: { objectId, projectId } }) =>
      updateCurrentProject(state, projectId, (prevProject) => ({
        ...prevProject,
        selectObjectId: objectId,
      }))
  ),
  handleAction(
    actions.changeLayerSet,
    (state, { payload: { direction, projectId } }) => {
      const project = selectCurrentProject(state, projectId)!;
      const { selectedLayerSetIndex } = project;
      const selectedTemplateInfo = selectSelectedTemplateInfo({
        lineupProducts: {
          data: state,
        },
      } as AppState)!;
      const { template: selectedAdminTemplate } = selectedTemplateInfo;

      const newLayerSetIndex =
        direction === 'back'
          ? selectedLayerSetIndex - 1
          : selectedLayerSetIndex + 1;
      const newLayerSet = selectedAdminTemplate.layerSets[newLayerSetIndex];

      const selectedTemplate: D.Template = convertAdminTemplateToTemplate(
        selectedTemplateInfo,
        newLayerSet
      );

      return updateCurrentProject(state, projectId, (prevProject) => {
        const template = {
          ...selectedTemplate,
          layers: updateLayersAccordingToProjectObjects(
            selectedTemplate.layers,
            prevProject.objects
          ),
        };
        const downscaledTemplate = downscaleLayerObjects(
          template,
          prevProject.sceneDimensions.widthDownscaleFactor,
          prevProject.sceneDimensions.heightDownscaleFactor
        );

        const selectedLayerSetId = newLayerSet.id;

        const objectsChangesInLayerSets = prevProject.objectsChangesInLayerSets[
          selectedLayerSetId
        ]
          ? prevProject.objectsChangesInLayerSets
          : {
              ...prevProject.objectsChangesInLayerSets,
              [selectedLayerSetId]: project.objects.reduce(
                (acc, object) => ({
                  ...acc,
                  [object.id]: DEFAULT_LAYER_SET_LEVEL_CHANGES,
                }),
                {}
              ),
            };

        return {
          ...prevProject,
          selectedLayerSetId,
          selectedLayerSetIndex: newLayerSetIndex,
          objectsChangesInLayerSets,
          template: downscaledTemplate,
          sceneLoader: {
            shouldShow: true,
            message: 'Changing shape arrangement',
          },
        };
      });
    }
  ),
  handleAction(
    actions.duplicateObject,
    (state, { payload: { objectId, projectId } }) => {
      const { template, objects } = selectCurrentProject(state, projectId)!;
      const objectToDuplicate = objects.find((x) => x.id === objectId)!;
      const currentSelection = D.pickProductLayerObjects(template).length;

      const newObject: D.ProjectObject = {
        ...objectToDuplicate,
        name: `Product ${objects.length + 1}`,
        id: nanoid(),
      };

      const prevObjectChangesInLayerSets =
        state.projects[template.id].objectsChangesInLayerSets;
      const newObjectChangesInLayerSets = Object.entries(
        prevObjectChangesInLayerSets
      ).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            ...value,
            [newObject.id]: DEFAULT_LAYER_SET_LEVEL_CHANGES,
          },
        }),
        {}
      );

      return {
        ...state,
        projects: {
          ...state.projects,
          [template.id]: {
            ...state.projects[template.id],
            objects: R.insert(currentSelection, newObject, objects),
            objectsChangesInLayerSets: newObjectChangesInLayerSets,
          } as D.Project,
        },
      };
    }
  ),
  handleAction(
    actions.rearrangeObjects,
    (state, { payload: { oldIndex, newIndex, projectId } }) => {
      const project = selectCurrentProject(state, projectId)!;
      const { template } = project;
      const currentSelection = D.pickProductLayerObjects(template).length;
      const maxIndexOfObjectInSelection = currentSelection - 1;
      /* TODO: save shapes order per layer set */
      // if change is out of selection, i.e. doesn't affect content on scene, then just rearrange objects in state
      const changeIsOutOfSelection =
        oldIndex > maxIndexOfObjectInSelection &&
        newIndex > maxIndexOfObjectInSelection;

      const objects = selectProjectObjectsWithChanges(project);
      const rearrangedObjects = arrayMoveImmutable(
        objects,
        oldIndex,
        newIndex
      ).map((x) => x[0]);

      if (changeIsOutOfSelection) {
        return updateCurrentProject(state, projectId, (prevProject) => ({
          ...prevProject,
          objects: rearrangedObjects,
        }));
      }

      const { selectedLayerSetId } = project;

      const [prevObject, prevObjectChanges] = objects[oldIndex];
      const [nextObject, nextObjectChanges] = objects[newIndex];

      const newObjectsChangesInLayerSets = {
        ...project.objectsChangesInLayerSets,
        [selectedLayerSetId]: {
          ...project.objectsChangesInLayerSets[selectedLayerSetId],
          [prevObject.id]: {
            ...project.objectsChangesInLayerSets[selectedLayerSetId][
              prevObject.id
            ],
            insertionPointOffset: nextObjectChanges.insertionPointOffset,
            hasBackgroundInfluence: nextObjectChanges.hasBackgroundInfluence,
          },
          [nextObject.id]: {
            ...project.objectsChangesInLayerSets[selectedLayerSetId][
              nextObject.id
            ],
            insertionPointOffset: prevObjectChanges.insertionPointOffset,
            hasBackgroundInfluence: prevObjectChanges.hasBackgroundInfluence,
          },
        },
      };

      const updatedLayers = updateLayersAccordingToProjectObjects(
        template.layers,
        rearrangedObjects
      );

      return updateCurrentProject(state, projectId, (prevProject) => ({
        ...prevProject,
        objectsChangesInLayerSets: newObjectsChangesInLayerSets,
        objects: rearrangedObjects,
        template: {
          ...prevProject.template,
          layers: updatedLayers,
        },
      }));
    }
  ),
  handleAction(
    actions.applyObjectChanges,
    (state, { payload: { objectId, projectId, ...updates } }) => {
      const currentProject = selectCurrentProject(state, projectId)!;
      const {
        objects,
        template,
        objectsChangesInLayerSets,
        selectedLayerSetId,
      } = currentProject;

      const topLevelProperties = [
        'artworkId',
        'imgUrl',
      ] as (keyof TopLevelUpdates)[];
      const topLevelUpdates = R.pick(topLevelProperties, updates);
      const layerSetLevelUpdates = R.omit(topLevelProperties, updates);

      const updatedObjects = objects.map((x) =>
        x.id === objectId ? { ...x, ...topLevelUpdates } : x
      );

      const newObjectsChangesInLayerSets = {
        ...objectsChangesInLayerSets,
        [selectedLayerSetId]: {
          ...objectsChangesInLayerSets[selectedLayerSetId],
          [objectId]: {
            ...objectsChangesInLayerSets[selectedLayerSetId][objectId],
            ...layerSetLevelUpdates,
          },
        },
      };

      const updatedLayers = updateLayersAccordingToProjectObjects(
        template.layers,
        updatedObjects
      );

      return updateCurrentProject(state, projectId, (prevProject) => ({
        ...prevProject,
        objectsChangesInLayerSets: newObjectsChangesInLayerSets,
        objects: updatedObjects,
        template: {
          ...prevProject.template,
          layers: updatedLayers,
        },
      }));
    }
  ),
  handleAction(
    actions.downscaleObjects,
    (
      state,
      { payload: { availableHeight: floorAvailableHeight, projectId } }
    ) => {
      const { template } = selectCurrentProject(state, projectId)!;
      const { resolution, pixelsInOneRelativeUnit } = template;
      const orderRD = state.order;

      if (!isSuccess(orderRD)) return state;

      const order = orderRD.value;

      const sceneDimensions = getSceneDimensionsBasedOnAvailableRect(
        floorAvailableHeight,
        resolution
      );

      return updateCurrentProject(state, projectId, (prevProject) => {
        const { heightDownscaleFactor, widthDownscaleFactor } = sceneDimensions;

        const orderShapes = calcPhysicalHeightsForShapes(
          prevProject.orderShapes,
          getUniqShapeAssets(order.assets),
          pixelsInOneRelativeUnit,
          heightDownscaleFactor
        );

        const originalTemplate = downscaleLayerObjects(
          prevProject.template,
          1 / prevProject.sceneDimensions.widthDownscaleFactor,
          1 / prevProject.sceneDimensions.heightDownscaleFactor
        );

        const template = downscaleLayerObjects(
          originalTemplate,
          widthDownscaleFactor,
          heightDownscaleFactor
        );

        return {
          ...prevProject,
          sceneDimensions,
          orderShapes,
          template,
          isDownscaled: true,
        };
      });
    }
  ),
  handleAction(actions.shapesBoundingBoxDetailsReady, (state, { payload }) => ({
    ...state,
    shapesBoundingBoxes: {
      data: payload,
      isSetup: true,
    },
  })),
  handleAction(
    actions.changePixelsInOneRelativeUnit,
    (state, { payload: { value: pixelsInOneRelativeUnit, projectId } }) => {
      const templatesGroupsRD = state.templatesGroups;
      const orderRD = state.order;
      if (!isSuccess(templatesGroupsRD) || !isSuccess(orderRD)) return state;
      const templatesGroups = templatesGroupsRD.value;
      const { selectedTemplatesGroupId, selectedTemplateId } = state;

      const newEditorState = {
        ...state,
        templatesGroups: createRemoteSuccess(
          templatesGroups.map((group) =>
            group.id === selectedTemplatesGroupId
              ? {
                  ...group,
                  templates: group.templates.map((templateInfo) =>
                    templateInfo.id === selectedTemplateId
                      ? {
                          ...templateInfo,
                          template: {
                            ...templateInfo.template,
                            pixelsInOneRelativeUnit,
                          },
                        }
                      : templateInfo
                  ),
                }
              : group
          )
        ),
      };

      return updateCurrentProject(newEditorState, projectId, (prevProject) => {
        const orderShapes = calcPhysicalHeightsForShapes(
          prevProject.orderShapes,
          getUniqShapeAssets(orderRD.value.assets),
          pixelsInOneRelativeUnit,
          prevProject.sceneDimensions.heightDownscaleFactor
        );

        return {
          ...prevProject,
          orderShapes,
          template: {
            ...prevProject.template,
            pixelsInOneRelativeUnit,
          },
        };
      });
    }
  ),
  handleAction(
    actions.toggleSceneLoader,
    (state, { payload: { value, message, projectId } }) =>
      updateCurrentProject(state, projectId, (prevProject) => ({
        ...prevProject,
        sceneLoader: {
          shouldShow: value,
          message: message || '',
        },
      }))
  ),
  handleAction(actions.openBriefingCreator, (state) => ({
    ...state,
    isBriefTemplateOpened: true,
  })),
  handleAction(
    actions.interactionModeSelected,
    (state, { payload: { interactionMode } }) => ({
      ...state,
      interactionMode,
    })
  ),
  handleAction(actions.addLayerSet, (state, action) => {
    const {
      payload: { projectId },
    } = action;

    const project = selectCurrentProject(state, projectId)!;
    const { selectedLayerSetIndex } = project;
    const selectedAdminTemplate = selectSelectedTemplateInfo({
      lineupProducts: {
        data: state,
      },
    } as AppState)?.template as AdminTemplate;

    const layerSetToDuplicate =
      selectedAdminTemplate.layerSets[selectedLayerSetIndex];
    const newLayerSet = generateLayerSetBasedOnExistingOne(
      layerSetToDuplicate,
      projectId
    );
    const newLayerSets = selectedAdminTemplate.layerSets.concat(newLayerSet);

    const {
      templatesGroups,
      selectedTemplatesGroupId,
      selectedTemplateId,
    } = state;

    return {
      ...state,
      templatesGroups: mapRemote(templatesGroups, (templatesGroups) =>
        templatesGroups.map((group) =>
          group.id === selectedTemplatesGroupId
            ? {
                ...group,
                templates: group.templates.map((templateInfo) =>
                  templateInfo.id === selectedTemplateId
                    ? {
                        ...templateInfo,
                        template: {
                          ...templateInfo.template,
                          layerSets: newLayerSets,
                        },
                      }
                    : templateInfo
                ),
              }
            : group
        )
      ),
      projects: projectsReducer(state.projects, action),
    };
  }),
  handleAction(actions.removeLayerSet, (state, action) => {
    const {
      payload: { layerSetId: layerSetIdToRemove, projectId },
    } = action;
    const { selectedLayerSetId } = selectCurrentProject(state, projectId)!;
    const selectedAdminTemplate = selectSelectedTemplateInfo({
      lineupProducts: {
        data: state,
      },
    } as AppState)?.template as AdminTemplate;

    if (selectedAdminTemplate.layerSets.length <= 1) {
      return state;
    }

    const newLayerSets = selectedAdminTemplate.layerSets.filter(
      (x) => x.id !== layerSetIdToRemove
    );
    const newSelectedLayerSetIndex = newLayerSets.findIndex(
      (x) => x.id === selectedLayerSetId
    );

    const {
      templatesGroups,
      selectedTemplatesGroupId,
      selectedTemplateId,
    } = state;

    const newProjects = projectsReducer(state.projects, action);

    return {
      ...state,
      templatesGroups: mapRemote(templatesGroups, (templatesGroups) =>
        templatesGroups.map((group) =>
          group.id === selectedTemplatesGroupId
            ? {
                ...group,
                templates: group.templates.map((templateInfo) =>
                  templateInfo.id === selectedTemplateId
                    ? {
                        ...templateInfo,
                        template: {
                          ...templateInfo.template,
                          layerSets: newLayerSets,
                        },
                      }
                    : templateInfo
                ),
              }
            : group
        )
      ),
      projects: {
        ...newProjects,
        [projectId]: {
          ...newProjects[projectId],
          selectedLayerSetIndex: newSelectedLayerSetIndex,
        } as D.Project,
      },
    };
  }),
  handleAction(actions.changeProductsAmountInScene, (state, action) => {
    const {
      payload: { amount, projectId },
    } = action;

    const templatesGroupsRD = state.templatesGroups;
    const orderRD = state.order;

    if (!(isSuccess(templatesGroupsRD) && isSuccess(orderRD))) {
      return state;
    }

    const shouldDoNothing =
      amount <= 0 || amount > MAX_AMOUNT_OF_PRODUCTS_IN_SCENE;

    if (shouldDoNothing) {
      return state;
    }

    const templatesGroups = templatesGroupsRD.value;
    const { selectedTemplatesGroupId, selectedTemplateId } = state;

    const project = selectCurrentProject(state, projectId)!;

    const { selectedLayerSetId } = project;
    const selectedTemplateInfo = selectSelectedTemplateInfo({
      lineupProducts: {
        data: state,
      },
    } as AppState)!;
    const { template: selectedAdminTemplate } = selectedTemplateInfo;

    const productLayerObjectsAmount = D.pickATProductLayerObjects(
      selectedAdminTemplate,
      selectedLayerSetId
    ).length;

    const deltaAmountChange = amount - productLayerObjectsAmount;

    const updateAdminTemplate = (template: D.AdminTemplate) =>
      deltaAmountChange < 0
        ? decreaseProductLayerObjectsInLayerSet(template, selectedLayerSetId)
        : increaseProductLayerObjectsInLayerSet(template, selectedLayerSetId);

    const updatedAdminTemplate = R.range(0, Math.abs(deltaAmountChange)).reduce(
      updateAdminTemplate,
      selectedAdminTemplate
    );

    const newEditorState = {
      ...state,
      templatesGroups: createRemoteSuccess(
        templatesGroups.map((group) =>
          group.id === selectedTemplatesGroupId
            ? {
                ...group,
                templates: group.templates.map((template) =>
                  template.id === selectedTemplateId
                    ? {
                        ...selectedTemplateInfo,
                        template: updatedAdminTemplate,
                      }
                    : template
                ),
              }
            : group
        )
      ),
    };

    return {
      ...newEditorState,
      ...updateCurrentProject(newEditorState, projectId, (prevProject) => {
        const selectedTemplate: D.Template = convertAdminTemplateToTemplate(
          selectedTemplateInfo,
          updatedAdminTemplate.layerSets[project.selectedLayerSetIndex]
        );

        const template = {
          ...selectedTemplate,
          layers: updateLayersAccordingToProjectObjects(
            selectedTemplate.layers,
            prevProject.objects
          ),
        };
        const downscaledTemplate = downscaleLayerObjects(
          template,
          prevProject.sceneDimensions.widthDownscaleFactor,
          prevProject.sceneDimensions.heightDownscaleFactor
        );

        return {
          ...prevProject,
          template: downscaledTemplate,
        };
      }),
    };
  }),
  handleAction(actions.changeInsertionPointLevelProperties, (state, action) => {
    const {
      payload: { projectId, objectId, changes },
    } = action;
    const templatesGroupsRD = state.templatesGroups;
    const orderRD = state.order;
    if (!(isSuccess(templatesGroupsRD) && isSuccess(orderRD))) return state;
    const templatesGroups = templatesGroupsRD.value;
    const { selectedTemplatesGroupId, selectedTemplateId } = state;

    const project = selectCurrentProject(state, projectId)!;

    const { selectedLayerSetId } = project;
    const selectedTemplateInfo = selectSelectedTemplateInfo({
      lineupProducts: {
        data: state,
      },
    } as AppState)!;
    const { template: selectedAdminTemplate } = selectedTemplateInfo;

    function applyInsertionLevelChanges(
      projectObject: D.ATProductLayerObject,
      changes: InsertionPointLevelChanges
    ): D.ATProductLayerObject {
      const newProjectObject = { ...projectObject };
      if (changes.insertionPointDepth !== undefined) {
        newProjectObject.insertionPoint = [
          newProjectObject.insertionPoint[0],
          newProjectObject.insertionPoint[1],
          changes.insertionPointDepth,
        ];
      }
      if (changes.backgroundBaseColor !== undefined) {
        newProjectObject.backgroundBaseColor = changes.backgroundBaseColor;
      }
      return newProjectObject;
    }

    const updatedAdminTemplate = changeProductObjectProperties(
      selectedAdminTemplate,
      selectedLayerSetId,
      project.objects.findIndex((x) => x.id === objectId),
      (prev) => applyInsertionLevelChanges(prev, changes)
    );

    const newEditorState = {
      ...state,
      templatesGroups: createRemoteSuccess(
        templatesGroups.map((group) =>
          group.id === selectedTemplatesGroupId
            ? {
                ...group,
                templates: group.templates.map((template) =>
                  template.id === selectedTemplateId
                    ? {
                        ...selectedTemplateInfo,
                        template: updatedAdminTemplate,
                      }
                    : template
                ),
              }
            : group
        )
      ),
    };

    return {
      ...newEditorState,
      ...updateCurrentProject(newEditorState, projectId, (prevProject) => {
        const selectedTemplate: D.Template = convertAdminTemplateToTemplate(
          selectedTemplateInfo,
          updatedAdminTemplate.layerSets[project.selectedLayerSetIndex]
        );

        const template = {
          ...selectedTemplate,
          layers: updateLayersAccordingToProjectObjects(
            selectedTemplate.layers,
            prevProject.objects
          ),
        };

        const downscaledTemplate = downscaleLayerObjects(
          template,
          prevProject.sceneDimensions.widthDownscaleFactor,
          prevProject.sceneDimensions.heightDownscaleFactor
        );

        return {
          ...prevProject,
          template: downscaledTemplate,
        };
      }),
    };
  }),
]);

import { useEffect, useState } from 'react';
import cx from 'clsx';
import BaseSlider, {
  SliderProps,
  Handle,
  RangeProps,
  SliderTooltip,
} from 'rc-slider';
import { usePrevious } from 'react-use';

import css from './Range.module.scss';
import sliderCss from './Slider.module.scss';

type Props = RangeProps & {
  value: [number, number];
  min: number;
  max: number;
};

const { createSliderWithTooltip } = BaseSlider;
const BaseRange = createSliderWithTooltip(BaseSlider.Range);

const RangeHandle = (
  props: Parameters<NonNullable<SliderProps['handle']>>[0]
) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle
        value={value}
        {...(restProps as unknown)}
        className={cx(restProps.className, sliderCss.handle)}
      />
    </SliderTooltip>
  );
};

export const Range: React.FC<Props> = (props) => {
  const { onChange, value, ...sliderProps } = props;

  const [sliderValue, setSliderValue] = useState<[number, number]>(value);

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (
      prevValue &&
      isRangeValueDifferent(prevValue, value) &&
      isRangeValueDifferent(sliderValue, value)
    ) {
      setSliderValue(value);
    }
  }, [prevValue, sliderValue, value, sliderProps.min, sliderProps.max]);

  const prevSliderValue = usePrevious(sliderValue);
  useEffect(() => {
    if (prevSliderValue !== undefined && prevSliderValue !== sliderValue) {
      onChange?.(sliderValue);
    }
  }, [onChange, prevSliderValue, sliderValue]);

  return (
    <div className={cx(sliderCss.slider, css.range)}>
      <BaseRange
        {...sliderProps}
        value={sliderValue}
        className={sliderCss.baseSlider}
        handle={RangeHandle}
        onChange={(value) => setSliderValue(value as [number, number])}
      />
    </div>
  );
};

function isRangeValueDifferent(a: [number, number], b: [number, number]) {
  return a[0] !== b[0] || a[1] !== b[1];
}

import { useCallback, useRef } from 'react';
import { mapRefValue } from 'utils/mapRefValue';
import { Button } from 'shared/components/Button/Button';
import { isFailure, isPending, RemoteData } from 'shared/remoteData';

import css from './UploadBackground.module.scss';

export type UploadData = { file: File; image: string; imageName: string };

type UploadBackgroundProps = {
  onUpload(data: UploadData): void;
  uploadCommunication: RemoteData<void>;
  error?: string;
};

export function UploadBackground(props: UploadBackgroundProps) {
  const { onUpload, error, uploadCommunication } = props;
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = useCallback(() => {
    mapRefValue(uploadInputRef, (uploadInput) => {
      uploadInput.click();
    });
  }, []);

  const handleBackgroundUpload = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      const { files } = event.target as HTMLInputElement;
      if (files) {
        const reader = new FileReader();
        const file = files[0];
        reader.readAsDataURL(file);

        const image: string = await new Promise((res) => {
          reader.addEventListener('load', () => {
            res(reader.result as string);
          });
        });

        onUpload({ file, image, imageName: file.name });
      }
    },
    [onUpload]
  );

  const errorMessage =
    error || (isFailure(uploadCommunication) && uploadCommunication.error);

  return (
    <form
      onSubmit={(event) => event.preventDefault()}
      className={css.backgroundUploadForm}
    >
      <input
        ref={uploadInputRef}
        className={css.inputFile}
        id="file"
        type="file"
        accept=".png, .jpg, .jpeg"
        onChange={handleBackgroundUpload}
      />
      <Button
        onClick={handleUploadClick}
        theme="red"
        classes={{ root: css.backgroundUploadButton }}
        disabled={isPending(uploadCommunication)}
      >
        {isPending(uploadCommunication)
          ? 'Uploading background...'
          : 'Upload background'}
      </Button>
      {errorMessage && <div className={css.error}>{errorMessage}</div>}
    </form>
  );
}

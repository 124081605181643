import { Shape } from '@domain/template';
import { ShapeAsset } from '@domain/order';

import sizes from './sizes.json';

const DEFAULT_SIZE = 70;

export const HARDCODED_SHAPES_SIZES: Record<string, number> & {
  defaultSize: number;
} = {
  ...Object.entries(sizes).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? DEFAULT_SIZE : value,
    }),
    {}
  ),
  defaultSize: DEFAULT_SIZE,
};

export function calcPhysicalHeightsForShapes(
  shapes: Shape[],
  assets: ShapeAsset[],
  pxsInOneRelativeUnit: number,
  heightDownscaleFactor: number
): Shape[] {
  return shapes.map((x) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const shapeConfigId = assets.find((asset) => asset.assetId === x.assetId)!
      .shape360Config.id;

    const physicalHeight =
      ((HARDCODED_SHAPES_SIZES[shapeConfigId] ||
        HARDCODED_SHAPES_SIZES.defaultSize) *
        pxsInOneRelativeUnit) /
      heightDownscaleFactor;

    return {
      ...x,
      physicalHeight,
    };
  });
}

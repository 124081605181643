import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';

class HttpActions {
  private request: AxiosInstance;

  constructor(baseURL: string, headers?: AxiosRequestConfig['headers']) {
    const config: AxiosRequestConfig = {
      baseURL,
      headers,
      withCredentials: true,
      auth: {
        username: 'LdpsUR3yXi2ip',
        password: 'U(36%ehuZyTvtSNq*#',
      },
    };
    this.request = axios.create(config);
  }

  public get<T>(
    url: string,
    params?: AxiosRequestConfig['params'],
    options?: AxiosRequestConfig
  ): AxiosPromise<T> {
    const config: AxiosRequestConfig = { params, ...options };
    return this.request.get(url, config);
  }

  public post<T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    options?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return this.request.post(url, data, options);
  }

  public patch<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    options: AxiosRequestConfig
  ): AxiosPromise<T> {
    return this.request.patch(url, data, options);
  }

  public del<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    params?: AxiosRequestConfig['params'],
    options?: AxiosRequestConfig
  ): AxiosPromise<T> {
    const config: AxiosRequestConfig = { url, data, params, ...options };
    return this.request.delete(url, config);
  }

  public put<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    params?: AxiosRequestConfig['params'],
    options?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return this.request.put(url, data, { params, ...options });
  }
}

export { HttpActions };

import * as R from 'ramda';
import { makeUrl } from 'environment';
import { ShapeAsset } from '@domain/order';
import {
  ConfigurableShapeSettings,
  setViewerSettings,
  convertToBase64,
  getViewer,
  ViewerSetupSettings,
} from './getBase64Image';

const baseConfigurableSettings: ConfigurableShapeSettings = {
  backgroundImageUrl: null,
  // backgroundColor: 'ffffff',
  scale: 1,
  reflection: 0,
  shadow: 0,
  rotation: 0,
  angle: {
    horizontal: 0,
    vertical: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
};

const objectToViewerMap = new Map<string, [Viewer, Shape360Api]>();

export function getViewerSetupSettings(asset: ShapeAsset): ViewerSetupSettings {
  return {
    shape360Config: R.clone(asset.shape360Config),
    mp4Url: makeUrl(asset.mp4Url),
    webMUrl: makeUrl(asset.webMUrl),
    uvMappingUrls: asset.uvMappingUrls,
  };
}

type MakeImageParams = {
  objectId: string;
  asset: ShapeAsset;
  partialConfigurableSettings: ConfigurableShapeSettings;
  container?: HTMLDivElement;
  destroyAfterRenderingImage?: boolean;
};

export async function renderImage(
  params: MakeImageParams
): Promise<string | undefined> {
  const {
    objectId,
    asset,
    partialConfigurableSettings,
    container,
    destroyAfterRenderingImage,
  } = params;
  window.console.log('container is ', container);
  if (objectToViewerMap.has(objectId)) {
    window.console.log('reusing viewer for ', objectId);
  } else {
    window.console.log('going to create viewer for', objectId);
  }

  const configurableSettings = {
    previewHeight: partialConfigurableSettings?.previewHeight, // int - height and width of the resulted image in px
    includeBackground: partialConfigurableSettings?.includeBackground ?? false, // bool - is need crop background around the image (default true)

    artworkUrl: partialConfigurableSettings.artworkUrl
      ? makeUrl(partialConfigurableSettings.artworkUrl)
      : undefined,

    backgroundImageUrl: partialConfigurableSettings?.backgroundImageUrl ?? null, // The link. Can be used instead of backgroundColor
    backgroundColor: partialConfigurableSettings?.backgroundColor, // rgb for example "109fa4"

    scale: partialConfigurableSettings?.scale ?? baseConfigurableSettings.scale, // float: 0.0 ... 1.0
    reflection:
      partialConfigurableSettings?.reflection ??
      baseConfigurableSettings.reflection, // float: 0.0 ... 1.0
    shadow:
      partialConfigurableSettings?.shadow ?? baseConfigurableSettings.shadow, // float: 0.0 ... 1.0
    rotation: baseConfigurableSettings.rotation, // float 0.0 ... 360.0
    angle: {
      ...baseConfigurableSettings.angle,
      ...partialConfigurableSettings?.angle,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any, // {horizontal: -90.0..+90.0, vertical: any float}
    position:
      partialConfigurableSettings?.position ??
      baseConfigurableSettings.position, // {x, y} float 0 ... 360
  };

  const isInitPhase = !objectToViewerMap.has(objectId);

  const [viewer, shape] = objectToViewerMap.has(objectId)
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectToViewerMap.get(objectId)!
    : await (async () => {
        const viewerPack = await getViewer(
          getViewerSetupSettings(asset),
          container
        );
        const [viewer, shape] = viewerPack;

        await new Promise<void>((resolve) => {
          requestAnimationFrame(async () => {
            await viewer.renderStep();
            await setViewerSettings(viewer, shape, asset, configurableSettings);
            await viewer.renderStep();
            requestAnimationFrame(() => {
              const backgroundLayer = container?.querySelectorAll(
                '[data-name=background]'
              )?.[0] as HTMLDivElement;
              if (backgroundLayer) {
                backgroundLayer.style.display = 'none';
              }
              resolve();
            });
          });
        });

        objectToViewerMap.set(objectId, viewerPack);
        return viewerPack;
      })();

  if (!isInitPhase) {
    await setViewerSettings(viewer, shape, asset, configurableSettings);
    await viewer.renderStep();
  }

  const { previewHeight, includeBackground } = configurableSettings;
  const preview = await viewer.generatePreview(
    previewHeight,
    includeBackground || false
  );

  if (destroyAfterRenderingImage) {
    viewer.destroy();
    objectToViewerMap.delete(objectId);
  }

  const finalImage = await convertToBase64(preview);

  return finalImage;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ProductLayerObject,
  Shape,
  InsertionPointOffset,
  ShapeBoundingBoxDetails,
} from '@domain/template';

export type ObjectDimensionsOnScene = ReturnType<
  typeof calcObjectDimensionsOnScene
>;

export function calcObjectDimensionsOnScene(
  orderShapes: Shape[],
  assetId: string,
  shapeBoundingBoxes: ShapeBoundingBoxDetails,
  [baseX, baseY, depthScale]: ProductLayerObject['insertionPoint'],
  insertionPointOffset: InsertionPointOffset
) {
  const x = baseX + insertionPointOffset[0];
  const y = baseY + insertionPointOffset[1];

  const shape = orderShapes.find((x) => x.assetId === assetId)!;
  const shapeRect = shapeBoundingBoxes;
  const desiredHeight = shape.physicalHeight * depthScale;

  if (desiredHeight === 0) return null;
  const desiredWidth =
    (shapeRect.shapeWidth * desiredHeight) / shapeRect.shapeHeight;

  const selectionAreaWidth = desiredWidth;
  const selectionAreaHeight = desiredHeight;
  const selectionAreaX = x - selectionAreaWidth / 2;
  const selectionAreaY = y - selectionAreaHeight;

  return {
    selectionAreaWidth,
    selectionAreaHeight,
    selectionAreaX,
    selectionAreaY,
  };
}

/* eslint-disable prefer-promise-reject-errors */
import createDebug from 'debug';
import { ShapeAsset } from '@domain/order';

const logShapeVideoLoad = createDebug('[waitForShapeVideoLoad]');

// Most of the code here is from GRIP.UI
const Viewer = window.viewer;

export const loadImage = async (imageSrc: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = document.createElement('img');
    image.crossOrigin = 'Anonymous';
    image.src = imageSrc;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });

export const loadVideo = async (videoSrc: string): Promise<HTMLVideoElement> =>
  new Promise((resolve) => {
    const video = document.createElement('video');
    video.crossOrigin = 'Anonymous';
    video.src = videoSrc;
    video.oncanplaythrough = () => {
      resolve(video);
      document.body.removeChild(video);
    };
    video.loop = false;
    video.autoplay = true;
    video.controls = true;
    video.crossOrigin = 'anonymous';
    video.style.position = 'absolute';
    video.style.width = '1px';
    video.style.height = '1px';
    video.style.left = '0px';
    video.style.bottom = '0px';
    video.style.opacity = '0.001';
    document.body.appendChild(video);
    video.tabIndex = -1;
  });

export const convertToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject('Failed convert file to base64 string!');
      }
    };
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(blob);
  });

const WATCH_SHAPE_VIDEO_INTERVAL = 500;
function watchVideoLoaded(videoElement: HTMLVideoElement, onLoad: () => void) {
  const intervalId = setInterval(() => {
    if (videoElement.readyState >= 3) {
      clearInterval(intervalId);
      onLoad();
    }
  }, WATCH_SHAPE_VIDEO_INTERVAL);
}

async function waitForShapeVideoLoad(shapeSettings: ViewerSetupSettings) {
  await new Promise<void>((res) => {
    const allVideosOnPage = [...document.getElementsByTagName('video')];
    const shapeVideo = allVideosOnPage.find(
      (x) =>
        x.src === encodeURI(shapeSettings.mp4Url) ||
        x.src === encodeURI(shapeSettings.webMUrl)
    );
    if (shapeVideo) {
      logShapeVideoLoad('Waiting for video with src', shapeVideo.src);
      watchVideoLoaded(shapeVideo, res);
    } else {
      logShapeVideoLoad(`Didn't find video element for viewer`);
      res();
    }
  });
}

export const getViewer = async (
  params: ViewerSetupSettings,
  container?: HTMLDivElement
): Promise<[Viewer, Shape360Api]> => {
  const rootElement = document.getElementById('container-for-viewer-instances');
  if (!rootElement) {
    throw new Error('Could not getElementById=root');
  }

  const viewer = new Viewer({ container: container || rootElement });
  const shapeSettings = params;
  const isScene = !!shapeSettings.sceneConfig;
  const isShape360 = !!(
    shapeSettings.mp4Url &&
    shapeSettings.webMUrl &&
    shapeSettings.shape360Config
  );

  if (isShape360) {
    await viewer.loadShape360({
      videoUrl: {
        mp4: shapeSettings.mp4Url,
        webM: shapeSettings.webMUrl,
      },
      renderingConfig: shapeSettings.shape360Config,
      uvMappingUrls: shapeSettings.uvMappingUrls
        ? Object.values(shapeSettings.uvMappingUrls)
        : undefined,
    });
    await waitForShapeVideoLoad(shapeSettings);
  } else if (isScene) {
    await viewer.loadPrerenderedScene({
      imageUrl: shapeSettings.sceneConfig.imageUrl,
      uvMappingUrl: shapeSettings.sceneConfig.uvMappingUrl,
      renderingConfig: {
        height: shapeSettings.sceneConfig.height,
        width: shapeSettings.sceneConfig.width,
        engine: shapeSettings.sceneConfig.engine,
        renderScale: shapeSettings.sceneConfig.renderScale,
        isBackgroundConfigurable:
          shapeSettings.sceneConfig.isBackgroundConfigurable,
        main: shapeSettings.sceneConfig.main,
        layout: shapeSettings.sceneConfig.layout,
        scene: shapeSettings.sceneConfig.scene,
      },
    });
  } else {
    throw new Error('Unknown config!');
  }

  const shape = isShape360
    ? viewer.getShape360()
    : viewer.getPrerenderedScene();

  return [viewer, shape];
};

export type ShapeSettings = Partial<{
  previewHeight: number;
  includeBackground: boolean;
  scale: number;
  reflection: number;
  shadow: number;
  backgroundImageUrl: string | null;
  backgroundColor: string;
  artworkUrl: string;
  angle: {
    horizontal: number;
    vertical: number;
  };
  asset: ShapeAsset;
  position: {
    x: number;
    y: number;
  };
  rotation: number;
}>;

export type ConfigurableShapeSettings = Partial<{
  previewHeight: number;
  includeBackground: boolean;
  backgroundImageUrl: string | null;
  artworkUrl: string | null;
  backgroundColor: string | null;
  scale: number;
  reflection: number;
  shadow: number;
  rotation: number;
  position: {
    x: number;
    y: number;
  };
  angle: {
    horizontal: number;
    vertical: number;
  };
}>;

export type ViewerSetupSettings = {
  shape360Config: unknown;
  mp4Url: string;
  webMUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uvMappingUrls: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export async function setViewerSettings(
  viewer: Viewer,
  shape: Shape360Api,
  asset: ShapeAsset,
  shapeSettings: ConfigurableShapeSettings
) {
  const background = shapeSettings.backgroundImageUrl
    ? await loadImage(shapeSettings.backgroundImageUrl)
    : undefined;

  const artwork = shapeSettings.artworkUrl
    ? await loadImage(shapeSettings.artworkUrl)
    : undefined;

  if (shapeSettings.backgroundColor) {
    viewer.background.setColor(
      Viewer.RgbColor.fromHex(shapeSettings.backgroundColor)
    );
  }
  if (background) {
    viewer.background.setImage(background);
  }

  if (artwork) {
    shape.setArtwork({
      artwork,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enableArtworkRefraction: (asset?.shape360Config as any)
        ?.enableArtworkRefraction,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useAlbedoAsRefractionColor: (asset?.shape360Config as any)
        ?.useAlbedoAsRefractionColor,
    });
  }

  if (shapeSettings.scale !== undefined) {
    shape.setScale(shapeSettings.scale);
  }
  if (shapeSettings.reflection !== undefined) {
    shape.setReflection(shapeSettings.reflection);
  }
  if (shapeSettings.shadow !== undefined) {
    shape.setShadow(shapeSettings.shadow);
  }
  if (shapeSettings.rotation !== undefined) {
    shape.setRotation(shapeSettings.rotation);
  }
  if (shapeSettings.position) {
    shape.setPosition({
      x: shapeSettings.position.x,
      y: shapeSettings.position.y,
    });
  }
  if (shapeSettings.angle) {
    await shape.setViewingAngle({
      horizontal: shapeSettings.angle.horizontal,
      vertical: shapeSettings.angle.vertical,
    });
  }
}

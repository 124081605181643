import { ActionType, AnyAction, createAction, getType } from 'deox';

import {
  createMultiRemoteDataActions,
  createRemoteDataActions,
} from 'shared/remoteData';

import * as D from '@domain/template';
import { RawOrder } from '@domain/order';
import { TemplateGroup } from '@domain/template';
import { TemplateInfo } from 'aspects/api/modules/Templates/types';
import {
  InsertionPointLevelChanges,
  InteractionMode,
  LayerSetChangeDirection,
  TabName,
  UpdateObjectChanges,
} from './types';

export const loadOrder = createAction(
  'LINEUP_PRODUCTS:LOAD_ORDER',
  (resolve) => (payload: { orderId: string }) => resolve(payload)
);

export const loadOrderActions = createRemoteDataActions<RawOrder>(
  'LINEUP_PRODUCTS:LOAD_ORDER_REQUEST',
  'LINEUP_PRODUCTS:LOAD_ORDER_SUCCESS',
  'LINEUP_PRODUCTS:LOAD_ORDER_FAILED'
);

export const loadTemplatesGroups = createAction(
  'LINEUP_PRODUCTS:LOAD_TEMPLATES_GROUPS'
);

export const loadTemplatesGroupsActions = createRemoteDataActions<
  TemplateGroup[]
>(
  'LINEUP_PRODUCTS:LOAD_TEMPLATES_GROUPS_REQUEST',
  'LINEUP_PRODUCTS:LOAD_TEMPLATES_GROUPS_SUCCESS',
  'LINEUP_PRODUCTS:LOAD_TEMPLATES_GROUPS_FAILED'
);

export const openTab = createAction(
  'LINEUP_PRODUCTS:OPEN_TAB',
  (resolve) => (payload: { tabName: TabName }) => resolve(payload)
);

export const setMovementBoundariesVisibility = createAction(
  'LINEUP_PRODUCTS:SET_MOVEMENT_BOUNDARIES_VISIBILITY',
  (resolve) => (payload: boolean) => resolve(payload)
);

export const selectTemplateGroup = createAction(
  'LINEUP_PRODUCTS:SELECT_TEMPLATE_GROUP',
  (resolve) => (payload: { groupId: string }) => resolve(payload)
);

export const selectTemplate = createAction(
  'LINEUP_PRODUCTS:SELECT_TEMPLATE',
  (resolve) => (payload: { templateId: string; isBriefing: boolean }) =>
    resolve(payload)
);

export const openTemplate = createAction(
  'LINEUP_PRODUCTS:OPEN_TEMPLATE',
  (resolve) => (payload: { templateId: string }) => resolve(payload)
);

export const saveTemplateChanges = createAction(
  'LINEUP_PRODUCTS:SAVE_TEMPLATE_CHANGES',
  (resolve) => (payload: { templateId: string }) => resolve(payload)
);

export const saveTemplateChangesActions = createMultiRemoteDataActions(
  'LINEUP_PRODUCTS:SAVE_TEMPLATE_CHANGES_REQUEST',
  'LINEUP_PRODUCTS:SAVE_TEMPLATE_CHANGES_SUCCESS',
  'LINEUP_PRODUCTS:SAVE_TEMPLATE_CHANGES_FAILED',
  'LINEUP_PRODUCTS:SAVE_TEMPLATE_CHANGES_RESET'
);

export const applyBackgroundInfluenceChanges = createMultiRemoteDataActions(
  'LINEUP_PRODUCTS:APPLY_BACKGROUND_INFLUENCE_CHANGES_REQUEST',
  'LINEUP_PRODUCTS:APPLY_BACKGROUND_INFLUENCE_CHANGES_SUCCESS',
  'LINEUP_PRODUCTS:APPLY_BACKGROUND_INFLUENCE_CHANGES_FAILED',
  'LINEUP_PRODUCTS:APPLY_BACKGROUND_INFLUENCE_CHANGES_RESET'
);

export const duplicateObject = createAction(
  'LINEUP_PRODUCTS:DUPLICATE_OBJECT',
  (resolve) => (payload: { objectId: string; projectId: string }) =>
    resolve(payload)
);

// rename to duplicate/rearrangeProduct(s)
export const rearrangeObjects = createAction(
  'LINEUP_PRODUCTS:REARRANGE_OBJECTS',
  (resolve) => (payload: {
    oldIndex: number;
    newIndex: number;
    projectId: string;
  }) => resolve(payload)
);

export const sceneDimensionsReady = createAction(
  'LINEUP_PRODUCTS:SCENE_DIMENSIONS_READY',
  (resolve) => (payload: {
    availableWidth: number;
    availableHeight: number;
    projectId: string;
  }) => resolve(payload)
);

export const sceneForLayerSetReady = createAction(
  'LINEUP_PRODUCTS:SCENE_FOR_LAYER_SET_READY',
  (resolve) => (payload: { projectId: string; layerSetId: string }) =>
    resolve(payload)
);

export const downscaleObjects = createAction(
  'LINEUP_PRODUCTS:DOWNSCALE_OBJECTS',
  (resolve) => (payload: {
    availableWidth: number;
    availableHeight: number;
    projectId: string;
  }) => resolve(payload)
);

// for use in views
export const updateObject = createAction(
  'LINEUP_PRODUCTS:UPDATE_OBJECT',
  (resolve) => (
    payload: { objectId: string; projectId: string } & UpdateObjectChanges
  ) => resolve(payload)
);

// for use in sagas
export const applyObjectChanges = createAction(
  'LINEUP_PRODUCTS:APPLY_OBJECT_CHANGES',
  (resolve) => (
    payload: { objectId: string; projectId: string } & UpdateObjectChanges
  ) => resolve(payload)
);

export const toggleSceneLoader = createAction(
  'LINEUP_PRODUCTS:TOGGLE_SCENE_LOADER',
  (resolve) => (payload: {
    value: boolean;
    message?: string;
    projectId: string;
  }) => resolve(payload)
);

export const changeLayerSet = createAction(
  'LINEUP_PRODUCTS:CHANGE_LAYER_SET',
  (resolve) => (payload: {
    direction: LayerSetChangeDirection;
    projectId: string;
  }) => resolve(payload)
);

export const changeProductsAmountInScene = createAction(
  'LINEUP_PRODUCTS:CHANGE_PRODUCTS_AMOUNT_IN_SCENE',
  (resolve) => (payload: {
    amount: number;
    projectId: string;
    layerSetId: string;
  }) => resolve(payload)
);

export const openBriefingCreator = createAction(
  'LINEUP_PRODUCTS:OPEN_BRIEFING_CREATOR'
);

export const addBriefing = createAction(
  'LINEUP_PRODUCTS:ADD_BRIEFING',
  (resolve) => (payload: { template: TemplateInfo }) => resolve(payload)
);

export const changePixelsInOneRelativeUnit = createAction(
  'LINEUP_PRODUCTS:CHANGE_PIXELS_IN_ONE_RELATIVE_UNIT',
  (resolve) => (payload: { value: number; projectId: string }) =>
    resolve(payload)
);

export const selectObject = createAction(
  'LINEUP_PRODUCTS:SELECT_OBJECT',
  (resolve) => (payload: { objectId: string; projectId: string }) =>
    resolve(payload)
);

export const requestPreviewImage = createAction(
  'LINEUP_PRODUCTS:REQUEST_PREVIEW_IMAGE',
  (resolve) => (payload: { projectId: string; extensionId: string | null }) =>
    resolve(payload)
);

export const requestFinalOrder = createAction(
  'LINEUP_PRODUCTS:REQUEST_FINAL_ORDER',
  (resolve) => (payload: { projectId: string; extensionId: string | null }) =>
    resolve(payload)
);

export const shapesBoundingBoxDetailsReady = createAction(
  'LINEUP_PRODUCTS:SHAPES_BOUNDING_BOX_DETAILS_READY',
  (resolve) => (
    boundingBoxDetails: Record<string, D.ShapeBoundingBoxDetails>
  ) => resolve(boundingBoxDetails)
);

export const viewerInitialized = createAction(
  'LINEUP_PRODUCTS:VIEWER_INITIALIZED',
  (resolve) => (payload: { objectId: string; layerSetId: string }) =>
    resolve(payload)
);

export const isChangePixelsInOneRelativeUnitAction = (
  x: AnyAction
): x is ActionType<typeof changePixelsInOneRelativeUnit> =>
  x.type === getType(changePixelsInOneRelativeUnit);

export const interactionModeSelected = createAction(
  'LINEUP_PRODUCTS:INTERACTION_MODE_SELECTED',
  (resolve) => (payload: {
    objectId: string;
    layerSetId: string;
    interactionMode: InteractionMode;
  }) => resolve(payload)
);

export const containersForProductsAreReady = createAction(
  'LINEUP_PRODUCTS:CONTAINERS_FOR_PRODUCTS_ARE_READY'
);

export const projectIsSetUp = createAction('LINEUP_PRODUCTS:PROJECT_IS_SET_UP');

export const producePNGPreviewActions = createRemoteDataActions(
  'LINEUP_PRODUCTS:PRODUCE_PNG_PREVIEW_REQUEST',
  'LINEUP_PRODUCTS:PRODUCE_PNG_PREVIEW_SUCCESS',
  'LINEUP_PRODUCTS:PRODUCE_PNG_PREVIEW_FAILED',
  'LINEUP_PRODUCTS:PRODUCE_PNG_PREVIEW_RESET'
);

export const addLayerSet = createAction(
  'LINEUP_PRODUCTS:ADD_LAYER_SET',
  (resolve) => (payload: { projectId: string }) => resolve(payload)
);

export const removeLayerSet = createAction(
  'LINEUP_PRODUCTS:REMOVE_LAYER_SET',
  (resolve) => (payload: { projectId: string; layerSetId: string }) =>
    resolve(payload)
);

export const changeInsertionPointLevelProperties = createAction(
  'LINEUP_PRODUCTS:CHANGE_INSERTION_POINT_LEVEL_PROPERTIES',
  (resolve) => (payload: {
    projectId: string;
    layerSetId: string;
    objectId: string;
    changes: InsertionPointLevelChanges;
  }) => resolve(payload)
);

export const gripRequestsPreview = createAction(
  'LINEUP_PRODUCTS:GRIP_REQUESTS_PREVIEW',
  (resolve) => (payload: { port: MessagePort }) => resolve(payload)
);

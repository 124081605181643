/* eslint-disable no-restricted-syntax */
import { ActionType, getType } from 'deox';
import { put, select, take, fork, call, all } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { batchActions } from 'redux-batched-actions';
import createDebug from 'debug';

import { isSuccess } from 'shared/remoteData';
import { SagaDependencies } from 'store/types';
import { ProductLayerObject, pickProductLayerObjects } from '@domain/template';
import { extractAllShapeAssets, RawOrder } from '@domain/order';

import { selectProjectObjectWithChanges } from '../reducerHelpers';
import {
  updateViewerSettings,
  changeShapeInteractionMode,
  enableInteractionModeOnShape,
  makeObjectContainerId,
  removeAllViewers,
  removeViewer,
} from '../../Editor/viewersGlobalMap';
import { getSceneDimensionsForRender } from '../../Editor/viewerRendering/getSceneDimensionsForRender';
import { insertObjectPositionRelatedSettings } from '../../Editor/viewerRendering/renderObjectOnBackground';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { setupViewerWorker, watchViewerUpdates } from './setupViewerWorker';
import { watchTabChanges } from './watchers/watchTabChanges';
import { takeChannelAfterAction } from './watchers/takeChannelAfterAction';

const debugSetupProject = createDebug(`[worker:${setupProject.name}]`);
const debugWatchObjectsRearrangement = createDebug(
  `[worker:${rearrangeObjectsWorker.name}]`
);

type ViewerWorkerDescriptor = { objectId: string; layerSetId: string };

export type WorkersMap = Map<ViewerWorkerDescriptor, Task>;

export function* setupProject(
  deps: SagaDependencies,
  action:
    | ActionType<typeof actions.selectTemplate>
    | ActionType<typeof actions.changeLayerSet>
) {
  const projectId: string =
    action.type !== getType(actions.selectTemplate)
      ? yield select(selectors.selectSelectedProjectId)
      : action.payload.templateId;

  debugSetupProject('start', { projectId });

  const workers: WorkersMap = new Map();

  const order: ReturnType<typeof selectors.selectOrder> = yield select(
    selectors.selectOrder
  );

  if (!isSuccess(order)) {
    debugSetupProject('order remote data is not success');
    return;
  }

  yield fork(watchTabChanges, {
    *onTabChanged(params) {
      if (params.tabName === 'templates') {
        yield call(removeAllViewers);
      }
    },
  });

  yield fork(function* downscaleWorker() {
    while (true) {
      const sceneDimensionsReadyAction: ActionType<
        typeof actions.sceneDimensionsReady
      > = yield take(getType(actions.sceneDimensionsReady));

      const areShapesBoundingBoxesSetup: NonNullable<
        ReturnType<typeof selectors.selectAreShapesBoundingBoxesSetup>
      > = yield select(selectors.selectAreShapesBoundingBoxesSetup);
      if (!areShapesBoundingBoxesSetup) {
        yield take(getType(actions.shapesBoundingBoxDetailsReady));
      }

      yield put(actions.downscaleObjects(sceneDimensionsReadyAction.payload));
    }
  });

  yield takeChannelAfterAction({
    waitFor: getType(actions.projectIsSetUp),
    buffer: [
      getType(actions.changeProductsAmountInScene),
      getType(actions.rearrangeObjects),
    ],
    *worker(action) {
      yield rearrangeObjectsWorker(
        {
          deps,
          projectId,
          order: order.value,
          workersMap: workers,
        },
        action as
          | ReturnType<typeof actions.rearrangeObjects>
          | ReturnType<typeof actions.changeProductsAmountInScene>
      );
    },
  });

  try {
    yield put(
      batchActions([
        actions.openTab({
          tabName: 'adjust',
        }),
        actions.toggleSceneLoader({
          value: true,
          message: 'Setting up the scene',
          projectId,
        }),
      ])
    );

    const {
      isDownscaled,
    }: NonNullable<ReturnType<typeof selectors.selectProject>> = yield select(
      selectors.selectProject,
      projectId
    );

    debugSetupProject('wait until scene is ready', { projectId });

    if (!isDownscaled) {
      yield take(getType(actions.downscaleObjects));
    } else {
      yield take(getType(actions.sceneForLayerSetReady));
    }

    const project: NonNullable<
      ReturnType<typeof selectors.selectProject>
    > = yield select(selectors.selectProject, projectId);
    const productLayerObjects = pickProductLayerObjects(project);

    for (const productLayerObject of productLayerObjects) {
      const workerDescriptor = {
        projectId,
        objectId: productLayerObject.id,
        layerSetId: project.selectedLayerSetId,
      };

      const currentObjectToSetup: ProductLayerObject = yield select(
        selectors.selectTemplateObject,
        projectId,
        productLayerObject.id
      );

      /**
       * it happens when viewers are being started but the number of object has been decreased
       * For example click on decrease products amount in the scene during the initialization
       */
      if (currentObjectToSetup) {
        debugSetupProject('viewer worker is forked', { projectId });

        const workerTask: Task = yield fork(
          setupViewerWorker,
          deps,
          workerDescriptor,
          productLayerObject,
          order.value
        );

        workers.set(workerDescriptor, workerTask);

        // due to problems that arise when you try to set up multiple viewers in parallel we must set up them one by one
        debugSetupProject('wait until viewer is initialized', { projectId });
        yield take(getType(actions.viewerInitialized));
        debugSetupProject('viewer has been initialized', { projectId });
      } else {
        debugSetupProject('skip forking viewer', { projectId });
      }
    }

    yield put(actions.toggleSceneLoader({ value: false, projectId }));

    yield fork(watchInteractionModeChange);
    yield fork(watchSelectedObjectChange);
    yield fork(watchViewerUpdates, { order: order.value, projectId });

    yield put(actions.projectIsSetUp());
  } catch (e) {
    debugSetupProject('error', { projectId, e });
    window.console.error('Error ocurred in setupProject', e.message);
  }
}

export function* rearrangeObjectsWorker(
  {
    deps,
    projectId,
    workersMap,
    order,
  }: {
    deps: SagaDependencies;
    projectId: string;
    workersMap: WorkersMap;
    order: RawOrder;
  },
  action:
    | ActionType<typeof actions.rearrangeObjects>
    | ActionType<typeof actions.changeProductsAmountInScene>
) {
  try {
    debugWatchObjectsRearrangement('start', { projectId });

    const project: NonNullable<
      ReturnType<typeof selectors.selectProject>
    > = yield select(selectors.selectProject, projectId);
    const productLayerObjects = pickProductLayerObjects(project);

    workersMap.forEach((task, descriptor) => {
      if (!productLayerObjects.some((o) => o.id === descriptor.objectId)) {
        workersMap.delete(descriptor);
        removeViewer(
          makeObjectContainerId(descriptor.layerSetId, descriptor.objectId)
        );
        task.cancel();
      }
    });

    const objectsToSetupViewerFor: ProductLayerObject[] = [];
    const workersDescriptors = [...workersMap.keys()];

    productLayerObjects.forEach((o) => {
      if (!workersDescriptors.some((d) => d.objectId === o.id)) {
        objectsToSetupViewerFor.push(o);
      }
    });

    if (objectsToSetupViewerFor.length) {
      const { selectedLayerSetId: layerSetId } = project;

      yield put(
        actions.toggleSceneLoader({
          value: true,
          message: 'Setting up the shapes',
          projectId,
        })
      );

      for (const objectToSetupViewerFor of objectsToSetupViewerFor) {
        if (action.type === getType(actions.changeProductsAmountInScene)) {
          const viewerContainer = document.getElementById(
            makeObjectContainerId(
              project.selectedLayerSetId,
              objectToSetupViewerFor.id
            )
          );

          if (!viewerContainer) {
            yield take(actions.containersForProductsAreReady);
          }
        }

        const workerDescriptor = {
          projectId,
          objectId: objectToSetupViewerFor.id,
          layerSetId,
        };
        debugWatchObjectsRearrangement('fork viewer');
        const workerTask: Task = yield fork(
          setupViewerWorker,
          deps,
          workerDescriptor,
          objectToSetupViewerFor,
          order
        );
        workersMap.set(workerDescriptor, workerTask);

        debugWatchObjectsRearrangement('wait until viewer is initialized');
        yield take(getType(actions.viewerInitialized));
        debugWatchObjectsRearrangement('viewer is initialized');
      }

      yield put(
        actions.toggleSceneLoader({
          value: false,
          projectId,
        })
      );
    }

    const shapesBoundingBoxes: NonNullable<
      ReturnType<typeof selectors.selectShapesBoundingBoxes>
    > = yield select(selectors.selectShapesBoundingBoxes);

    const { orderShapes } = project;
    const sceneDimensions = getSceneDimensionsForRender(project);
    const shapeAssets = extractAllShapeAssets(order);

    yield all(
      [...workersMap.keys()].map(({ objectId }) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const templateObject = productLayerObjects.find(
          (x) => x.id === objectId
        )!;
        const asset = shapeAssets.find(
          (x) => x.assetId === templateObject.assetId
        )!;
        const shape = orderShapes.find((x) => x.assetId === asset.assetId)!;
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        const [, objectChanges] = selectProjectObjectWithChanges(
          project,
          objectId
        );

        const { position, scale } = insertObjectPositionRelatedSettings(
          templateObject.insertionPoint,
          objectChanges.insertionPointOffset,
          shape.physicalHeight,
          shapesBoundingBoxes[asset.assetId],
          sceneDimensions,
          {}
        );
        return call(
          updateViewerSettings,
          objectId,
          project.selectedLayerSetId,
          {
            position,
            scale,
          }
        );
      })
    );
  } finally {
    yield put(
      actions.toggleSceneLoader({
        value: false,
        projectId,
      })
    );
  }
}

export function* watchInteractionModeChange() {
  while (true) {
    const {
      payload: { objectId, layerSetId, interactionMode },
    }: ActionType<typeof actions.interactionModeSelected> = yield take(
      actions.interactionModeSelected
    );

    changeShapeInteractionMode(objectId, layerSetId, interactionMode);
  }
}

export function* watchSelectedObjectChange() {
  while (true) {
    const {
      payload: { objectId, projectId },
    }: ActionType<typeof actions.selectObject> = yield take(
      actions.selectObject
    );

    const lastInteractionMode: ReturnType<
      typeof selectors.selectInteractionMode
    > = yield select(selectors.selectInteractionMode);

    if (lastInteractionMode !== 'none') {
      const project: NonNullable<
        ReturnType<typeof selectors.selectProject>
      > = yield select(selectors.selectProject, projectId);

      enableInteractionModeOnShape(
        objectId,
        project.selectedLayerSetId,
        lastInteractionMode
      );
    }
  }
}

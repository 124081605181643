import { exhaustiveCheck } from 'utils/exhaustiveCheck';
import * as D from '@domain/template';
import { TemplateInfo } from 'aspects/api/modules/Templates/types';

export function convertAdminTemplateToTemplate(
  templateInfo: TemplateInfo,
  selectedAdminLayerSet: D.ATLayerSet
): D.Template {
  function convertATLayerObjectToLayerObject(
    x: D.ATLayerObject
  ): D.LayerObject {
    switch (x.type) {
      case 'product': {
        return {
          id: x.id,
          layerId: x.layerId,
          type: 'product',
          assetId: null,
          artworkId: null,
          imgUrl: null,
          movementBoundaries: convertMovementBoundaries(x.movementBoundaries),
          influencedByLayers: x.influencedByLayers,
          insertionPoint: x.insertionPoint,
        } as D.ProductLayerObject;
      }
      case 'image':
      case 'background': {
        return x;
      }
      default: {
        return exhaustiveCheck(x);
      }
    }
  }

  function convertATLayerToTemplateLayer(x: D.ATLayer): D.Layer {
    return { ...x, objects: x.objects.map(convertATLayerObjectToLayerObject) };
  }

  const { template: adminTemplate } = templateInfo;
  return {
    id: templateInfo.id,
    thumbnail: adminTemplate.thumbnail,
    type: adminTemplate.type,
    pixelsInOneRelativeUnit: adminTemplate.pixelsInOneRelativeUnit,
    resolution: adminTemplate.resolution,
    imgKeyToUrlMap: adminTemplate.imgKeyToUrlMap,
    layers: selectedAdminLayerSet.layers.map(convertATLayerToTemplateLayer),
  };
}

function convertMovementBoundaries(
  boundaries: D.MovementBoundaries
): D.MovementBoundaries {
  const [left, top, right, bottom] = boundaries;

  if (left <= 0 && top <= 0 && right >= 0 && bottom >= 0) {
    return boundaries;
  }

  window.console.error('Invalid movement boundaries', boundaries);

  return [0, 0, 0, 0];
}

import { useCallback } from 'react';

import { Button } from 'shared/components/Button/Button';
import * as D from '@domain/template';

import { AdminContentOnly } from 'aspects/roles';
import rightPanelCss from './RightPanel.module.scss';
import css from './ProductsArrangementControls.module.scss';
import { OnProductSelectValue, ProductsList } from './ProductsList';
import { LimitedInput, LimitedInputConfig } from './components/LimitedInput';
import { AdjustSectionProps } from './AdjustSection';

type LayerSetControlsContainerProps = {
  values: LimitedInputConfig;
  projectId: string;
  changeLayerSet: AdjustSectionProps['changeLayerSet'];
};

export function LayerSetControlsContainer(
  props: LayerSetControlsContainerProps
) {
  const { values, projectId, changeLayerSet } = props;

  const handleLayerSetAmount = useCallback(
    (amount: number) => {
      const direction = amount < values.value ? 'back' : 'forward';
      changeLayerSet({ direction, projectId });
    },
    [values.value, changeLayerSet, projectId]
  );

  return (
    <LimitedInput
      config={values}
      controlsView="arrow"
      onChange={handleLayerSetAmount}
    />
  );
}

export type ProductsArrangementControlsProps = {
  onSelectedProductChange(id: string): void;
  selectedProductId: string | null;
  currentProject: D.Project;
  layerSetsControlValues: LimitedInputConfig | null;
  productsAmountInSceneControlValues: LimitedInputConfig;
  selectedSceneObjectId: string | null;
  currentSelection: number;
  selectSceneObject: AdjustSectionProps['selectObject'];
  isBriefing: boolean;
} & Pick<
  AdjustSectionProps,
  | 'duplicateObject'
  | 'rearrangeObjects'
  | 'changeProductsAmountInScene'
  | 'changeLayerSet'
  | 'addLayerSet'
  | 'removeLayerSet'
  | 'layerSetChangeDirectionOnRemoval'
  | 'shouldDisableRemoveProductsComposition'
>;

export function ProductsArrangementControls(
  props: ProductsArrangementControlsProps
) {
  const {
    onSelectedProductChange,
    selectSceneObject,
    currentProject,
    selectedProductId,
    duplicateObject,
    rearrangeObjects,
    changeProductsAmountInScene,
    layerSetsControlValues,
    changeLayerSet,
    productsAmountInSceneControlValues,
    selectedSceneObjectId,
    currentSelection,
    addLayerSet,
    removeLayerSet,
    layerSetChangeDirectionOnRemoval,
    shouldDisableRemoveProductsComposition,
    isBriefing,
  } = props;
  const { id: projectId, selectedLayerSetId: layerSetId } = currentProject;

  const handleProductSelect = useCallback(
    ({ productId, isOutOfSelection }: OnProductSelectValue) => {
      onSelectedProductChange(productId);
      if (!isOutOfSelection) {
        selectSceneObject({ objectId: productId, projectId });
      }
    },
    [onSelectedProductChange, selectSceneObject, projectId]
  );

  const handleDuplicateProduct = useCallback(() => {
    if (selectedProductId !== null) {
      duplicateObject({ objectId: selectedProductId, projectId });
    }
  }, [selectedProductId, duplicateObject, projectId]);

  const handleProductsSort = useCallback(
    (oldIndex: number, newIndex: number) => {
      rearrangeObjects({ oldIndex, newIndex, projectId });
    },
    [rearrangeObjects, projectId]
  );

  const handleProductsAmountInSceneChange = useCallback(
    (amount: number) => {
      changeProductsAmountInScene({
        amount,
        projectId,
        layerSetId,
      });
    },
    [changeProductsAmountInScene, projectId, layerSetId]
  );

  const handleLayerSetAdd = useCallback(() => {
    addLayerSet({ projectId });
    changeLayerSet({ projectId, direction: 'forward' });
  }, [projectId, addLayerSet, changeLayerSet]);

  const handleLayerSetRemove = useCallback(() => {
    if (layerSetChangeDirectionOnRemoval) {
      changeLayerSet({
        projectId,
        direction: layerSetChangeDirectionOnRemoval,
      });
    }
    removeLayerSet({
      projectId,
      layerSetId: currentProject.selectedLayerSetId,
    });
  }, [
    projectId,
    layerSetChangeDirectionOnRemoval,
    currentProject.selectedLayerSetId,
    removeLayerSet,
    changeLayerSet,
  ]);

  return (
    <>
      <AdminContentOnly>
        {currentProject.template.type === 'custom' && (
          <div className={css.control}>
            <h6 className={css.controlTitle}>
              Add/remove products composition
            </h6>
            <div className={css.layerSetsActions}>
              <Button
                classes={{ root: css.layerSetAction }}
                onClick={handleLayerSetAdd}
              >
                Add
              </Button>
              <Button
                classes={{ root: css.layerSetAction }}
                onClick={handleLayerSetRemove}
                disabled={shouldDisableRemoveProductsComposition}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
      </AdminContentOnly>
      {layerSetsControlValues && (
        <div className={css.control}>
          <h6 className={css.controlTitle}>Change products composition</h6>
          <LayerSetControlsContainer
            values={layerSetsControlValues}
            projectId={currentProject.id}
            changeLayerSet={changeLayerSet}
          />
        </div>
      )}
      {isBriefing && productsAmountInSceneControlValues && (
        <div className={css.control}>
          <h6 className={css.controlTitle}>
            Change products amount in the scene
          </h6>
          <LimitedInput
            config={productsAmountInSceneControlValues}
            controlsView="plus-minus"
            onChange={handleProductsAmountInSceneChange}
          />
        </div>
      )}
      <div className={rightPanelCss.productsList}>
        <ProductsList
          items={currentProject.objects}
          lockAxis="y"
          lockToContainerEdges
          useDragHandle
          currentSelectionCount={currentSelection}
          selectedSceneObjectId={selectedSceneObjectId}
          selectedProductId={selectedProductId}
          onProductSelect={handleProductSelect}
          onProductDuplicate={handleDuplicateProduct}
          onSortEnd={(payload) => {
            const { oldIndex, newIndex } = payload;
            handleProductsSort(oldIndex, newIndex);
          }}
        />
      </div>
    </>
  );
}

import { connect } from 'react-redux';
import 'rc-slider/assets/index.css';

import 'shared/components/Button/Button.module.scss';
import 'shared/components/Loader/Loader.module.scss';
import 'shared/components/Slider/Slider.module.scss';
import 'shared/components/Checkbox/Checkbox.module.scss';
import { AppState } from 'store/types';
import { lineupProductsSelectors } from 'features/lineupProducts';
import { ChooseBackground } from 'features/briefTemplate';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';

import css from './Editor.module.scss';
import { RightPanel } from './components/RightPanel/RightPanel';
import { TemplatesList } from './components/TemplatesList/TemplatesList';
import { Scene } from './components/Scene/Scene';

type EditorProps = ReturnType<typeof mapState> &
  typeof actionsMap & { groupId?: string; templateId: string | null };

function EditorView(props: EditorProps) {
  const {
    currentProject,
    isBriefTemplateOpened,
    activeTabName,
    groupId,
    openingContext,
    templateId,
  } = props;

  return (
    <div className={css.editor}>
      <div className={css.content}>
        {activeTabName === 'templates' &&
          (isBriefTemplateOpened ? (
            <ChooseBackground groupId={groupId} />
          ) : (
            <TemplatesList />
          ))}
        {activeTabName !== 'templates' && currentProject && (
          <Scene project={currentProject} bounds={`.${css.content}`} />
        )}
      </div>
      <RightPanel classes={{ root: css.controlPanel }} />
      {openingContext === 'opened-from-lineup-admin' && (
        <a
          className={css.goBackLink}
          href={`/admin/templates/${templateId ?? ''}`}
        >
          <ArrowIcon className={css.goBackIcon} />
        </a>
      )}
    </div>
  );
}

function mapState(state: AppState) {
  const projectId = lineupProductsSelectors.selectSelectedTemplateId(state);
  return {
    activeTabName: lineupProductsSelectors.selectActiveTabName(state),
    isBriefTemplateOpened: lineupProductsSelectors.selectIsBriefTemplateOpened(
      state
    ),
    currentProject: projectId
      ? lineupProductsSelectors.selectProject(state, projectId)
      : undefined,
    openingContext: lineupProductsSelectors.selectOpeningContext(state),
  };
}

const actionsMap = {};

export const Editor = connect(mapState, actionsMap)(EditorView);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { runSequentialAsync } from 'utils/runSequentialAsync';
import {
  extractAllArtworkAssets,
  extractAllShapeAssets,
  RawOrder,
} from '@domain/order';
import {
  Project,
  ProductLayerObject,
  isProductLayerObject,
  Layer,
  LayerObject,
  selectImageUrl,
  Template,
  BackgroundLayerObject,
  isBackgroundLayerObject,
  ShapeBoundingBoxDetails,
} from '@domain/template';
import {
  selectProjectObjectsWithChanges,
  selectProjectObjectWithChanges,
} from '../../redux/reducerHelpers';
import { asyncDetermineImageDimensions } from './asyncDetermineImageDimensions';
import { getRectDimensionsInUnits } from './getRectDimensionsInUnits';
import { renderObjectOnBackground } from './renderObjectOnBackground';
import { getBaseCanvas } from './getBaseCanvas';
import { applyBackgroundProperties } from './applyBackgroundProperties';

export async function prepareBackgroundForShape(
  object: ProductLayerObject,
  currentProject: Project,
  order: RawOrder,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  imageDimensions: ImageDimensions,
  forceBackgroundInfluenceOff?: boolean
): Promise<string> {
  const {
    sceneDimensions: { width: sceneWidth, height: sceneHeight },
  } = currentProject;
  const baseBackgroundUrl = getBaseCanvas(sceneWidth, sceneHeight).toDataURL();

  const [, objectChanges] = selectProjectObjectsWithChanges(
    currentProject
  ).find(([x]) => x.id === object.id)!;

  const backgroundInfluenceTurnedOff = !objectChanges.hasBackgroundInfluence;
  if (
    forceBackgroundInfluenceOff ??
    (object.influencedByLayers.length === 0 || backgroundInfluenceTurnedOff)
  ) {
    return baseBackgroundUrl;
  }

  const influencedByLayers = currentProject.template.layers.filter((x) =>
    object.influencedByLayers.includes(x.id)
  );

  const workToPerform = influencedByLayers.reduce(
    (acc: ((background: string) => Promise<string>)[], layer: Layer) => {
      const unitOfWork = async (background: string) => {
        const newBackground = await renderLayer(
          background,
          layer.objects,
          currentProject,
          order,
          shapesBoundingBoxes,
          imageDimensions
        );

        return newBackground;
      };
      return [...acc, unitOfWork];
    },
    []
  );

  return runSequentialAsync(baseBackgroundUrl, ...workToPerform);
}

type ImageDimensions = { width: number; height: number };

export async function renderPNGPreview(
  project: Project,
  order: RawOrder,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  imageDimensions: ImageDimensions
) {
  const { width: imageWidth, height: imageHeight } = imageDimensions;
  const baseBackgroundUrl = getBaseCanvas(imageWidth, imageHeight).toDataURL();

  const workToPerform = project.template.layers.reduce(
    (acc: ((background: string) => Promise<string>)[], layer: Layer) => {
      const unitOfWork = async (background: string) => {
        const newBackground = await renderLayer(
          background,
          layer.objects,
          project,
          order,
          shapesBoundingBoxes,
          imageDimensions
        );

        // downloadImage(
        //   newBackground,
        //   `newBackgroundLayer-${new Date().toISOString()}.png`
        // );

        return newBackground;
      };
      return [...acc, unitOfWork];
    },
    []
  );

  return runSequentialAsync(baseBackgroundUrl, ...workToPerform);
}

export async function renderLayer(
  backgroundUrl: string,
  objects: LayerObject[],
  project: Project,
  order: RawOrder,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  imageDimensions: ImageDimensions
): Promise<string> {
  if (objects.length === 0) {
    return backgroundUrl;
  }

  const objectToRender = objects[0];

  const newBackgroundUrl = await renderObject(
    backgroundUrl,
    objectToRender,
    project,
    order,
    shapesBoundingBoxes,
    imageDimensions
  );

  return renderLayer(
    newBackgroundUrl,
    objects.slice(1),
    project,
    order,
    shapesBoundingBoxes,
    imageDimensions
  );
}

async function renderObject(
  background: string,
  object: LayerObject,
  project: Project,
  order: RawOrder,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  imageDimensions: ImageDimensions
): Promise<string> {
  const { template, orderShapes } = project;
  const { width, height } = imageDimensions;

  switch (object.type) {
    case 'background': {
      const [backgroundImage] = await getBackgroundBase64Image(
        template,
        imageDimensions,
        object
      );
      return backgroundImage;
    }
    case 'image': {
      const {
        coords: [x, y],
        imgKey,
        resolution: [width, height],
      } = object;
      const imageUrl = selectImageUrl(template, imgKey);
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d')!;
      const { imageElement } = await asyncDetermineImageDimensions(imageUrl);
      const {
        imageElement: backgroundImageElement,
      } = await asyncDetermineImageDimensions(background);
      ctx.drawImage(backgroundImageElement, 0, 0);
      ctx.drawImage(
        imageElement,
        0,
        0,
        imageElement.width,
        imageElement.height, // source rectangle
        x,
        y,
        width,
        height
      ); // destination rectangle

      return canvas.toDataURL();
    }
    case 'product': {
      const [widthInUnits, heightInUnits] = getRectDimensionsInUnits(
        width,
        height
      );

      const artworks = extractAllArtworkAssets(order);

      const templateObject = template.layers
        .flatMap((x) => x.objects)
        .find(
          (x) => isProductLayerObject(x) && x.id === object.id
        )! as ProductLayerObject;

      const shapeAsset = extractAllShapeAssets(order).find(
        (x) => x.assetId === object.assetId
      )!;
      const shape = orderShapes.find((x) => x.assetId === shapeAsset.assetId)!;
      const [
        projectObject,
        projectObjectChanges,
      ] = selectProjectObjectWithChanges(project, object.id);
      const artwork = artworks.find(
        (x) => x.artworkUrl === projectObject.artworkId
      );

      const backgroundImageForShape = await prepareBackgroundForShape(
        templateObject,
        project,
        order,
        shapesBoundingBoxes,
        imageDimensions
      );

      const finalShapeBackgroundImage = await applyBackgroundProperties({
        baseBackgroundUrl: backgroundImageForShape,
        baseColor: projectObjectChanges.backgroundBaseColor,
        opacity: projectObjectChanges.backgroundOpacity,
      });

      const heightFactor = height / project.sceneDimensions.height;

      const influencedShapeImage = await renderObjectOnBackground({
        asset: shapeAsset,
        sourceDimensions: {
          width: project.sceneDimensions.width,
          height: project.sceneDimensions.height,
        },
        sceneDimensions: {
          sceneWidth: width,
          sceneHeight: height,
          sceneWidthUnits: widthInUnits,
          sceneHeightUnits: heightInUnits,
        },
        objectHeight: shape.physicalHeight * heightFactor,
        shapeBoundingBoxDetails: shapesBoundingBoxes[shapeAsset.assetId],
        renderConfig: {
          artworkUrl: artwork?.artworkUrl,
          includeBackground: false,
          backgroundImageUrl: finalShapeBackgroundImage,
          reflection: projectObjectChanges.reflection,
          shadow: projectObjectChanges.shadow,
          angle: projectObjectChanges.angle,
        },
        insertionPointOffset: projectObjectChanges.insertionPointOffset,
        object: templateObject,
      });

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d')!;
      const {
        imageElement: backgroundImageElement,
      } = await asyncDetermineImageDimensions(background);
      const {
        imageElement: influencedShapeImageElement,
      } = await asyncDetermineImageDimensions(influencedShapeImage);
      ctx.drawImage(backgroundImageElement, 0, 0);
      ctx.drawImage(influencedShapeImageElement, 0, 0);
      return canvas.toDataURL();
    }
    default:
      return background;
  }
}

export async function getBackgroundBase64Image(
  template: Template,
  { width, height }: ImageDimensions,
  bgObject?: BackgroundLayerObject
): Promise<[base64Image: string, originalUrl: string]> {
  const object =
    bgObject ||
    template.layers.flatMap((x) => x.objects).find(isBackgroundLayerObject)!;
  const backgroundUrl = selectImageUrl(template, object.imgKey);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  const ctx = canvas.getContext('2d')!;
  const {
    imageElement: backgroundImageElement,
  } = await asyncDetermineImageDimensions(backgroundUrl);
  ctx.drawImage(
    backgroundImageElement,
    0,
    0,
    backgroundImageElement.width,
    backgroundImageElement.height, // source rectangle
    0,
    0,
    canvas.width,
    canvas.height
  ); // destination rectangle

  return [canvas.toDataURL(), backgroundUrl];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import { ActionType, getType } from 'deox';
import { makeUrl } from 'environment';
import { put, select, take, cancelled, call, all } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import createDebug from 'debug';

import { ReturnPromisedType } from 'shared/utilTypes';
import { SagaDependencies } from 'store/types';
import { ProductLayerObject, pickProductLayerObjects } from '@domain/template';
import {
  extractAllArtworkAssets,
  extractAllShapeAssets,
  RawOrder,
} from '@domain/order';
import { briefTemplateSelectors } from 'features/briefTemplate';

import { applyBackgroundProperties } from '../../Editor/viewerRendering/applyBackgroundProperties';
import { selectProjectObjectWithChanges } from '../reducerHelpers';
import {
  makeObjectContainerId,
  removeViewer,
  setupViewer,
  updateViewerSettings,
} from '../../Editor/viewersGlobalMap';
import { getSceneDimensionsForRender } from '../../Editor/viewerRendering/getSceneDimensionsForRender';
import { prepareBackgroundForShape } from '../../Editor/viewerRendering/prepareBackgroundForShape';
import {
  insertObjectPositionRelatedSettings,
  getInsertionPointOffsetFromPosition,
} from '../../Editor/viewerRendering/renderObjectOnBackground';

import * as actions from '../actions';
import * as selectors from '../selectors';

import { UpdateObjectChanges } from '../types';

const debugSetupViewerWorker = createDebug(`worker:${setupViewerWorker.name}`);
const debugWatchViewerUpdates = createDebug(
  `worker:${watchViewerUpdates.name}`
);

export function* setupViewerWorker(
  deps: SagaDependencies,
  descriptor: {
    projectId: string;
    layerSetId: string;
    objectId: string;
  },
  templateObject: ProductLayerObject,
  order: RawOrder
) {
  const { projectId } = descriptor;

  debugSetupViewerWorker('start');

  try {
    const viewerContainerId = makeObjectContainerId(
      descriptor.layerSetId,
      descriptor.objectId
    );
    const viewerContainer = document.getElementById(viewerContainerId);

    if (viewerContainer === null) {
      window.console.error('NO VIEWER CONTAINER FOR OBJECT', { descriptor });
      return;
    }

    yield call(removeViewer, viewerContainerId);

    const project: NonNullable<
      ReturnType<typeof selectors.selectProject>
    > = yield select(selectors.selectProject, projectId);

    const { objects, orderShapes } = project;
    const artworks = extractAllArtworkAssets(order);
    const shapeAssets = extractAllShapeAssets(order);

    const object = objects.find((x) => x.id === templateObject.id)!;
    const asset = shapeAssets.find((x) => x.assetId === object.assetId)!;
    const shape = orderShapes.find((x) => x.assetId === asset.assetId)!;
    const artwork = artworks.find((x) => x.artworkUrl === object.artworkId);

    const shapesBoundingBoxes: NonNullable<
      ReturnType<typeof selectors.selectShapesBoundingBoxes>
    > = yield select(selectors.selectShapesBoundingBoxes);

    const isTemplateBriefing: NonNullable<
      ReturnType<typeof briefTemplateSelectors.selectIsTemplateBriefing>
    > = yield select(
      briefTemplateSelectors.selectIsTemplateBriefing,
      project.id
    );

    const sceneDimensions = getSceneDimensionsForRender(project);

    yield call(
      setupViewer,
      templateObject,
      project,
      order,
      object,
      shape,
      shapesBoundingBoxes,
      sceneDimensions,
      isTemplateBriefing ? undefined : artwork?.artworkUrl,
      asset,
      viewerContainer as HTMLDivElement,
      !isTemplateBriefing,
      deps.dispatch
    );

    yield put(actions.viewerInitialized(descriptor));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('viewer worker: caught error', e, 'in', descriptor);
  } finally {
    if ((yield cancelled()) as boolean) {
      debugSetupViewerWorker('clean up');
      yield call(
        removeViewer,
        makeObjectContainerId(descriptor.layerSetId, descriptor.objectId)
      );
    }
  }
}

export function* watchViewerUpdates({
  order,
  projectId,
}: {
  order: RawOrder;
  projectId: string;
}) {
  debugWatchViewerUpdates('started');
  while (true) {
    const updateAction:
      | ActionType<typeof actions.updateObject>
      | ActionType<typeof actions.changePixelsInOneRelativeUnit> = yield take([
      getType(actions.updateObject),
      getType(actions.changePixelsInOneRelativeUnit),
    ]);

    const project: NonNullable<
      ReturnType<typeof selectors.selectProject>
    > = yield select(selectors.selectProject, projectId);

    const shapesBoundingBoxes: NonNullable<
      ReturnType<typeof selectors.selectShapesBoundingBoxes>
    > = yield select(selectors.selectShapesBoundingBoxes);

    const selectedLayerSetId: NonNullable<
      ReturnType<typeof selectors.selectSelectedLayerSetId>
    > = yield select(selectors.selectSelectedLayerSetId, projectId);

    const { orderShapes } = project;
    const shapeAssets = extractAllShapeAssets(order);
    const sceneDimensions = getSceneDimensionsForRender(project);

    if (actions.isChangePixelsInOneRelativeUnitAction(updateAction)) {
      const productLayerObjects = pickProductLayerObjects(project);

      yield all(
        productLayerObjects.map((object) => {
          const objectId = object.id;
          const templateObject = productLayerObjects.find(
            (x) => x.id === objectId
          )!;
          const asset = shapeAssets.find(
            (x) => x.assetId === templateObject.assetId
          )!;
          const shape = orderShapes.find((x) => x.assetId === asset.assetId)!;

          const [, objectChanges] = selectProjectObjectWithChanges(
            project,
            objectId
          );

          const { scale, position } = insertObjectPositionRelatedSettings(
            templateObject.insertionPoint,
            objectChanges.insertionPointOffset,
            shape.physicalHeight,
            shapesBoundingBoxes[asset.assetId],
            sceneDimensions,
            {}
          );

          return call(
            updateViewerSettings,
            objectId,
            project.selectedLayerSetId,
            {
              scale,
              position,
            }
          );
        })
      );
    } else {
      const {
        payload: { objectId, ...updates },
      } = updateAction;

      if (updates.insertionPointOffset !== undefined) {
        const newInsertionPointOffset = updates.insertionPointOffset;
        const sceneDimensions = getSceneDimensionsForRender(project);

        const templateObject = pickProductLayerObjects(project).find(
          (x) => x.id === objectId
        )!;
        const asset = shapeAssets.find(
          (x) => x.assetId === templateObject.assetId
        )!;
        const shape = orderShapes.find((x) => x.assetId === asset.assetId)!;

        const { position } = insertObjectPositionRelatedSettings(
          templateObject.insertionPoint,
          newInsertionPointOffset,
          shape.physicalHeight,
          shapesBoundingBoxes[asset.assetId],
          sceneDimensions,
          {}
        );

        yield call(updateViewerSettings, objectId, project.selectedLayerSetId, {
          position,
        });
        yield put(
          actions.applyObjectChanges({
            objectId,
            insertionPointOffset: newInsertionPointOffset,
            projectId,
          })
        );
      }

      if (updates.shadow !== undefined) {
        const newShadow = updates.shadow;

        updateViewerSettings(objectId, selectedLayerSetId, {
          shadow: newShadow,
        });
        yield put(
          actions.applyObjectChanges({
            objectId,
            shadow: newShadow,
            projectId,
          })
        );
      }

      if (updates.reflection !== undefined) {
        const newReflection = updates.reflection;

        updateViewerSettings(objectId, selectedLayerSetId, {
          reflection: newReflection,
        });
        yield put(
          actions.applyObjectChanges({
            objectId,
            reflection: newReflection,
            projectId,
          })
        );
      }

      if (updates.angle !== undefined) {
        const newAngle = updates.angle;

        updateViewerSettings(objectId, selectedLayerSetId, { angle: newAngle });
        yield put(
          actions.applyObjectChanges({
            objectId,
            angle: newAngle,
            projectId,
          })
        );
      }

      if (
        updates.hasBackgroundInfluence !== undefined ||
        updates.backgroundOpacity !== undefined ||
        updates.backgroundBaseColor !== undefined
      ) {
        yield put(
          actions.applyBackgroundInfluenceChanges.request({
            entityId: objectId,
          })
        );
        const selectedProjectObject: ReturnType<
          typeof selectors.selectSelectedProjectObject
        > = yield select(selectors.selectSelectedProjectObject, projectId);

        if (!selectedProjectObject) return;

        const [, objectChanges] = selectedProjectObject;
        const newHasBackgroundInfluence =
          updates.hasBackgroundInfluence ??
          objectChanges.hasBackgroundInfluence;

        const templateObject = pickProductLayerObjects(project).find(
          (x) => x.id === objectId
        )! as ProductLayerObject;

        const baseShapeBackground: ReturnPromisedType<
          typeof prepareBackgroundForShape
        > = yield call(
          prepareBackgroundForShape,
          templateObject,
          project,
          order,
          shapesBoundingBoxes,
          {
            width: sceneDimensions.sceneWidth,
            height: sceneDimensions.sceneHeight,
          },
          !newHasBackgroundInfluence
        );

        const background: ReturnPromisedType<
          typeof applyBackgroundProperties
        > = yield call(applyBackgroundProperties, {
          baseColor:
            updates.backgroundBaseColor ?? objectChanges.backgroundBaseColor,
          baseBackgroundUrl: baseShapeBackground,
          opacity: updates.backgroundOpacity ?? objectChanges.backgroundOpacity,
        });

        const finalShapeBackground: string = background;

        updateViewerSettings(objectId, project.selectedLayerSetId, {
          backgroundImageUrl: finalShapeBackground,
        });

        const updatesToApply: UpdateObjectChanges = {
          ...(updates.hasBackgroundInfluence !== undefined
            ? { hasBackgroundInfluence: updates.hasBackgroundInfluence }
            : {}),
          ...(updates.backgroundOpacity !== undefined
            ? { backgroundOpacity: updates.backgroundOpacity }
            : {}),
          ...(updates.backgroundBaseColor !== undefined
            ? { backgroundBaseColor: updates.backgroundBaseColor }
            : {}),
        };

        yield put(
          actions.applyObjectChanges({
            objectId,
            projectId,
            ...updatesToApply,
          })
        );
        yield put(
          actions.applyBackgroundInfluenceChanges.reset({ entityId: objectId })
        );
      }

      if (updates.artworkId) {
        const newArtworkId = updates.artworkId;
        const assetOfLastSelectedObject: ReturnType<
          typeof selectors.selectAssetOfLastSelectedObject
        > = yield select(selectors.selectAssetOfLastSelectedObject, projectId);

        if (assetOfLastSelectedObject) {
          updateViewerSettings(objectId, selectedLayerSetId, {
            artworkUrl: makeUrl(newArtworkId),
          });

          yield put(
            actions.applyObjectChanges({
              objectId,
              artworkId: newArtworkId,
              projectId,
            })
          );
        }
      }

      if (updates.position) {
        updateViewerSettings(objectId, project.selectedLayerSetId, {
          position: updates.position,
        });

        const templateObject = pickProductLayerObjects(project).find(
          (x) => x.id === objectId
        )! as ProductLayerObject;

        const asset = shapeAssets.find(
          (x) => x.assetId === templateObject.assetId
        )!;
        const shape = project.orderShapes.find(
          (x) => x.assetId === asset.assetId
        )!;

        const newInsertionPointOffset = getInsertionPointOffsetFromPosition(
          updates.position,
          templateObject.insertionPoint,
          shape.physicalHeight,
          getSceneDimensionsForRender(project)
        );

        yield put(
          actions.applyObjectChanges({
            objectId,
            insertionPointOffset: newInsertionPointOffset,
            projectId,
          })
        );
      }

      if (updates.movementBoundaries) {
        yield put(
          actions.applyObjectChanges({
            objectId,
            projectId,
            movementBoundaries: updates.movementBoundaries,
          })
        );
      }

      if (updates.insertionPointDepth !== undefined) {
        const productLayerObjects = pickProductLayerObjects(project);

        const templateObject = productLayerObjects.find(
          (x) => x.id === objectId
        )!;
        const asset = shapeAssets.find(
          (x) => x.assetId === templateObject.assetId
        )!;
        const shape = orderShapes.find((x) => x.assetId === asset.assetId)!;

        const [, objectChanges] = selectProjectObjectWithChanges(
          project,
          objectId
        );

        const { scale, position } = insertObjectPositionRelatedSettings(
          templateObject.insertionPoint,
          objectChanges.insertionPointOffset,
          shape.physicalHeight,
          shapesBoundingBoxes[asset.assetId],
          sceneDimensions,
          {},
          updates.insertionPointDepth
        );

        yield call(updateViewerSettings, objectId, project.selectedLayerSetId, {
          scale,
          position,
        });

        yield put(
          batchActions([
            actions.applyObjectChanges({
              objectId,
              projectId,
              insertionPointDepth: updates.insertionPointDepth,
            }),
            actions.changeInsertionPointLevelProperties({
              layerSetId: project.selectedLayerSetId,
              projectId,
              objectId,
              changes: { insertionPointDepth: updates.insertionPointDepth },
            }),
          ])
        );
      }
    }
  }
}

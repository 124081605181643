import { ATLayer, ATLayerSet, isATProductLayerObject } from '@domain/template';
import { ServerTemplateInfo, TemplateInfo } from './types';

export function convertToTemplateInfo(x: ServerTemplateInfo): TemplateInfo {
  try {
    const template = {
      ...x.data,
      layerSets: sortLayersByDepth(x.data.layerSets),
    };

    return {
      id: x.id.toString(),
      name: x.name,
      template,
      approved: x.approved,
    };
  } catch (e) {
    throw new Error('Something wrong with templates from backend.');
  }
}

function sortLayersByDepth(layerSets: ATLayerSet[]) {
  return layerSets.map((layerSet) => ({
    ...layerSet,
    layers: [...layerSet.layers].sort(compareLayersByDepth),
  }));
}

function compareLayersByDepth(layerA: ATLayer, layerB: ATLayer) {
  const objectLeft = layerA.objects[0];
  const objectRight = layerB.objects[0];

  if (
    !isATProductLayerObject(objectLeft) ||
    !isATProductLayerObject(objectRight)
  ) {
    return 0;
  }

  const [, , depthLeft] = objectLeft.insertionPoint;
  const [, , depthRight] = objectRight.insertionPoint;

  return depthLeft - depthRight;
}

import * as R from 'ramda';

import { isBriefingTemplate } from '@domain/template';
import {
  foldRemoteOption,
  fromNullableRemote,
  mapRemote,
  remoteNotAsked,
} from 'shared/remoteData';
import { AppState } from 'store/types';

function selectFeatureState(state: AppState) {
  return state.briefTemplate;
}

export function selectBackgrounds(state: AppState) {
  return selectFeatureState(state).data.backgrounds;
}

export function selectBackground(state: AppState, id: number) {
  return fromNullableRemote(
    mapRemote(selectFeatureState(state).data.backgrounds, (backgrounds) =>
      backgrounds.find((x) => x.id === id)
    ),
    'No such background.'
  );
}

export function selectUploadBackgroundComm(state: AppState) {
  return selectFeatureState(state).communications.uploadBackground;
}

export function selectTurnIntoTemplateComm(
  state: AppState,
  templateId: string
) {
  return (
    selectFeatureState(state).communications.turnIntoTemplate[templateId] ||
    remoteNotAsked
  );
}

export function selectTemplatesInfo(state: AppState) {
  return selectFeatureState(state).data.templatesInfo;
}

export function selectIsTemplateBriefing(
  state: AppState,
  templateId: string
): boolean {
  return foldRemoteOption(
    mapRemote(selectFeatureState(state).data.templatesInfo, (templatesInfo) =>
      isBriefingTemplate(templatesInfo, templateId)
    ),
    R.F,
    R.identity
  );
}

import { api } from 'aspects/api/Api';

export async function asyncDetermineImageDimensions(
  image: string
): Promise<{
  imageElement: HTMLImageElement;
  width: number;
  height: number;
}> {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    const updatedImageUrl = await fixCorsIssue(image);

    const imageElement = new Image();
    // https://garyrafferty.com/software/Tainted-Canvas/ when working with images outside localhost
    imageElement.crossOrigin = 'anonymous';
    imageElement.onload = () => {
      const { width, height } = imageElement;
      resolve({ imageElement, width, height });
    };
    imageElement.onerror = reject;
    imageElement.src = updatedImageUrl;
  });
}

/**
 * A temporary fix for the CORS issue in Chrome + S3 + duplicated request to the same image
 * https://stackoverflow.com/questions/49503171/the-image-tag-with-crossorigin-anonymous-cant-load-success-from-s3
 *
 * TODO: replace it with a proper solution
 */
async function fixCorsIssue(imageUrl: string) {
  const isAwsUrl = imageUrl.includes('X-Amz-Credential');

  if (!isAwsUrl) {
    return imageUrl;
  }

  try {
    const response = await api.urls.updateUrl(imageUrl);

    return response.url;
  } catch (error) {
    window.console.error('Failed to update URL.', error);

    return imageUrl;
  }
}

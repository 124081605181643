import { InsertionPoint, MovementBoundaries } from '@domain/template';

export function calcDownscaledDimensionsBasedOnWidth(
  [width, height]: [number, number],
  widthDownscaleFactor: number
): [number, number] {
  const widthToHeightRatio = width / height;
  const newWidth = width / widthDownscaleFactor;
  const newHeight = newWidth / widthToHeightRatio;
  return [newWidth, newHeight];
}

export function calcDownscaledDimensionsBasedOnHeight(
  [width, height]: [number, number],
  newHeight: number
): [number, number] {
  const newWidth = (newHeight * width) / height;
  return [newWidth, newHeight];
}

export function calcDownscaledInsertionPoint(
  [x, y, z]: InsertionPoint,
  widthDownscaleFactor: number,
  heightDownscaleFactor: number
): InsertionPoint {
  if (x === 0 || y === 0) {
    return [
      x === 0 ? x : x / widthDownscaleFactor,
      y === 0 ? y : y / heightDownscaleFactor,
      z,
    ];
  }

  return [
    ...calcDownscaledDimensionsBasedOnWidth([x, y], widthDownscaleFactor),
    z,
  ];
}

export function calcDownscaledMovementBoundaries(
  [left, up, right, down]: MovementBoundaries,
  widthDownscaleFactor: number,
  heightDownscaleFactor: number
) {
  return [
    left / widthDownscaleFactor,
    up / heightDownscaleFactor,
    right / widthDownscaleFactor,
    down / heightDownscaleFactor,
  ] as MovementBoundaries;
}

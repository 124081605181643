import { RefObject } from 'react';

export function mapRefValue<T, R>(
  ref: RefObject<T | null>,
  onSome: (value: T) => R,
  onNone: () => R
): R;
export function mapRefValue<T, R>(
  ref: RefObject<T | null>,
  onSome: (value: T) => R,
  onNone?: () => R
): R | undefined;
export function mapRefValue<T, R>(
  ref: RefObject<T | null>,
  onSome: (value: T) => R,
  onNone?: () => R
): R | undefined {
  const refValue = ref.current;
  return refValue !== null ? onSome(refValue) : onNone?.();
}

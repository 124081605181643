import React, { useCallback } from 'react';
import * as R from 'ramda';
import cx from 'clsx';

import { Slider } from 'shared/components/Slider/Slider';
import { SliderInput } from 'shared/components/Slider/SliderInput';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { MovementBoundaries, InsertionPointOffset } from '@domain/template';
import { rotateInRange } from 'utils/math/rotateInRange';
import css from './ProductControls.module.scss';

export type ChangeDirection = 'left' | 'up' | 'right' | 'down';

type ProductControlsProps = {
  isAffectedByLayersInPrinciple: boolean;
  horizontalAngle: number | undefined;
  verticalAngle: number | undefined;
  horizontalRotationStep: number;
  verticalRotationStep: number;
  movementBoundaries: MovementBoundaries | undefined;
  insertionPointOffset: InsertionPointOffset | undefined;
  hasBackgroundInfluence: boolean | undefined;
  shadow: number | undefined;
  reflection: number | undefined;
  shouldShowShapesScaleSlider: boolean;
  pixelsInOneRelativeUnit: number;
  maxPixelsInOneRelativeUnit: number;
  backgroundOpacity: number;
  backgroundBaseColor: string;
  areMovementBoundariesVisible: boolean;
  isBackgroundInfluenceBeingApplied: boolean;
  shouldShowMovementBoundariesControls: boolean;
  shouldShowBackgroundControls: boolean;
  onBackgroundBaseColorChange(color: string): void;
  onHorizontalAngleChange(value: number): void;
  onVerticalAngleChange(value: number): void;
  onInsertionPointOffsetChange(offset: InsertionPointOffset): void;
  onBackgroundInfluenceToggle(): void;
  onShadowChange(value: number): void;
  onReflectionChange(value: number): void;
  onPixelsInOneRelativeUnitChange(value: number): void;
  onBackgroundOpacityChange(value: number): void;
  onSetMovementBoundariesVisibility(value: boolean): void;
};

export function ProductControls(props: ProductControlsProps) {
  const {
    horizontalAngle,
    horizontalRotationStep,
    verticalAngle,
    verticalRotationStep,
    movementBoundaries,
    insertionPointOffset,
    hasBackgroundInfluence,
    shadow,
    reflection,
    isAffectedByLayersInPrinciple,
    shouldShowShapesScaleSlider,
    pixelsInOneRelativeUnit,
    maxPixelsInOneRelativeUnit,
    backgroundOpacity,
    backgroundBaseColor,
    areMovementBoundariesVisible,
    isBackgroundInfluenceBeingApplied,
    shouldShowMovementBoundariesControls,
    shouldShowBackgroundControls,
    onHorizontalAngleChange,
    onVerticalAngleChange,
    onInsertionPointOffsetChange,
    onBackgroundInfluenceToggle,
    onShadowChange,
    onReflectionChange,
    onPixelsInOneRelativeUnitChange,
    onBackgroundOpacityChange,
    onBackgroundBaseColorChange,
    onSetMovementBoundariesVisibility,
  } = props;

  const handleShadowChange = useCallback(
    (value: number) => {
      onShadowChange(value / 100);
    },
    [onShadowChange]
  );

  const handleReflectionChange = useCallback(
    (value: number) => {
      onReflectionChange(value / 100);
    },
    [onReflectionChange]
  );

  const handleBackgroundOpacityChange = useCallback(
    (value: number) => {
      onBackgroundOpacityChange(value / 100);
    },
    [onBackgroundOpacityChange]
  );

  const handleBackgroundBaseColorChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onBackgroundBaseColorChange((event.target as HTMLInputElement).value);
    },
    [onBackgroundBaseColorChange]
  );

  let controls = [
    <>
      <h6 className={css.controlTitle}>Horizontal rotation</h6>
      <Slider
        min={0}
        max={360}
        step={horizontalRotationStep}
        value={rotateInRange({ value: horizontalAngle || 0, range: 360 })}
        onChange={onHorizontalAngleChange}
        Input={SliderInput}
        inputValueType="percent-placeholder"
      />
    </>,
  ];

  if (verticalRotationStep !== 180) {
    controls.push(
      <>
        <h6 className={css.controlTitle}>Vertical rotation</h6>
        <Slider
          min={-90}
          max={90}
          step={verticalRotationStep}
          value={verticalAngle || 0}
          onChange={onVerticalAngleChange}
          Input={SliderInput}
          inputValueType="percent-placeholder"
        />
      </>
    );
  }

  if (shouldShowShapesScaleSlider) {
    const scaleAllShapesSlider = (
      <>
        <h6 className={css.controlTitle}>Scale shapes</h6>
        <Slider
          min={1}
          max={maxPixelsInOneRelativeUnit}
          step={0.1}
          value={pixelsInOneRelativeUnit}
          onChange={onPixelsInOneRelativeUnitChange}
          Input={SliderInput}
          inputValueType="percent-placeholder"
        />
      </>
    );
    controls = R.insert(0, scaleAllShapesSlider, controls);
  }

  if (shouldShowBackgroundControls && isAffectedByLayersInPrinciple) {
    const backgroundInfluenceControls = (
      <>
        <div className={css.backgroundInfluenceCheckbox}>
          <Checkbox
            checked={hasBackgroundInfluence ?? true}
            onChange={onBackgroundInfluenceToggle}
            label={
              isBackgroundInfluenceBeingApplied
                ? 'Applying changes...'
                : 'Background influence enabled'
            }
            disabled={isBackgroundInfluenceBeingApplied}
          />
        </div>
        <h6 className={css.controlTitle}>Background opacity</h6>
        <Slider
          min={0}
          max={100}
          step={1}
          value={backgroundOpacity * 100}
          onChange={handleBackgroundOpacityChange}
          Input={SliderInput}
          inputValueType="percent-placeholder"
        />
        <div className={css.backgroundBaseColorControl}>
          <h6 className={cx(css.controlTitle, css.backgroundBaseColor)}>
            Background base color:
          </h6>
          <input
            type="color"
            onChange={handleBackgroundBaseColorChange}
            value={backgroundBaseColor}
          />
        </div>
      </>
    );
    controls.push(backgroundInfluenceControls);
  }

  if (shadow !== undefined) {
    controls.push(
      <>
        <h6 className={css.controlTitle}>Shadow</h6>
        <Slider
          min={0}
          max={100}
          step={1}
          value={shadow * 100}
          onChange={handleShadowChange}
          Input={SliderInput}
          inputValueType="percent"
        />
      </>
    );
  }

  if (reflection !== undefined) {
    controls.push(
      <>
        <h6 className={css.controlTitle}>Reflection</h6>
        <Slider
          min={0}
          max={100}
          step={1}
          value={reflection * 100}
          onChange={handleReflectionChange}
          Input={SliderInput}
          inputValueType="percent"
        />
      </>
    );
  }

  const handleHorizontalMovement = useCallback(
    (value: number) => {
      if (insertionPointOffset) {
        onInsertionPointOffsetChange([value, insertionPointOffset[1]]);
      }
    },
    [insertionPointOffset, onInsertionPointOffsetChange]
  );

  const handleVerticalMovement = useCallback(
    (value: number) => {
      if (insertionPointOffset) {
        onInsertionPointOffsetChange([insertionPointOffset[0], value]);
      }
    },
    [insertionPointOffset, onInsertionPointOffsetChange]
  );

  if (
    shouldShowMovementBoundariesControls &&
    movementBoundaries !== undefined &&
    insertionPointOffset !== undefined
  ) {
    const [left, up, right, down] = movementBoundaries;
    const [x, y] = insertionPointOffset;

    controls.push(
      <Checkbox
        checked={areMovementBoundariesVisible}
        onChange={onSetMovementBoundariesVisibility}
        label="Movement boundaries visible"
      />
    );

    if (left !== 0 || right !== 0) {
      controls.push(
        <>
          <h6 className={css.controlTitle}>Horizontal Movement</h6>
          <Slider
            min={left}
            startPoint={0}
            max={right}
            value={x}
            onChange={handleHorizontalMovement}
            Input={SliderInput}
            inputValueType="percent-placeholder"
          />
        </>
      );
    }

    if (up !== 0 || down !== 0) {
      controls.push(
        <>
          <h6 className={css.controlTitle}>Vertical Movement</h6>
          <Slider
            min={up}
            startPoint={0}
            max={down}
            value={y}
            onChange={handleVerticalMovement}
            Input={SliderInput}
            inputValueType="percent-placeholder"
          />
        </>
      );
    }
  }

  return (
    <div className={css.productControlsPanel}>
      {controls.map((control, index) => (
        <div key={index} className={css.productControl}>
          {control}
        </div>
      ))}
    </div>
  );
}

import * as D from '@domain/template';
import { isATProductLayerObject } from './template';

export function decreaseProductLayerObjectsInLayerSet(
  template: D.AdminTemplate,
  layerSetId: string
): D.AdminTemplate {
  const layerSet = template.layerSets.find((x) => x.id === layerSetId);

  if (layerSet === undefined) return template;

  const productLayerObjects = layerSet.layers
    .flatMap((x) => x.objects)
    .filter(isATProductLayerObject);

  const lastProductLayerObject =
    productLayerObjects[productLayerObjects.length - 1];

  return {
    ...template,
    layerSets: template.layerSets.map((x) =>
      x.id === layerSet.id
        ? {
            ...x,
            layers: x.layers.filter(
              (l) => !l.objects.some((o) => o.id === lastProductLayerObject.id)
            ),
          }
        : x
    ),
  };
}

import { ProductLayerObject, Template } from '@domain/template';
import {
  calcDownscaledDimensionsBasedOnWidth,
  calcDownscaledInsertionPoint,
  calcDownscaledMovementBoundaries,
} from './calcDownscaledDimensions';

export function downscaleLayerObjects(
  template: Template,
  widthDownscaleFactor: number,
  heightDownscaleFactor: number
): Template {
  return {
    ...template,
    layers: template.layers.map((layer) => ({
      ...layer,
      objects: layer.objects.map((object) => {
        if (object.type === 'background') {
          return object;
        }

        if (object.type === 'image') {
          const [x, y] = calcDownscaledInsertionPoint(
            [...object.coords, 1],
            widthDownscaleFactor,
            heightDownscaleFactor
          );

          return {
            ...object,
            resolution: calcDownscaledDimensionsBasedOnWidth(
              object.resolution,
              widthDownscaleFactor
            ),
            coords: [x, y],
          };
        }

        return downscaleProductLayerObject(
          object,
          widthDownscaleFactor,
          heightDownscaleFactor
        );
      }),
    })),
  };
}

export function downscaleProductLayerObject(
  object: ProductLayerObject,
  widthDownscaleFactor: number,
  heightDownscaleFactor: number
) {
  const newInsertionPoint = calcDownscaledInsertionPoint(
    object.insertionPoint,
    widthDownscaleFactor,
    heightDownscaleFactor
  );

  return {
    ...object,
    movementBoundaries: calcDownscaledMovementBoundaries(
      object.movementBoundaries,
      widthDownscaleFactor,
      heightDownscaleFactor
    ),
    insertionPoint: newInsertionPoint,
  };
}

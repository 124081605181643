import css from './Checkbox.module.scss';

type Props = {
  checked: boolean;
  onChange(value: boolean): void;
  label: string;
} & Omit<JSX.IntrinsicElements['input'], 'onChange'>;

export const Checkbox: React.FC<Props> = (props) => {
  const { checked, onChange, label, ...rest } = props;

  return (
    <label className={css.checkbox}>
      <input
        className={css.input}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
        {...rest}
      />
      <span className={css.box} />
      <span className={css.label}>{label}</span>
    </label>
  );
};

import React, { useMemo } from 'react';

export const ADMIN_USERS_IDS = ['555'];

type UserRole = 'user' | 'admin';

const DEFAULT_ROLE: UserRole = 'user';

type RolesContextValue = {
  userRole: UserRole;
};

const RolesContext = React.createContext<RolesContextValue>({
  userRole: DEFAULT_ROLE,
});

export function RolesProvider({
  children,
  userId,
}: React.PropsWithChildren<{
  userId: string | null;
}>) {
  const value = useMemo(
    (): RolesContextValue => ({
      userRole: ADMIN_USERS_IDS.some((x) => x === userId) ? 'admin' : 'user',
    }),
    [userId]
  );

  return (
    <RolesContext.Provider value={value}>{children}</RolesContext.Provider>
  );
}

export function useRolesContext() {
  const context = React.useContext<RolesContextValue>(RolesContext);
  if (context === undefined) {
    throw new Error('useRolesContext must be used within a RolesProvider');
  }
  return context;
}

import { useCallback } from 'react';
import * as D from '@domain/template';
import { ProductControls } from '../../../view/ProductControls/ProductControls';
import { ProductControlsValues } from './RightPanel';
import { AdjustSectionProps } from './AdjustSection';

type Props = {
  values: ProductControlsValues;
  projectId: string;
  selectedObjectId: string;
  shouldShowShapesScaleSlider: boolean;
  shouldShowMovementBoundariesControls: boolean;
  shouldShowBackgroundControls: boolean;
  pixelsInOneRelativeUnit: number;
  maxPixelsInOneRelativeUnit: number;
  updateObject: AdjustSectionProps['updateObject'];
  changePixelsInOneRelativeUnit: AdjustSectionProps['changePixelsInOneRelativeUnit'];
  areMovementBoundariesVisible: boolean;
  setMovementBoundariesVisibility: AdjustSectionProps['setMovementBoundariesVisibility'];
  isBackgroundInfluenceBeingApplied: boolean;
};

export function ProductControlsContainer(props: Props) {
  const {
    values,
    projectId,
    selectedObjectId,
    updateObject,
    changePixelsInOneRelativeUnit,
    shouldShowShapesScaleSlider,
    pixelsInOneRelativeUnit,
    maxPixelsInOneRelativeUnit,
    setMovementBoundariesVisibility,
    areMovementBoundariesVisible,
    isBackgroundInfluenceBeingApplied,
    shouldShowMovementBoundariesControls,
    shouldShowBackgroundControls,
  } = props;
  const {
    reflection,
    shadow,
    angle,
    insertionPointOffset,
    backgroundOpacity,
    backgroundBaseColor,
  } = values;

  const handleHorizontalAngleChange = useCallback(
    (newHorizontalAngle: number) => {
      const horizontalAngle = angle.horizontal;
      if (
        selectedObjectId &&
        horizontalAngle !== newHorizontalAngle &&
        projectId
      ) {
        const newAngle = {
          vertical: angle.vertical || 0,
          horizontal: newHorizontalAngle,
        };
        updateObject({
          objectId: selectedObjectId,
          angle: newAngle,
          projectId,
        });
      }
    },
    [updateObject, selectedObjectId, angle, projectId]
  );

  const handleVerticalAngleChange = useCallback(
    (newVerticalAngle: number) => {
      const verticalAngle = angle.vertical;
      if (verticalAngle !== newVerticalAngle) {
        const newAngle = {
          vertical: newVerticalAngle,
          horizontal: angle.horizontal || 0,
        };
        updateObject({
          objectId: selectedObjectId,
          angle: newAngle,
          projectId,
        });
      }
    },
    [updateObject, selectedObjectId, angle, projectId]
  );

  const handleInsertionPointOffsetChange = useCallback(
    (newInsertionPointOffset: D.InsertionPointOffset) => {
      const prevAndNextDifferent =
        insertionPointOffset[0] !== newInsertionPointOffset[0] ||
        insertionPointOffset[1] !== newInsertionPointOffset[1];

      if (prevAndNextDifferent) {
        updateObject({
          objectId: selectedObjectId,
          insertionPointOffset: newInsertionPointOffset,
          projectId,
        });
      }
    },
    [updateObject, selectedObjectId, insertionPointOffset, projectId]
  );

  const handleShadowChange = useCallback(
    (newShadow: number) => {
      if (shadow !== newShadow) {
        updateObject({
          objectId: selectedObjectId,
          shadow: newShadow,
          projectId,
        });
      }
    },
    [updateObject, selectedObjectId, shadow, projectId]
  );

  const handleReflectionChange = useCallback(
    (newReflection: number) => {
      if (reflection !== newReflection) {
        updateObject({
          objectId: selectedObjectId,
          reflection: newReflection,
          projectId,
        });
      }
    },
    [reflection, updateObject, selectedObjectId, projectId]
  );

  const handleBackgroundInfluenceToggle = useCallback(() => {
    if (selectedObjectId) {
      updateObject({
        objectId: selectedObjectId,
        hasBackgroundInfluence: !values.hasBackgroundInfluence,
        projectId,
      });
    }
  }, [
    updateObject,
    selectedObjectId,
    projectId,
    values.hasBackgroundInfluence,
  ]);

  const handleBackgroundOpacityChange = useCallback(
    (newOpacity: number) => {
      if (backgroundOpacity !== newOpacity) {
        updateObject({
          objectId: selectedObjectId,
          backgroundOpacity: newOpacity,
          projectId,
        });
      }
    },
    [backgroundOpacity, updateObject, selectedObjectId, projectId]
  );

  const handlePixelsInOneRelativeUnitChange = useCallback(
    (value: number) => changePixelsInOneRelativeUnit({ value, projectId }),
    [projectId, changePixelsInOneRelativeUnit]
  );

  const handleBackgroundBaseColorChange = useCallback(
    (newColor: string) => {
      if (backgroundBaseColor !== newColor) {
        updateObject({
          objectId: selectedObjectId,
          backgroundBaseColor: newColor,
          projectId,
        });
      }
    },
    [backgroundBaseColor, updateObject, selectedObjectId, projectId]
  );

  return (
    <ProductControls
      horizontalAngle={angle.horizontal}
      verticalAngle={angle.vertical}
      horizontalRotationStep={values.horizontalRotationStep}
      verticalRotationStep={values.verticalRotationStep}
      movementBoundaries={values.movementBoundaries}
      insertionPointOffset={values.insertionPointOffset}
      hasBackgroundInfluence={values.hasBackgroundInfluence}
      shadow={values.shadow}
      reflection={values.reflection}
      backgroundOpacity={values.backgroundOpacity}
      backgroundBaseColor={backgroundBaseColor}
      onBackgroundBaseColorChange={handleBackgroundBaseColorChange}
      isBackgroundInfluenceBeingApplied={isBackgroundInfluenceBeingApplied}
      isAffectedByLayersInPrinciple={values.influencedByLayers.length > 0}
      onHorizontalAngleChange={handleHorizontalAngleChange}
      onVerticalAngleChange={handleVerticalAngleChange}
      onBackgroundInfluenceToggle={handleBackgroundInfluenceToggle}
      onInsertionPointOffsetChange={handleInsertionPointOffsetChange}
      onShadowChange={handleShadowChange}
      onReflectionChange={handleReflectionChange}
      onPixelsInOneRelativeUnitChange={handlePixelsInOneRelativeUnitChange}
      shouldShowShapesScaleSlider={shouldShowShapesScaleSlider}
      pixelsInOneRelativeUnit={pixelsInOneRelativeUnit}
      maxPixelsInOneRelativeUnit={maxPixelsInOneRelativeUnit}
      onBackgroundOpacityChange={handleBackgroundOpacityChange}
      areMovementBoundariesVisible={areMovementBoundariesVisible}
      onSetMovementBoundariesVisibility={setMovementBoundariesVisibility}
      shouldShowMovementBoundariesControls={
        shouldShowMovementBoundariesControls
      }
      shouldShowBackgroundControls={shouldShowBackgroundControls}
    />
  );
}

import { ActionType, getType } from 'deox';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';

import {
  lineupProductsActions,
  lineupProductsSelectors,
} from 'features/lineupProducts';
import {
  applyLineupChangesToAdminTemplate,
  generateBriefingTemplateInfo,
} from '@domain/template';

import { isSuccess, shouldLoadRemoteData } from 'shared/remoteData';
import { ReturnPromisedType } from 'shared/utilTypes';
import { SagaDependencies } from 'store/types';

import * as actions from './actions';
import * as selectors from './selectors';

export function* briefTemplateSaga(deps: SagaDependencies) {
  yield takeLatest(getType(actions.loadBackgrounds), loadBackgrounds, deps);
  yield takeEvery(getType(actions.loadTemplatesInfo), loadTemplatesInfo, deps);
  yield takeEvery(getType(actions.uploadBackground), uploadBackground, deps);
  yield takeEvery(getType(actions.deleteBackground), deleteBackground, deps);
  yield takeEvery(getType(actions.turnIntoTemplate), turnIntoTemplate, deps);
  yield takeEvery(getType(actions.selectBackground), addBriefing);
}

function* loadBackgrounds({ api }: SagaDependencies) {
  const backgrounds: ReturnType<
    typeof selectors.selectBackgrounds
  > = yield select(selectors.selectBackgrounds);

  if (shouldLoadRemoteData(backgrounds)) {
    try {
      yield put(actions.loadBackgroundsActions.request());

      const backgrounds: ReturnPromisedType<
        typeof api.backgrounds.getBackgrounds
      > = yield call(api.backgrounds.getBackgrounds);

      yield put(actions.loadBackgroundsActions.success(backgrounds));
    } catch (e) {
      yield put(actions.loadBackgroundsActions.failed(e.message));
    }
  }
}

function* uploadBackground(
  { api }: SagaDependencies,
  action: ActionType<typeof actions.uploadBackground>
) {
  const {
    background: backgroundImage,
    name: backgroundName,
    backgroundWidth,
    backgroundHeight,
  } = action.payload;
  const backgrounds: ReturnType<
    typeof selectors.selectBackgrounds
  > = yield select(selectors.selectBackgrounds);

  const order: ReturnType<
    typeof lineupProductsSelectors.selectOrder
  > = yield select(lineupProductsSelectors.selectOrder);

  if (isSuccess(backgrounds) && isSuccess(order)) {
    try {
      yield put(actions.uploadBackgroundActions.request());
      const uploadedBackground: ReturnPromisedType<
        typeof api.backgrounds.uploadBackground
      > = yield call(
        api.backgrounds.uploadBackground,
        backgroundImage,
        backgroundName
      );

      const briefingTemplateInfo = generateBriefingTemplateInfo({
        resolution: [backgroundWidth, backgroundHeight],
        backgroundImage: uploadedBackground.url,
      });

      yield put(
        batchActions([
          actions.loadBackgroundsActions.success(
            [uploadedBackground].concat(backgrounds.value)
          ),
          actions.uploadBackgroundActions.reset(),
          lineupProductsActions.addBriefing({
            template: briefingTemplateInfo,
          }),
          lineupProductsActions.selectTemplate({
            templateId: briefingTemplateInfo.id,
            isBriefing: true,
          }),
        ])
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
      yield put(
        actions.uploadBackgroundActions.failed('Failed to upload background.')
      );
    }
  }
}

function* deleteBackground(
  { api }: SagaDependencies,
  action: ActionType<typeof actions.deleteBackground>
) {
  const { id } = action.payload;
  const backgrounds: ReturnType<
    typeof selectors.selectBackgrounds
  > = yield select(selectors.selectBackgrounds);
  const backgroundToRemove: ReturnType<
    typeof selectors.selectBackground
  > = yield select(selectors.selectBackground, id);

  if (isSuccess(backgrounds) && isSuccess(backgroundToRemove)) {
    try {
      yield put(
        actions.loadBackgroundsActions.success(
          backgrounds.value.filter((x) => x.id !== id)
        )
      );
      yield call(api.backgrounds.deleteBackground, id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
      yield put(actions.loadBackgroundsActions.success(backgrounds.value));
    }
  }
}

function* turnIntoTemplate(
  { api }: SagaDependencies,
  { payload: { templateId, name } }: ActionType<typeof actions.turnIntoTemplate>
) {
  const selectedTemplateInfo: ReturnType<
    typeof lineupProductsSelectors.selectSelectedTemplateInfo
  > = yield select(lineupProductsSelectors.selectSelectedTemplateInfo);

  if (selectedTemplateInfo !== null) {
    try {
      const project: NonNullable<
        ReturnType<typeof lineupProductsSelectors.selectProject>
      > = yield select(lineupProductsSelectors.selectProject, templateId);

      yield put(
        actions.turnIntoTemplateActions.request({ entityId: templateId })
      );

      yield call(
        api.templates.createTemplateInfo,
        applyLineupChangesToAdminTemplate(
          selectedTemplateInfo.template,
          project.objects,
          project.objectsChangesInLayerSets,
          project.sceneDimensions.widthDownscaleFactor,
          project.sceneDimensions.heightDownscaleFactor
        ),
        (name !== undefined && name.trim().length > 0 && name.trim()) ||
          selectedTemplateInfo.name
      );

      yield put(
        actions.turnIntoTemplateActions.success({
          entityId: templateId,
          data: templateId,
        })
      );
    } catch (e) {
      yield put(
        actions.turnIntoTemplateActions.failed({
          entityId: templateId,
          error: `Failed.`,
        })
      );
    }
  }
}

function* addBriefing(action: ActionType<typeof actions.selectBackground>) {
  const { width, height, backgroundUrl } = action.payload;
  const order: ReturnType<
    typeof lineupProductsSelectors.selectOrder
  > = yield select(lineupProductsSelectors.selectOrder);

  if (isSuccess(order)) {
    const briefingTemplateInfo = generateBriefingTemplateInfo({
      resolution: [width, height],
      backgroundImage: backgroundUrl,
    });

    yield put(
      batchActions([
        lineupProductsActions.addBriefing({
          template: briefingTemplateInfo,
        }),
        lineupProductsActions.selectTemplate({
          templateId: briefingTemplateInfo.id,
          isBriefing: true,
        }),
      ])
    );
  }
}

function* loadTemplatesInfo({ api }: SagaDependencies) {
  const templatesInfo: ReturnType<
    typeof selectors.selectTemplatesInfo
  > = yield select(selectors.selectTemplatesInfo);

  if (shouldLoadRemoteData(templatesInfo)) {
    try {
      yield put(actions.loadTemplatesInfoActions.request());

      const templatesInfo: ReturnPromisedType<
        typeof api.templates.getTemplatesInfo
      > = yield call(api.templates.getTemplatesInfo);

      yield put(actions.loadTemplatesInfoActions.success(templatesInfo));
    } catch (e) {
      yield put(actions.loadTemplatesInfoActions.failed(e.message));
    }
  }
}

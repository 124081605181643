import { useCallback, useEffect, useState } from 'react';
import cx from 'clsx';
import BaseSlider, { SliderProps, Handle } from 'rc-slider';
import * as R from 'ramda';
import { usePrevious } from 'react-use';

import css from './Slider.module.scss';

type Props = SliderProps & {
  Input?: React.ComponentType<InputProps>;
  value: number;
  min: number;
  max: number;
  inputValueType?: InputProps['inputValueType'];
};

export type InputProps = {
  className?: string;
  value: number;
  inputValueType?: 'percent' | 'percent-placeholder';
  normalizeValue(value: number): number;
  onValueChanged(value: number): void;
};

export const SliderHandle = (
  props: Parameters<NonNullable<SliderProps['handle']>>[0]
) => {
  const { value, ...restProps } = props;
  return (
    <Handle
      value={value}
      {...(R.omit(['dragging'], restProps) as unknown)}
      className={cx(restProps.className, css.handle)}
    />
  );
};

export const Slider: React.FC<Props> = (props) => {
  const { Input, inputValueType, onChange, value, ...sliderProps } = props;
  const step = sliderProps.step ?? 1;

  const [sliderValue, setSliderValue] = useState<number>(value);

  const normalizeValue = useCallback(
    (newInputValue: number) =>
      Math.round(
        R.clamp(sliderProps.min, sliderProps.max, newInputValue) / step
      ) * step,
    [sliderProps.min, sliderProps.max, step]
  );

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (prevValue !== value && sliderValue !== value) {
      setSliderValue(value);
    }
  }, [prevValue, sliderValue, value, sliderProps.min, sliderProps.max]);

  const prevSliderValue = usePrevious(sliderValue);
  useEffect(() => {
    if (prevSliderValue !== undefined && prevSliderValue !== sliderValue) {
      onChange?.(sliderValue);
    }
  }, [onChange, prevSliderValue, sliderValue]);

  return (
    <div className={css.slider}>
      <BaseSlider
        {...sliderProps}
        value={sliderValue}
        className={css.baseSlider}
        handle={SliderHandle}
        onChange={setSliderValue}
      />
      {Input && (
        <Input
          className={css.input}
          inputValueType={inputValueType}
          value={sliderValue}
          onValueChanged={setSliderValue}
          normalizeValue={normalizeValue}
        />
      )}
    </div>
  );
};

// from https://github.com/INDGDev/Grip.UI/blob/master/packages/app/src/app/views/Extensions/ExtensionPage/extensionAppEvent.ts

export interface IExtensionAppEventUid {
  extensionId: string;
}

export interface CloseExtensionApp extends IExtensionAppEventUid {
  type: 'CloseExtensionApp';
}

export interface StartExtensionApp extends IExtensionAppEventUid {
  type: 'StartExtensionApp';
}

export interface RenderOrder extends IExtensionAppEventUid {
  type: 'RenderOrder';
  data: OrderSettings;
}

export enum OrderType {
  Regular = 'regular',
  Preview = 'preview',
}

export interface PriceListItem {
  format: 'png' | 'psd';
  maxWidth: number;
  maxHeight: number;
  price: number;
}

export interface RegularOrderSettings {
  type: OrderType.Regular;
  priceList: PriceListItem[];
  width: number;
  height: number;
  format: 'png' | 'psd';
  aspectRatio: number;
  renderSettings: unknown; // some render settings for render farm
}

export interface PreviewOrderSettings {
  type: OrderType.Preview;
  price: number;
  width: number;
  height: number;
  format: 'png';
}

export type OrderSettings = RegularOrderSettings | PreviewOrderSettings;

export type ExtensionAppEvent =
  | CloseExtensionApp
  | StartExtensionApp
  | RenderOrder;

function postMessage(message: ExtensionAppEvent): void {
  const gripAppHost = '*';
  window.parent.postMessage(message, gripAppHost);
}

export function requestPreviewOrder(
  payload: Omit<PreviewOrderSettings, 'type' | 'format'>,
  extensionId: string
) {
  const data: PreviewOrderSettings = {
    type: OrderType.Preview,
    format: 'png',
    ...payload,
  };
  postMessage({ type: 'RenderOrder', extensionId, data });
}

export function requestRegularOrder(
  payload: Omit<RegularOrderSettings, 'type' | 'format'>,
  extensionId: string
) {
  const data: RegularOrderSettings = {
    type: OrderType.Regular,
    format: 'png',
    ...payload,
  };
  postMessage({ type: 'RenderOrder', extensionId, data });
}

import { getCanvasWithImage } from './getCanvasWithImage';

export type ShapeRect = {
  rightmostX: number;
  leftmostX: number;
  topmostY: number;
  bottommostY: number;
};

export async function getShapeRect(
  image: string,
  sceneWidth: number,
  sceneHeight: number
) {
  const [canvas] = await getCanvasWithImage(image);

  const points = window.MarchingSquaresOpt.getBlobOutlinePoints(
    canvas,
    sceneWidth,
    sceneHeight
  );

  let nextX = true;
  const shapeRect = points.reduce(
    (acc, x) => {
      if (nextX) {
        nextX = false;
        return {
          ...acc,
          rightmostX: x >= acc.rightmostX ? x : acc.rightmostX,
          leftmostX: x <= acc.leftmostX ? x : acc.leftmostX,
        } as ShapeRect;
      }
      const y = x;
      nextX = true;
      return {
        ...acc,
        topmostY: y <= acc.topmostY ? y : acc.topmostY,
        bottommostY: y >= acc.bottommostY ? y : acc.bottommostY,
      } as ShapeRect;
    },
    {
      rightmostX: points[0],
      leftmostX: points[0],
      topmostY: points[1],
      bottommostY: points[1],
    } as ShapeRect
  );

  return shapeRect;
}

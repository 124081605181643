/**
 * Enables you to replace imperative try-catch block with more declarative one
 * @example
 *
 * async function requestEntity(id: string) {
 *  let entity;
 *
 *  try {
 *    entity = await api.getEntity(id);
 *  } catch (error) {
 *    logger(error);
 *  }
 *
 *  // do something with entity
 * }
 *
 *
 * const entity = await asyncTryCatch(
 *  () => api.getEntity(id),
 *  logger,
 * )
 *
 * // do something with entity
 * }
 */
export async function asyncTryCatch<A, E>(
  fn: () => Promise<A>,
  onError?: (error: E) => unknown
) {
  try {
    return await fn();
  } catch (error) {
    onError?.(error);

    return undefined;
  }
}

export function validateFileAgainstAllowedMimeTypes(params: {
  mimeTypes: string[];
  file: File;
}) {
  if (!params.mimeTypes.includes(params.file.type)) {
    const join = (it: string[], separator: string) =>
      it
        .map((it) => it.split('/'))
        .map(([_, ext]) => ext)
        .join(separator);

    const commaSeparated = join(params.mimeTypes.slice(0, -1), ', ');
    const last = join(params.mimeTypes.slice(-1), ' and ');

    return {
      isValid: false,
      message: `Unsupported format. Only ${commaSeparated} and ${last} allowed`,
    };
  }

  return {
    isValid: true,
  };
}

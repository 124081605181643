export function rotateInRange({
  range,
  value,
}: {
  range: number;
  value: number;
}) {
  const sign = Math.sign(value);

  if (sign < 0) {
    return range + (value % range);
  }

  return value % range;
}

import * as R from 'ramda';
import { nanoid } from 'nanoid';

import * as D from '@domain/template';
import { AdminTemplate, isATProductLayerObject } from '@domain/template';
import {
  extractAllShapeAssets,
  GENERIC_SHAPE,
  isShapeAsset,
  RawOrder,
  ShapeAsset,
} from '@domain/order';

import {
  DEFAULT_LAYER_SET_LEVEL_CHANGES,
  makeLayerSetLevelChanges,
} from './temp';

export function getInitialProjectProductsData({
  adminTemplate,
  order,
  isBriefing,
}: {
  adminTemplate: AdminTemplate;
  order: RawOrder;
  isBriefing: boolean;
}) {
  const products = initProducts({ adminTemplate, order, isBriefing });

  let nextLayerSetProductIndexToTake = 0;
  const objectsChangesInLayerSets = adminTemplate.layerSets.reduce(
    (acc, layerSet) => {
      nextLayerSetProductIndexToTake = 0;
      const layerSetProductObjects = layerSet.layers
        .flatMap((x) => x.objects)
        .filter(isATProductLayerObject);
      return {
        ...acc,
        [layerSet.id]: products.reduce((acc, object) => {
          const templateProduct: D.ATProductLayerObject | undefined =
            layerSetProductObjects[nextLayerSetProductIndexToTake];

          const productConfigFromTemplate = templateProduct
            ? ({
                backgroundBaseColor:
                  templateProduct.backgroundBaseColor ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundBaseColor,
                backgroundOpacity:
                  templateProduct.backgroundOpacity ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundOpacity,
                angle:
                  templateProduct.angle ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.angle,
                shadow:
                  templateProduct.shadow ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.shadow,
                reflection:
                  templateProduct.reflection ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.reflection,
                hasBackgroundInfluence:
                  templateProduct.hasBackgroundInfluence ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.hasBackgroundInfluence,
                movementBoundaries:
                  templateProduct.movementBoundaries ??
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.movementBoundaries,
              } as Partial<D.LayerSetLevelObjectChanges>)
            : undefined;
          // eslint-disable-next-line no-plusplus
          nextLayerSetProductIndexToTake++;
          return {
            ...acc,
            [object.id]: makeLayerSetLevelChanges(productConfigFromTemplate),
          };
        }, {}),
      };
    },
    {}
  );

  return { objects: products, objectsChangesInLayerSets };
}

export function initProducts({
  adminTemplate,
  order,
  isBriefing,
}: {
  adminTemplate: AdminTemplate;
  order: RawOrder;
  isBriefing: boolean;
}) {
  const getNumberOfInsertionPoints = (layerSet: D.ATLayerSet) =>
    layerSet.layers
      .flatMap((x) => x.objects)
      .filter((x) => x.type === 'product').length;
  const maxNumberOfInsertionPoints = R.reduce(
    (maxCount, layerSet) =>
      R.max(maxCount, getNumberOfInsertionPoints(layerSet)),
    getNumberOfInsertionPoints(adminTemplate.layerSets[0]),
    adminTemplate.layerSets
  );

  function initProduct(
    asset: ShapeAsset,
    index: number,
    artworkUrl: string | null
  ): D.ProjectObject {
    return {
      assetId: asset.assetId,
      thumbnailUrl: asset.thumbnailUrl,
      id: nanoid(),
      name: `Product ${index + 1}`,
      artworkId: artworkUrl,
      imgUrl: asset.thumbnailUrl,
    };
  }

  const shapeAssets = isBriefing
    ? [GENERIC_SHAPE]
    : extractAllShapeAssets(order, true);
  const assets = isBriefing ? [GENERIC_SHAPE] : order.assets;

  let lastProductIndex = 0;
  const products = assets.reduce((acc: D.ProjectObject[], asset) => {
    if (isShapeAsset(asset)) {
      const artwork = shapeAssets.find((x) => x.assetId === asset.assetId)
        ?.relatedArtworks?.[0];
      const product = initProduct(
        asset,
        lastProductIndex,
        artwork?.artworkUrl || null
      );
      // eslint-disable-next-line no-plusplus
      lastProductIndex++;
      return acc.concat(product);
    }

    const artworkShapes = asset.relatedShapes;
    return acc.concat(
      artworkShapes.map((x) => {
        const product = initProduct(
          { ...x, relatedArtworks: [] },
          lastProductIndex,
          asset.artworkUrl
        );
        // eslint-disable-next-line no-plusplus
        lastProductIndex++;
        return product;
      })
    );
  }, []);

  if (products.length < maxNumberOfInsertionPoints) {
    let nextProductToTake = 0;
    const extraProducts = Array.from(
      Array(maxNumberOfInsertionPoints - products.length)
    ).map((_, index) => {
      const productsNeedToBeDuplicatedMoreThanOnce = index >= products.length;
      if (productsNeedToBeDuplicatedMoreThanOnce) {
        nextProductToTake = 0;
      }
      const productToDuplicate = products[nextProductToTake];

      const productDuplicate = {
        ...productToDuplicate,
        id: nanoid(),
        name: `Product ${lastProductIndex + 1}`,
      };
      /* eslint-disable no-plusplus */
      lastProductIndex++;
      nextProductToTake++;
      /* eslint-enable no-plusplus */
      return productDuplicate;
    });
    products.push(...extraProducts);
  }

  return products;
}

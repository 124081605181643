import { nanoid } from 'nanoid';

import {
  AdminTemplate,
  ATLayer,
  ATProductLayerObject,
  BRIEFINGS_GROUP_NAME,
} from '@domain/template';
import { GENERIC_SHAPE } from '@domain/order';
import { HARDCODED_SHAPES_SIZES } from 'features/lineupProducts/Editor/editorRendering/calcPhysicalHeightsForShapes';
import { exhaustiveCheck } from 'utils/exhaustiveCheck';
import { TemplateInfo } from 'aspects/api/modules/Templates/types';
import { DEFAULT_LAYER_SET_LEVEL_CHANGES } from 'features/lineupProducts/redux/temp';

export function generateBriefingTemplateInfo({
  name,
  ...rest
}: Partial<Pick<TemplateInfo, 'name'>> &
  Omit<GenerateAdminTemplateParams, 'templateId'>): TemplateInfo {
  const id = nanoid();
  return {
    id,
    approved: false,
    name: name ?? `Briefing ${new Date().toUTCString()}`,
    template: generateAdminTemplate({ ...rest, templateId: id }),
  };
}

export type GenerateAdminTemplateParams = {
  resolution: AdminTemplate['resolution'];
  backgroundImage: string;
  templateId: string;
};

const DEFAULT_NUMBER_OF_PRODUCTS_TO_GENERATE = 1;

export function generateAdminTemplate({
  resolution,
  backgroundImage,
  templateId,
}: GenerateAdminTemplateParams): AdminTemplate {
  const [imageWidth, imageHeight] = resolution;
  const productsCount = DEFAULT_NUMBER_OF_PRODUCTS_TO_GENERATE;
  const distanceBetweenProducts = imageWidth / productsCount;
  const positionsX = [...new Array(productsCount)].map(
    (_, index) => (index + 1) * (distanceBetweenProducts / 2)
  );

  const briefingShapeSize =
    HARDCODED_SHAPES_SIZES[GENERIC_SHAPE.shape360Config.id];

  const layerSetId = makeLayerSetId(templateId);
  const backgroundLayerId = makeBackgroundLayerId(layerSetId);

  return {
    resolution,
    // We don't know what the user's background height would be, so we cannot adjust pixelsInOneRelativeUnit for the shape to have real life-style size, so we're calculating pixelsInOneRelativeUnit in such way that the max size shape takes up 40% of the user's background height, the percent is not set in stone
    pixelsInOneRelativeUnit: (imageHeight / briefingShapeSize) * 0.4,
    type: 'custom',
    thumbnail: backgroundImage,
    version: 1, // TODO: use last version when migrations are ready
    categoryName: BRIEFINGS_GROUP_NAME,
    imgKeyToUrlMap: {
      background: backgroundImage,
    },
    layerSets: [
      {
        id: layerSetId,
        layers: [
          {
            id: backgroundLayerId,
            objects: [
              {
                id: makeLayerObjectId(backgroundLayerId),
                type: 'background',
                imgKey: 'background',
              },
            ],
          },
          ...[...new Array(productsCount)].map(
            (_, layerIndex): ATLayer => {
              const layerId = makeLayerId(layerSetId);
              const productObject: ATProductLayerObject = {
                id: makeLayerObjectId(layerId),
                type: 'product',
                layerId,
                insertionPoint: [positionsX[layerIndex], imageHeight * 0.9, 1],
                angle: DEFAULT_LAYER_SET_LEVEL_CHANGES.angle,
                shadow: DEFAULT_LAYER_SET_LEVEL_CHANGES.shadow,
                reflection: DEFAULT_LAYER_SET_LEVEL_CHANGES.reflection,
                movementBoundaries:
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.movementBoundaries,
                influencedByLayers: [backgroundLayerId],
                hasBackgroundInfluence:
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.hasBackgroundInfluence,
                backgroundBaseColor:
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundBaseColor,
                backgroundOpacity:
                  DEFAULT_LAYER_SET_LEVEL_CHANGES.backgroundOpacity,
              };
              return {
                id: layerId,
                objects: [productObject],
              };
            }
          ),
        ],
      },
    ],
  };
}

function makeLayerSetId(templateId: string) {
  return `${templateId}-layer-set-${nanoid()}`;
}

function makeBackgroundLayerId(layerSetId: string) {
  return `${layerSetId}-background-layer-${nanoid()}`;
}

export function makeLayerId(layerSetId: string) {
  return `${layerSetId}-layer-${nanoid()}`;
}

export function makeLayerObjectId(layerId: string) {
  return `${layerId}-object-${nanoid()}`;
}

export function generateLayerSetBasedOnExistingOne(
  layerSet: AdminTemplate['layerSets'][number],
  templateId: string
): AdminTemplate['layerSets'][number] {
  const layerSetId = makeLayerSetId(templateId);

  return {
    id: layerSetId,
    layers: layerSet.layers.map((layer) => {
      const layerId = makeLayerId(layerSetId);
      return {
        ...layer,
        id: layerId,
        objects: layer.objects.map((object) => {
          switch (object.type) {
            case 'background': {
              return {
                ...object,
                id: makeLayerObjectId(layerId),
              };
            }
            case 'product': {
              return {
                ...object,
                id: makeLayerObjectId(layerId),
                layerId,
              };
            }
            case 'image': {
              return {
                ...object,
                layerId,
                id: nanoid(),
              };
            }
            default: {
              return exhaustiveCheck(object);
            }
          }
        }),
      };
    }),
  };
}

export function extractAppConfig() {
  const devOrderId = new URL(window.location.href).pathname.slice(1);
  const gripSearchParams = new URLSearchParams(window.location.search);
  const orderId = gripSearchParams.has('orderid')
    ? gripSearchParams.get('orderid')
    : devOrderId;
  const workGroupId = gripSearchParams.get('work_group_id');
  const userId = gripSearchParams.get('userid');
  const templateIdFromAdminInterface = gripSearchParams.get('template_id');

  const gripExtensionId = gripSearchParams.get('extensionid');
  const extensionIdForAdminInterface =
    templateIdFromAdminInterface !== null ? 'admin-interface' : null;
  const extensionIdForDevEnv = devOrderId.trim().length > 0 ? 'dev-env' : null;
  const extensionId =
    gripExtensionId || extensionIdForAdminInterface || extensionIdForDevEnv;

  return {
    orderId,
    workGroupId,
    userId,
    extensionId,
    templateId: templateIdFromAdminInterface,
  };
}

import { AxiosRequestConfig } from 'axios';
import { HttpActions } from './HttpActions';
import { Backgrounds } from './modules/Backgrounds/Backgrounds';
import { Templates } from './modules/Templates/Templates';
import { Extensions } from './modules/Extensions/Extensions';
import { Urls } from './modules/Urls/Urls';

export class Api {
  private actions: HttpActions;

  public backgrounds: Backgrounds;

  public templates: Templates;

  public extensions: Extensions;

  public urls: Urls;

  private headers: AxiosRequestConfig['headers'] = {
    Authorization: 'Basic TGRwc1VSM3lYaTJpcDpVKDM2JWVodVp5VHZ0U05xKiM=',
  };

  constructor() {
    this.actions = new HttpActions(
      `${process.env.REACT_APP_LINEUP_DOMAIN}/api/`,
      this.headers
    );

    this.backgrounds = new Backgrounds(this.actions);
    this.templates = new Templates(this.actions);
    this.extensions = new Extensions();
    this.urls = new Urls(this.actions);
  }
}

const api = new Api();

export { api };

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnyAction, createReducer } from 'deox';

import { LineupProductsState } from './types';
import * as actions from './actions';
import { defaultState } from './initial';

export function projectsReducer(
  state: LineupProductsState['data']['projects'],
  action: AnyAction
) {
  return createReducer(defaultState.data.projects, (handleAction) => [
    handleAction(
      actions.removeLayerSet,
      (state, { payload: { projectId, layerSetId } }) => {
        const prevProject = state[projectId];

        const newObjectsChangesInLayerSets = {
          ...prevProject.objectsChangesInLayerSets,
        };
        delete newObjectsChangesInLayerSets[layerSetId];

        return {
          ...state,
          [prevProject.id]: {
            ...prevProject,
            objectsChangesInLayerSets: newObjectsChangesInLayerSets,
          },
        };
      }
    ),
  ])(state, action);
}

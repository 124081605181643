/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { exhaustiveCheck } from 'utils/exhaustiveCheck';
import {
  BackgroundLayerObject,
  isProductLayerObject,
  Project,
  ShapeBoundingBoxDetails,
  pickProductLayerObjects,
  isBackgroundLayerObject,
  selectImageUrl,
} from '@domain/template';
import {
  extractAllArtworkAssets,
  getUniqShapeAssets,
  RawOrder,
  ShapeAsset,
} from '@domain/order';
import { makeUrl } from 'environment';
import { selectProjectObjectWithChanges } from '../redux/reducerHelpers';
import { getSceneDimensionsForRender } from './viewerRendering/getSceneDimensionsForRender';
import { insertObjectPositionRelatedSettings } from './viewerRendering/renderObjectOnBackground';

type DocumentLayout = {
  size: [x: number, y: number]; // size of the layout; layer placement is related to this size
  layers: Layer[]; // the layers in the document, ordered from bottom to top
};

type Layer = BackgroundLayer | ImageLayer | SceneLayer;

/** Includes the background as defined in the order; this should be the first layer in the layout. */
type BackgroundLayer = {
  id: string; // ID by which the layer can be identified in the layout
  layerType: 'background';
};

/** Includes an image as layer in the output document. */
type ImageLayer = {
  id: string; // ID by which the layer can be identified in the layout
  layerType: 'image';
  name: string; // layer name in the PSD file
  contentId: string; // ID of the image in the order
  position: [x: number, y: number]; // position of the top-left corner of the image, relative to top-left corner of the layout
  width?: number; // layout width of the image
  height?: number; // layout height of the image
};

/** Includes a rendered scene as layer in the output document. */
type SceneLayer = {
  id: string; // ID by which the layer can be identified in the layout
  layerType: 'scene';
  name: string; // layer name in the PSD file
  contentId: string; // ID of the scene in the order
  influencedByLayers?: string[] | null; // optional list of layer IDs used to generate the render background; when unspecified the render will use the order background; when empty the render will use white
};

type RenderFarmOrder = {
  // renderJobId: string; should not pass it
  version: '2.0';
  processingStartDate: null;
  completionDate: null;
  previewImagePath: null | string;
  progressText: null;
  rendererAssignedTo: null;
  errorText: null;
  createdBy: string;
  createdAt: string;
  priority: 0;
  queueName: null;
  productName: 'HRPNG';
  renderSettings: {
    outputFileName: string;
    isTestRender: boolean;
    renderWidth: number;
    renderHeight: number;
    startFrame: null;
    endFrame: null;
    frameRate: null;
    background: {
      backgroundId: string;
      backgroundColor: string;
      backgroundUrl: string;
      environmentUrl: null;
      lightingUrl: null;
    };
    gradientEnabled: false;
    includeBackground: true;
    scenes: {
      id: string;
      position: [x: number, y: number];
      rotation: number;
      scale: number;
      origin: [x: number, y: number];
      horizontalAngle: number;
      verticalAngle: number;
      shapes: [
        {
          collectionId: 'testLineupCollection';
          collectionNasId: string;
          artworkId: string | null;
          surfaceIndex: 0;
        }
      ];
      enablePhotoIntegration: false;
      reflectionStrength: number;
      shadowStrength: number | null;
      colorCorrectionCurve: number[][];
      fadeWidth: number;
      fadeHeight: number;
    }[];
    images: { id: string; imageUrl: string }[];
    artworks: {
      artworkId: string;
      nasId: null | string;
      displayName: string;
      thumbnailUrl: string;
      artworkUrl: string;
    }[];
    layout: DocumentLayout;
  };
};

export type PlaceOrderSettings = {
  renderWidth: number;
  renderHeight: number;
};

export async function getRenderFarmOrder(
  project: Project,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  order: RawOrder,
  placeOrderSettings: PlaceOrderSettings
): Promise<[orderId: string, order: string]> {
  const renderFarmOrder = convertTemplateToRenderFarmOrder(
    project,
    shapesBoundingBoxes,
    order,
    placeOrderSettings
  );
  return [project.template.id, JSON.stringify(renderFarmOrder)];
}

function convertTemplateToRenderFarmOrder(
  project: Project,
  shapesBoundingBoxes: Record<string, ShapeBoundingBoxDetails>,
  order: RawOrder,
  placeOrderSettings: PlaceOrderSettings
): RenderFarmOrder {
  const { template, orderShapes } = project;

  const backgroundObject = template.layers
    .flatMap((x) => x.objects)
    .find(isBackgroundLayerObject);

  const images: RenderFarmOrder['renderSettings']['images'] = Object.entries(
    template.imgKeyToUrlMap
  )
    .filter(([id]) => id !== backgroundObject?.imgKey)
    .map(([id, imageUrl]) => ({
      id,
      imageUrl,
    }));

  const sceneDimensions = getSceneDimensionsForRender(project);

  const shapeAssets = getUniqShapeAssets(order.assets);
  const artworks = extractAllArtworkAssets(order);

  const scenes = template.layers.reduce(
    (acc: RenderFarmOrder['renderSettings']['scenes'], layer) =>
      acc.concat(
        ...layer.objects.map(
          (layerObject): RenderFarmOrder['renderSettings']['scenes'] => {
            if (isProductLayerObject(layerObject)) {
              const [
                projectObject,
                projectObjectChanges,
              ] = selectProjectObjectWithChanges(project, layerObject.id);

              const asset = shapeAssets.find(
                (x) => x.assetId === layerObject.assetId
              )!;
              const shape = orderShapes.find(
                (x) => x.assetId === asset.assetId
              )!;
              const artwork = artworks.find(
                (x) => x.artworkUrl === projectObject.artworkId
              );

              const { position, scale } = insertObjectPositionRelatedSettings(
                layerObject.insertionPoint,
                projectObjectChanges.insertionPointOffset,
                shape.physicalHeight,
                shapesBoundingBoxes[asset.assetId],
                sceneDimensions,
                {}
              );

              const origin = calculateView2dOrigin(asset.shape360Config);
              return [
                {
                  id: layer.id,
                  position: [position.x, position.y],
                  rotation: 0.0,
                  scale,
                  origin,
                  horizontalAngle: projectObjectChanges.angle.horizontal,
                  verticalAngle: projectObjectChanges.angle.vertical,
                  shapes: [
                    {
                      collectionId: 'testLineupCollection',
                      collectionNasId: asset.shape360Config.id,
                      artworkId: artwork?.assetId || null,
                      surfaceIndex: 0,
                    },
                  ],
                  enablePhotoIntegration: false,
                  reflectionStrength: projectObjectChanges.reflection,
                  shadowStrength: projectObjectChanges.shadow,
                  colorCorrectionCurve: asset.shape360Config.curve,
                  fadeWidth: asset.shape360Config.fadeWidth,
                  fadeHeight: asset.shape360Config.fadeHeight,
                },
              ];
            }
            return [];
          }
        )
      ),
    []
  );

  const layout: RenderFarmOrder['renderSettings']['layout'] = {
    size: [sceneDimensions.sceneWidth, sceneDimensions.sceneHeight],
    layers: template.layers.flatMap((layer, index) =>
      layer.objects.map((layerObject) => {
        switch (layerObject.type) {
          case 'background': {
            return {
              id: layer.id,
              layerType: 'background',
            };
          }
          case 'product': {
            // all templates must have one object per layer
            return {
              id: layer.id,
              name: `Scene ${index + 1}`,
              layerType: 'scene',
              contentId: layer.id,
              influencedByLayers: layerObject.influencedByLayers,
            };
          }
          case 'image': {
            return {
              id: layer.id,
              name: layerObject.imgKey,
              layerType: 'image',
              contentId: layerObject.imgKey,
              width: layerObject.resolution[0],
              height: layerObject.resolution[1],
              position: layerObject.coords,
            };
          }
          default: {
            return exhaustiveCheck(layerObject);
          }
        }
      })
    ),
  };

  const orderArtworks = pickProductLayerObjects(template)
    .map((layerObject) => {
      const artwork = artworks.find(
        (x) => layerObject.artworkId === x.artworkUrl
      );

      if (artwork === undefined) return null;

      return {
        artworkId: artwork.assetId,
        nasId: null,
        displayName: `Artwork ${artwork.assetId}`,
        thumbnailUrl: makeUrl(artwork.thumbnailUrl),
        artworkUrl: makeUrl(artwork.artworkUrl),
      };
    })
    .filter((x) => x !== null) as RenderFarmOrder['renderSettings']['artworks'];

  const backgroundImageKey = (template.layers[0]
    .objects[0] as BackgroundLayerObject).imgKey;

  return {
    version: '2.0',
    processingStartDate: null,
    completionDate: null,
    previewImagePath: null,
    progressText: null,
    rendererAssignedTo: null,
    errorText: null,
    createdBy: order.createdBy,
    createdAt: order.createdAt,
    priority: 0,
    queueName: null,
    productName: 'HRPNG',
    renderSettings: {
      outputFileName: 'based_on_order_from_lineup_generator',
      isTestRender: true,
      renderWidth: placeOrderSettings.renderWidth,
      renderHeight: placeOrderSettings.renderHeight,
      startFrame: null,
      endFrame: null,
      frameRate: null,
      background: {
        backgroundId: backgroundImageKey,
        backgroundColor: 'ffffff',
        backgroundUrl: selectImageUrl(template, backgroundImageKey),
        environmentUrl: null,
        lightingUrl: null,
      },
      gradientEnabled: false,
      includeBackground: true,
      scenes,
      images,
      artworks: orderArtworks,
      layout,
    },
  };
}

/** From the viewer source code, TODO: expose calculated value through viewer api and remove */
function calculateView2dOrigin(
  shape360Config: ShapeAsset['shape360Config']
): [number, number] {
  const offsetX = shape360Config.offset[0];
  const offsetY = shape360Config.offset[1] - 5.5;
  return [0.5 + offsetX / 100, 0.5 + offsetY / 100];
}

import { asyncTryCatch } from 'utils/asyncTryCatch';
import {
  ProductLayerObject,
  ShapeBoundingBoxDetails,
  InsertionPointOffset,
  InsertionPoint,
} from '@domain/template';
import { ShapeAsset } from '@domain/order';
import { pxsToUnits, unitsToPxs } from '@domain/pxsToUnits';

import { ConfigurableShapeSettings } from '../getBase64Image';
import { renderImage } from '../renderImage';
import { getBoundingBoxDetailsAtHeight } from './getBoundingBoxDetailsAtHeight';
import { getScale } from './getScale';

export type SceneDimensions = {
  sceneHeight: number;
  sceneWidth: number;
  sceneHeightUnits: number;
  sceneWidthUnits: number;
};

type RenderObjectOnBackgroundParams = {
  asset: ShapeAsset;
  objectHeight: number;
  shapeBoundingBoxDetails: ShapeBoundingBoxDetails;
  object: ProductLayerObject;
  container?: HTMLDivElement;
  renderConfig: ConfigurableShapeSettings;
  insertionPointOffset?: InsertionPointOffset;
  sceneDimensions: SceneDimensions;
  sourceDimensions: { width: number; height: number };
};

export function insertObjectPositionRelatedSettings(
  insertionPoint: ProductLayerObject['insertionPoint'],
  insertionPointOffset: InsertionPointOffset = [0, 0],
  objectBaseHeight: number,
  shapeBasicBoundingBoxDetails: ShapeBoundingBoxDetails,
  sceneDimensions: SceneDimensions,
  renderConfig: ConfigurableShapeSettings,
  maybeDepthScale?: number
) {
  const { sceneHeight, sceneHeightUnits, sceneWidthUnits } = sceneDimensions;
  const [, , depthScale] = insertionPoint;
  const x = insertionPoint[0] + insertionPointOffset[0];
  const y = insertionPoint[1] + insertionPointOffset[1];
  const finalDepthScale = maybeDepthScale ?? depthScale;

  const objectHeight = objectBaseHeight * finalDepthScale;

  const shapeBoundingBoxDetails = getBoundingBoxDetailsAtHeight(
    shapeBasicBoundingBoxDetails,
    sceneHeight
  );

  const targetScale = getScale(
    shapeBoundingBoxDetails.shapeHeight,
    objectHeight
  );

  const targetX = pxsToUnits(x, sceneHeight) - sceneWidthUnits / 2;
  const targetY =
    pxsToUnits(y, sceneHeight) -
    pxsToUnits(objectHeight / 2, sceneHeight) -
    sceneHeightUnits / 2;

  return {
    ...renderConfig,
    scale: targetScale,
    position: { x: targetX, y: targetY },
    previewHeight: renderConfig.previewHeight
      ? renderConfig.previewHeight
      : sceneHeight,
  };
}

export function getInsertionPointOffsetFromPosition(
  sourcePosition: ShapePosition,
  insertionPoint: ProductLayerObject['insertionPoint'],
  objectBaseHeight: number,
  sceneDimensions: SceneDimensions
): InsertionPointOffset {
  const { x: sourceX, y: sourceY } = sourcePosition;
  const { sceneHeight, sceneHeightUnits, sceneWidthUnits } = sceneDimensions;

  const [, , depthScale] = insertionPoint;

  const objectHeight = objectBaseHeight * depthScale;

  const currentPointX = sourceX + sceneWidthUnits / 2;
  const currentPointY =
    sourceY + pxsToUnits(objectHeight / 2, sceneHeight) + sceneHeightUnits / 2;

  const { x: currentPointXInPx, y: currentPointYInPx } = unitsToPxs(
    { x: currentPointX, y: currentPointY },
    sceneHeight
  );

  return [
    currentPointXInPx - insertionPoint[0],
    currentPointYInPx - insertionPoint[1],
  ];
}

function calcInsertionPointAtSceneDimensions(
  insertionPoint: InsertionPoint,
  sourceDimensions: { width: number; height: number },
  targetDimensions: { width: number; height: number },
  insertionPointOffset?: InsertionPointOffset
): {
  insertionPoint: InsertionPoint;
  insertionPointOffset: InsertionPointOffset | undefined;
} {
  const [x, y, depthScale] = insertionPoint;
  const widthFactor = targetDimensions.width / sourceDimensions.width;
  const heightFactor = targetDimensions.height / sourceDimensions.height;
  return {
    insertionPoint: [x * widthFactor, y * heightFactor, depthScale],
    insertionPointOffset: insertionPointOffset
      ? [
          insertionPointOffset[0] * widthFactor,
          insertionPointOffset[1] * heightFactor,
        ]
      : undefined,
  };
}

export async function renderObjectOnBackground(
  params: RenderObjectOnBackgroundParams
): Promise<string> {
  const {
    asset,
    shapeBoundingBoxDetails: shapeBasicBoundingBoxDetails,
    object,
    renderConfig,
    objectHeight: objectBaseHeight,
    container,
    sourceDimensions,
    sceneDimensions,
    insertionPointOffset: sourceInsertionPointOffset,
  } = params;

  const {
    insertionPoint,
    insertionPointOffset,
  } = calcInsertionPointAtSceneDimensions(
    object.insertionPoint,
    sourceDimensions,
    {
      width: sceneDimensions.sceneWidth,
      height: sceneDimensions.sceneHeight,
    },
    sourceInsertionPointOffset
  );
  const partialConfigurableSettings = insertObjectPositionRelatedSettings(
    insertionPoint,
    insertionPointOffset,
    objectBaseHeight,
    shapeBasicBoundingBoxDetails,
    sceneDimensions,
    renderConfig
  );

  const newBackground = (await asyncTryCatch(
    async () =>
      renderImage({
        objectId: object.id,
        asset,
        partialConfigurableSettings,
        container,
      }),
    (err) => window.console.log('ERROR >>>', err)
  )) as string;

  return newBackground;
}

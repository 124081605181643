import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button } from 'shared/components/Button/Button';
import { Modal, ModalProps } from 'shared/components/Modal/Modal';

import css from './TurnIntoTemplateModal.module.scss';

const schema = yup.object().shape({
  name: yup
    .string()
    .max(200, (max) => `Name must be at most ${max.max} characters`),
  comment: yup
    .string()
    .max(
      2500,
      (max) => `Briefing information must be at most ${max.max} characters`
    ),
});

type FormValues = Pick<yup.TypeOf<typeof schema>, 'name' | 'comment'>;

export type SubmitData = { name?: string; comment?: string };

type Props = {
  onTurn(value: SubmitData): void;
  onCancel(): void;
} & ModalProps;

export function TurnIntoTemplateModal(props: Props) {
  const { onTurn, onCancel, ...rest } = props;
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      onTurn({ name: data.name, comment: data.comment });
    },
    [onTurn]
  );

  const { errors, isValid } = formState;

  return (
    <Modal {...rest} onRequestClose={onCancel}>
      <h3 className={css.title}>Turn the briefing into a template</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={css.inputContainer}>
          <label className={css.inputLabel}>Name:</label>
          <Input {...register('name')} />
        </div>
        <p className={css.errorMessage}>{errors.name?.message}</p>

        <div className={css.inputContainer}>
          <label className={css.inputLabel}>Briefing information:</label>
          <Textarea {...register('comment')} />
        </div>
        <p className={css.errorMessage}>{errors.comment?.message}</p>

        <div className={css.actions}>
          <Button classes={{ root: css.actionButton }} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            classes={{ root: css.actionButton }}
            type="submit"
            theme="red"
            disabled={!isValid}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
}

type InputProps = JSX.IntrinsicElements['input'];

function Input(props: InputProps) {
  const { ...rest } = props;
  return <input className={css.input} {...rest} />;
}

type TextareaProps = JSX.IntrinsicElements['textarea'];

function Textarea(props: TextareaProps) {
  const { ...rest } = props;
  return <textarea className={css.textarea} {...rest} />;
}

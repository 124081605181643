import cx from 'clsx';
import { Classes } from 'shared/style';
import css from './Button.module.scss';

type Props = {
  classes?: Classes<'root'>;
  disabled?: boolean;
  theme?: 'red';
  onClick?(): void;
} & JSX.IntrinsicElements['button'];

export const Button: React.FC<Props> = (props) => {
  const { classes, disabled, onClick, theme, children, ...rest } = props;
  return (
    <button
      type="button"
      disabled={disabled}
      className={cx(css.button, theme && css[`${theme}Theme`], classes?.root)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

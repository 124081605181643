import { ShapeBoundingBoxDetails } from '@domain/template';

export function getBoundingBoxDetailsAtHeight(
  boundingBoxDetails: ShapeBoundingBoxDetails,
  height: number
): ShapeBoundingBoxDetails {
  if (!boundingBoxDetails || boundingBoxDetails.imageHeight === 0)
    return boundingBoxDetails;

  const scaleFactor = height / boundingBoxDetails.imageHeight;
  return {
    assetId: boundingBoxDetails.assetId,
    paddingTop: boundingBoxDetails.paddingTop * scaleFactor,
    paddingBottom: boundingBoxDetails.paddingBottom * scaleFactor,
    paddingLeft: boundingBoxDetails.paddingLeft * scaleFactor,
    paddingRight: boundingBoxDetails.paddingRight * scaleFactor,
    shapeHeight: boundingBoxDetails.shapeHeight * scaleFactor,
    shapeWidth: boundingBoxDetails.shapeWidth * scaleFactor,
    boundingBoxHeight: height,
    imageWidth: height,
    imageHeight: height,
  };
}

import { combineReducers } from 'redux';

import {
  createMultiRemoteDataReducer,
  createRemoteDataReducer,
} from 'shared/remoteData';

import { LineupProductsState } from './types';
import * as actions from './actions';
import { dataReducer } from './dataReducer';
import { defaultState } from './initial';

const communicationsReducer = combineReducers<
  LineupProductsState['communications']
>({
  producePNGPreview: createRemoteDataReducer(
    defaultState.communications.producePNGPreview,
    actions.producePNGPreviewActions
  ),
  saveTemplateChanges: createMultiRemoteDataReducer(
    defaultState.communications.saveTemplateChanges,
    actions.saveTemplateChangesActions
  ),
  applyBackgroundInfluenceChanges: createMultiRemoteDataReducer(
    defaultState.communications.applyBackgroundInfluenceChanges,
    actions.applyBackgroundInfluenceChanges
  ),
});

export const lineupProductsReducer = combineReducers<LineupProductsState>({
  data: dataReducer,
  communications: communicationsReducer,
});

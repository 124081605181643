/* eslint-disable camelcase */
import { boundMethod } from 'autobind-decorator';

import { RawOrder } from '@domain/order';
import { HttpActions } from '../../HttpActions';

export class Extensions {
  private actions: HttpActions;

  constructor() {
    this.actions = new HttpActions(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v3/extensions/`
    );
  }

  @boundMethod
  async getOrder(orderId: string): Promise<RawOrder> {
    const response = await this.actions.get<RawOrder>(`/orders/${orderId}`);

    if (response.status !== 200) {
      throw new Error('Failed to load order.');
    }

    return response.data;
  }
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { foldRemoteOption, isSuccess, remoteNotAsked } from 'shared/remoteData';
import { AppState } from 'store/types';
import * as D from '@domain/template';

import { extractAllShapeAssets } from '@domain/order';
import { TemplateInfo } from 'aspects/api/modules/Templates/types';
import { AdminTemplate } from '@domain/template';
import { ProductControlsValues } from '../Editor/components/RightPanel/RightPanel';
import { LimitedInputConfig } from '../Editor/components/RightPanel/components/LimitedInput';
import { HARDCODED_SHAPES_SIZES } from '../Editor/editorRendering/calcPhysicalHeightsForShapes';
import {
  selectProjectObjectsWithChanges,
  selectProjectObjectWithChanges,
} from './reducerHelpers';
import { InsertionPointValues, LayerSetChangeDirection } from './types';
import {
  calcObjectDimensionsOnScene,
  ObjectDimensionsOnScene,
} from '../Editor/editorRendering/calcObjectDimensionsOnScene';
import { MAX_AMOUNT_OF_PRODUCTS_IN_SCENE } from './initial';

function selectFeatureState(state: AppState) {
  return state.lineupProducts;
}

function createProjectSpecificSelector<T extends unknown[], R>(
  selector: (project: D.Project, state: AppState, ...args: T) => R
): (state: AppState, projectId: string, ...args: T) => R {
  return (state: AppState, projectId: string, ...args: T) => {
    const project = selectProject(state, projectId);
    return selector(project, state, ...args);
  };
}

export function selectSelectedProjectId(state: AppState) {
  return selectFeatureState(state).data.selectedTemplateId;
}

export function selectOpeningContext(state: AppState) {
  return selectFeatureState(state).data.openingContext;
}

export function selectProject(state: AppState, projectId: string) {
  const { projects } = selectFeatureState(state).data;
  return projects[projectId];
}

export function selectShapesBoundingBoxes(state: AppState) {
  return selectFeatureState(state).data.shapesBoundingBoxes.data;
}

export function selectAreShapesBoundingBoxesSetup(state: AppState) {
  return selectFeatureState(state).data.shapesBoundingBoxes.isSetup;
}

export function selectActiveTabName(state: AppState) {
  return selectFeatureState(state).data.activeTabName;
}

export function selectSelectedTemplatesGroupId(state: AppState) {
  return selectFeatureState(state).data.selectedTemplatesGroupId;
}

export function selectSelectedTemplateId(state: AppState) {
  return selectFeatureState(state).data.selectedTemplateId;
}

export function selectSelectedTemplatesGroup(state: AppState) {
  const templatesGroupsRD = selectTemplatesGroups(state);
  const selectedTemplatesGroupId = selectSelectedTemplatesGroupId(state);
  return foldRemoteOption(
    templatesGroupsRD,
    () => null,
    (templatesGroups) =>
      templatesGroups.find((x) => x.id === selectedTemplatesGroupId)
  );
}

export function selectOrder(state: AppState) {
  return selectFeatureState(state).data.order;
}

export function selectTemplatesGroups(state: AppState) {
  return selectFeatureState(state).data.templatesGroups;
}

export const selectSelectedLayerSetId = createProjectSpecificSelector(
  (project) => project.selectedLayerSetId
);

export function selectIsBriefTemplateOpened(state: AppState) {
  return selectFeatureState(state).data.isBriefTemplateOpened;
}

export const selectMaxPixelsInOneRelativeUnit = createProjectSpecificSelector(
  (project, state) => {
    const order = selectOrder(state);

    if (project && isSuccess(order)) {
      const resolution = project?.template.resolution;
      if (resolution) {
        const [, imageHeight] = resolution;
        const minShapeSize = extractAllShapeAssets(order.value)
          .map(
            (x) =>
              HARDCODED_SHAPES_SIZES[x.shape360Config.id] ||
              HARDCODED_SHAPES_SIZES.defaultSize
          )
          .reduce((acc, x) => Math.min(acc, x));
        return imageHeight / minShapeSize;
      }
    }
    return 1;
  }
);

export const selectSelectedObjectId = createProjectSpecificSelector(
  (project) => project.selectObjectId
);

export const selectTemplateObject = createProjectSpecificSelector(
  (project, _, objectId: string) => {
    const productLayerObjects = D.pickProductLayerObjects(project);
    return productLayerObjects.find((x) => x.id === objectId)!;
  }
);

export const selectSelectedObject = createProjectSpecificSelector(
  (project, state) => {
    const selectedObjectId = selectSelectedObjectId(state, project.id);

    const allProductLayerObjects = project
      ? D.pickProductLayerObjects(project)
      : [];
    const lastSelectedObject = selectedObjectId
      ? allProductLayerObjects.find((x) => x.id === selectedObjectId)
      : allProductLayerObjects[0];

    return lastSelectedObject;
  }
);

export const selectAssetOfLastSelectedObject = createProjectSpecificSelector(
  (project, state) => {
    const selectedObject = selectSelectedObject(state, project.id);

    if (
      project?.orderShapes &&
      selectedObject &&
      D.isProductLayerObject(selectedObject)
    ) {
      return (
        project.orderShapes.find((x) => x.assetId === selectedObject.assetId) ||
        null
      );
    }
    return null;
  }
);

export const selectSelectedProjectObject = createProjectSpecificSelector(
  (project, state) => {
    const selectedObject = selectSelectedObject(state, project.id);

    return project && selectedObject?.id
      ? selectProjectObjectWithChanges(project, selectedObject.id)
      : null;
  }
);

export const selectProductControlsValues = createProjectSpecificSelector(
  (project, state): ProductControlsValues | null => {
    const selectedProjectObject = selectSelectedProjectObject(
      state,
      project.id
    );

    if (selectedProjectObject && project) {
      const [projectObject, projectObjectChanges] = selectedProjectObject;
      const { orderShapes } = project;
      const productLayerObject = D.pickProductLayerObjects(project).find(
        (x) => x.id === projectObject.id
      )!;
      const shape = orderShapes.find(
        (x) => x.assetId === projectObject.assetId
      )!;

      return {
        horizontalRotationStep: shape.horizontalRotationStep,
        verticalRotationStep: shape.verticalRotationStep,
        angle: projectObjectChanges.angle,
        shadow: projectObjectChanges.shadow,
        reflection: projectObjectChanges.reflection,
        insertionPointOffset: projectObjectChanges.insertionPointOffset,
        hasBackgroundInfluence: projectObjectChanges.hasBackgroundInfluence,
        movementBoundaries: productLayerObject.movementBoundaries,
        influencedByLayers: productLayerObject.influencedByLayers,
        backgroundOpacity: projectObjectChanges.backgroundOpacity,
        backgroundBaseColor: projectObjectChanges.backgroundBaseColor,
      };
    }

    return null;
  }
);

export const selectLayerSetsControlValues = createProjectSpecificSelector(
  (project, state): LimitedInputConfig | null => {
    const selectedAdminTemplate = selectSelectedTemplateInfo(state)
      ?.template as AdminTemplate;

    if (selectedAdminTemplate === null) {
      return null;
    }

    if (selectedAdminTemplate.layerSets.length <= 1) {
      return null;
    }

    const { selectedLayerSetIndex } = project;

    return {
      min: 0,
      max: selectedAdminTemplate.layerSets.length - 1,
      value: selectedLayerSetIndex,
      valueToDisplay: selectedAdminTemplate.layerSets[
        selectedLayerSetIndex
      ].layers
        .flatMap((x) => x.objects)
        .filter((x) => x.type === 'product').length,
    };
  }
);

export const selectProductsAmountInSceneControlValues = createProjectSpecificSelector(
  (project, state): LimitedInputConfig => {
    const maxAmountOfProducts = Math.min(
      project.objects.length,
      MAX_AMOUNT_OF_PRODUCTS_IN_SCENE
    );

    const currentSelection = selectCurrentSelection(state, project.id);

    return {
      min: 1,
      max: maxAmountOfProducts,
      value: currentSelection,
    };
  }
);

export function selectSelectedTemplateInfo(
  state: AppState
): TemplateInfo | null {
  const selectedTemplatesGroupId = selectSelectedTemplatesGroupId(state);
  const selectedTemplateId = selectSelectedTemplateId(state);
  const templatesGroupsRD = selectTemplatesGroups(state);

  if (selectedTemplateId === null || selectedTemplatesGroupId === null) {
    return null;
  }

  return foldRemoteOption(
    templatesGroupsRD,
    () => null,
    (templatesGroups) => {
      const selectedGroup = templatesGroups.find(
        (x) => x.id === selectedTemplatesGroupId
      )!;

      return selectedGroup.templates.find((x) => x.id === selectedTemplateId)!;
    }
  );
}

export const selectPixelsInOneRelativeUnit = createProjectSpecificSelector(
  (project) => project?.template.pixelsInOneRelativeUnit || 1
);

export const selectCurrentSelection = createProjectSpecificSelector((project) =>
  project ? D.pickProductLayerObjects(project).length : 0
);

type ObjectToDimensionsOnScene = {
  [objectId: string]: ObjectDimensionsOnScene;
};

export const selectObjectToDimensionsOnScene = createProjectSpecificSelector(
  (project, state): ObjectToDimensionsOnScene => {
    const projectObjectsWithChanges = selectProjectObjectsWithChanges(project);
    const shapesBoundingBoxes = selectShapesBoundingBoxes(state);
    const productLayerObjects = D.pickProductLayerObjects(project);

    return productLayerObjects.reduce((acc, productLayerObject) => {
      const [object, objectChanges] = projectObjectsWithChanges.find(
        ([o]) => o.id === productLayerObject.id
      )!;
      return {
        ...acc,
        [object.id]: calcObjectDimensionsOnScene(
          project.orderShapes,
          object.assetId,
          shapesBoundingBoxes[object.assetId],
          productLayerObject.insertionPoint,
          objectChanges.insertionPointOffset
        ),
      };
    }, {});
  }
);

export const selectSelectedObjectMovementBoundaries = createProjectSpecificSelector(
  (project, state) => {
    const objectId = project.selectObjectId;
    const objectToDimensionsOnScene = selectObjectToDimensionsOnScene(
      state,
      project.id
    );
    const selectedProjectObject = selectSelectedProjectObject(
      state,
      project.id
    );

    if (
      !selectedProjectObject ||
      !objectId ||
      !objectToDimensionsOnScene[objectId]
    ) {
      return null;
    }

    const selectedObjectDimensionsOnScene = objectToDimensionsOnScene[objectId];

    if (!selectedObjectDimensionsOnScene) {
      return null;
    }

    const [projectObject, projectObjectChanges] = selectedProjectObject;
    const productLayerObject = D.pickProductLayerObjects(project).find(
      (x) => x.id === projectObject.id
    )!;

    const [
      movementBoundariesLeft,
      movementBoundariesTop,
      movementBoundariesRight,
      movementBoundariesBottom,
    ] = productLayerObject.movementBoundaries;

    const [
      insertionPointOffsetX,
      insertionPointOffsetY,
    ] = projectObjectChanges.insertionPointOffset;

    return {
      left:
        selectedObjectDimensionsOnScene.selectionAreaX +
        movementBoundariesLeft -
        insertionPointOffsetX,
      top:
        selectedObjectDimensionsOnScene.selectionAreaY +
        movementBoundariesTop -
        insertionPointOffsetY,
      width:
        selectedObjectDimensionsOnScene.selectionAreaWidth +
        Math.abs(movementBoundariesLeft) +
        movementBoundariesRight,
      height:
        selectedObjectDimensionsOnScene.selectionAreaHeight +
        Math.abs(movementBoundariesTop) +
        movementBoundariesBottom,
    };
  }
);

export function selectInteractionMode(state: AppState) {
  return selectFeatureState(state).data.interactionMode;
}

export function selectProducePNGPreviewComm(state: AppState) {
  return selectFeatureState(state).communications.producePNGPreview;
}

export const selectLayerSetChangeDirectionOnRemoval = createProjectSpecificSelector(
  (project, state): LayerSetChangeDirection | null => {
    const selectedAdminTemplate = selectSelectedTemplateInfo(state)
      ?.template as AdminTemplate;

    if (selectedAdminTemplate === null) {
      return null;
    }

    if (selectedAdminTemplate.layerSets.length <= 1) {
      return null;
    }

    const { selectedLayerSetIndex } = project;

    const selectedLayerIsLast =
      selectedLayerSetIndex === selectedAdminTemplate.layerSets.length - 1;
    const direction = selectedLayerIsLast ? 'back' : 'forward';
    return direction;
  }
);

export const selectShouldDisableRemoveProductsComposition = createProjectSpecificSelector(
  (_, state): boolean => {
    const selectedAdminTemplate = selectSelectedTemplateInfo(state)
      ?.template as AdminTemplate;

    if (selectedAdminTemplate === null) {
      return true;
    }

    return selectedAdminTemplate.layerSets.length <= 1;
  }
);

export const selectInsertionPointValues = createProjectSpecificSelector(
  (project, state): InsertionPointValues | null => {
    const selectedProjectObject = selectSelectedProjectObject(
      state,
      project.id
    );

    if (selectedProjectObject && project) {
      const templateObject = selectTemplateObject(
        state,
        project.id,
        selectedProjectObject[0].id
      );
      const [, projectObjectChanges] = selectedProjectObject;
      return {
        movementBoundaries: projectObjectChanges.movementBoundaries,
        insertionPointDepth: templateObject.insertionPoint[2],
      };
    }

    return null;
  }
);

export function selectSaveTemplateChangesComm(
  state: AppState,
  templateId: string
) {
  return (
    selectFeatureState(state).communications.saveTemplateChanges[templateId] ||
    remoteNotAsked
  );
}

export function selectApplyBackgroundInfluenceChangesComm(
  state: AppState,
  objectId?: string
) {
  if (objectId === undefined) return remoteNotAsked;

  return (
    selectFeatureState(state).communications.applyBackgroundInfluenceChanges[
      objectId
    ] || remoteNotAsked
  );
}

export function selectAreMovementBoundariesVisible(state: AppState) {
  return selectFeatureState(state).data.areMovementBoundariesVisible;
}

export function getBaseCanvas(
  canvasWidth: number,
  canvasHeight: number,
  opacity?: number
) {
  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ctx = canvas.getContext('2d')!;
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  if (opacity !== undefined) {
    ctx.globalAlpha = opacity;
  }
  return canvas;
}

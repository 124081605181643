import { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'clsx';

import {
  briefTemplateActions,
  briefTemplateSelectors,
} from 'features/briefTemplate';
import { AppState } from 'store/types';
import { foldRemote, mapRemote } from 'shared/remoteData';
import { asyncDetermineImageDimensions } from 'features/lineupProducts/Editor/viewerRendering/asyncDetermineImageDimensions'; // TODO: move into utils/asyncDetermineImageDimensions
import { lineupProductsSelectors } from 'features/lineupProducts';
import { getCanvasWithImage } from 'features/lineupProducts/Editor/viewerRendering/getCanvasWithImage';
import { ClassesProp } from 'shared/style';

import {
  UploadBackground,
  UploadData,
} from './UploadBackground/UploadBackground';
import { validateFileAgainstAllowedMimeTypes } from './utils';

import css from './ChooseBackground.module.scss';

type Props = ReturnType<typeof mapState> &
  typeof actionsMap & { groupId?: string } & ClassesProp<'root'>;

function ChooseBackgroundView(props: Props) {
  const {
    backgrounds,
    loadBackgrounds,
    uploadBackground,
    order,
    selectBackground,
    uploadBackgroundComm,
    classes,
  } = props;
  const [imageUploadError, setImageUploadError] = useState<string | undefined>(
    undefined
  );
  const [wasBackgroundSelected, setWasBackgroundSelected] = useState(false);

  useEffect(() => {
    loadBackgrounds();
  }, [loadBackgrounds]);

  const handleBackgroundUpload = useCallback(
    async ({ image, imageName, file }: UploadData) => {
      setImageUploadError(undefined);

      const mimeTypeValidation = validateFileAgainstAllowedMimeTypes({
        file,
        mimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
      });

      if (!mimeTypeValidation.isValid) {
        setImageUploadError(mimeTypeValidation.message);
        return;
      }

      mapRemote(order, async () => {
        try {
          const imgDimensions = await asyncDetermineImageDimensions(image);

          if (imgDimensions.height >= 1000 && imgDimensions.width >= 1000) {
            uploadBackground({
              background: image,
              name: imageName,
              backgroundWidth: imgDimensions.width,
              backgroundHeight: imgDimensions.height,
            });
          } else {
            setImageUploadError(
              'Image resolution is expected to be minimum 1000x1000 px.'
            );
          }
        } catch {
          setImageUploadError(`Something went wrong`);
        }
      });
    },
    [uploadBackground, order]
  );

  const handleBackgroundSelect = useCallback(
    async (backgroundUrl: string) => {
      setWasBackgroundSelected(true);
      const [, width, height] = await getCanvasWithImage(backgroundUrl);
      setWasBackgroundSelected(false);
      selectBackground({ width, height, backgroundUrl });
    },
    [selectBackground]
  );

  function renderInfoMessage(message: string) {
    return <p className={css.infoMessage}>{message}</p>;
  }

  return (
    <div className={cx(css.chooseBackground, classes?.root)}>
      <UploadBackground
        error={imageUploadError}
        uploadCommunication={uploadBackgroundComm}
        onUpload={handleBackgroundUpload}
      />
      {foldRemote(backgrounds, {
        onNotAsked: () => null,
        onPending: () => renderInfoMessage('Loading backgrounds...'),
        onFailure: (error) => renderInfoMessage(error),
        onSuccess: (backgrounds) =>
          backgrounds.length === 0 ? (
            renderInfoMessage(`You haven't uploaded any backgrounds yet.`)
          ) : (
            <section className={css.previouslyUploadedBackgrounds}>
              <h4 className={css.previouslyUploadedBackgroundsHeader}>
                {wasBackgroundSelected
                  ? 'Creating the briefing...'
                  : 'Previously uploaded backgrounds:'}
              </h4>
              <ul className={css.backgroundsGrid}>
                {backgrounds.map((x) => (
                  <li className={css.backgroundsGridItem} key={x.id}>
                    <div
                      onClick={() =>
                        !wasBackgroundSelected && handleBackgroundSelect(x.url)
                      }
                      key={x.id}
                      style={{ backgroundImage: `url(${x.thumbnail})` }}
                      className={cx(
                        css.backgroundThumbnail,
                        wasBackgroundSelected && css.disabled
                      )}
                    />
                  </li>
                ))}
              </ul>
            </section>
          ),
      })}
    </div>
  );
}

function mapState(state: AppState) {
  return {
    backgrounds: briefTemplateSelectors.selectBackgrounds(state),
    uploadBackgroundComm: briefTemplateSelectors.selectUploadBackgroundComm(
      state
    ),
    order: lineupProductsSelectors.selectOrder(state),
  };
}

const actionsMap = {
  loadBackgrounds: briefTemplateActions.loadBackgrounds,
  uploadBackground: briefTemplateActions.uploadBackground,
  selectBackground: briefTemplateActions.selectBackground,
};

export const ChooseBackground = connect(
  mapState,
  actionsMap
)(ChooseBackgroundView);

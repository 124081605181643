import {
  AdminTemplate,
  ATLayerObject,
  ATProductLayerObject,
} from './adminTemplate';

export type ShapeBoundingBoxDetails = {
  assetId: string;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  boundingBoxHeight: number;
  shapeHeight: number;
  shapeWidth: number;
  imageWidth: number;
  imageHeight: number;
};

export type Shape = {
  id: string;
  name: string;
  assetId: string;
  thumbnailUrl: string;
  physicalHeight: number;
  horizontalRotationStep: number;
  verticalRotationStep: number;
};

export type LayerSetLevelObjectChanges = {
  angle: {
    vertical: number;
    horizontal: number;
  };
  insertionPointOffset: InsertionPointOffset;
  hasBackgroundInfluence: boolean;
  backgroundOpacity: number;
  shadow: number;
  reflection: number;
  position: ShapePosition;
  movementBoundaries: MovementBoundaries;
  backgroundBaseColor: string;
};

export type ProjectObject = {
  id: string;
  name: string;
  assetId: string;
  artworkId: string | null;
  imgUrl: string | null;
  thumbnailUrl: string;
};

export type InsertionPointOffset = [x: number, y: number];

export type SceneDimensions = {
  widthDownscaleFactor: number;
  heightDownscaleFactor: number;
  width: number;
  height: number;
};

export type Project = {
  id: string;
  template: Template;
  sceneDimensions: SceneDimensions;
  selectedLayerSetId: string;
  selectedLayerSetIndex: number;
  selectObjectId: string | null;
  objects: ProjectObject[];
  objectsChangesInLayerSets: {
    [layerSetId: string]: {
      [objectId: string]: LayerSetLevelObjectChanges;
    };
  };
  orderShapes: Shape[]; // in different projects shapes will have different physicalHeights
  isDownscaled: boolean;
  sceneLoader: {
    shouldShow: boolean;
    message: string;
  };
};

export type MovementBoundaries = [
  left: number,
  up: number,
  right: number,
  down: number
];

export type LayerObjectBase = {
  id: string;
  layerId: string;
};

export type BackgroundLayerObject = {
  type: 'background';
  imgKey: string;
  id: string;
};

export type ImageLayerObject = {
  type: 'image';
  imgKey: string;
  coords: [x: number, y: number];
  resolution: [width: number, height: number];
} & LayerObjectBase;

export type ProductLayerObject = Pick<
  ATProductLayerObject,
  'id' | 'type' | 'movementBoundaries' | 'influencedByLayers' | 'insertionPoint'
> & {
  assetId: string | null;
  artworkId: string | null;
  imgUrl: string | null;
};

export type InsertionPoint = [x: number, y: number, depthScale: number];

export type LayerObject =
  | BackgroundLayerObject
  | ImageLayerObject
  | ProductLayerObject;

export type Layer = {
  id: string;
  objects: LayerObject[];
};

export type LayerSet = {
  id: string;
  layers: Layer[];
};

// TODO: write a migration to rename custom to briefing, predefined to regular
export type TemplateType = 'predefined' | 'custom';

export type Template = {
  id: string;
  thumbnail: string;
  type: TemplateType;
  pixelsInOneRelativeUnit: number;
  resolution: [number, number];
  imgKeyToUrlMap: Record<string, string>;
  layers: Layer[];
};

export function isProductLayerObject(x: LayerObject): x is ProductLayerObject {
  return x.type === 'product';
}

export function isATProductLayerObject(
  x: ATLayerObject
): x is ATProductLayerObject {
  return x.type === 'product';
}

export function isBackgroundLayerObject(
  x: LayerObject | ATLayerObject
): x is BackgroundLayerObject {
  return x.type === 'background';
}

export function isATBackgroundLayerObject(
  x: ATLayerObject
): x is BackgroundLayerObject {
  return x.type === 'background';
}

export function selectImageUrl(template: Template, imgKey: string): string {
  return template.imgKeyToUrlMap[imgKey];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isProject(x: any): x is Project {
  return typeof x.template === 'object';
}

export function pickProductLayerObjects(project: Project): ProductLayerObject[];
export function pickProductLayerObjects(
  template: Template
): ProductLayerObject[];
export function pickProductLayerObjects(layers: Layer[]): ProductLayerObject[];
export function pickProductLayerObjects(
  source: Project | Template | Layer[]
): ProductLayerObject[] {
  let layers: Layer[];

  if (isProject(source)) {
    layers = source.template.layers;
  } else if (Array.isArray(source)) {
    layers = source;
  } else {
    layers = source.layers;
  }

  return layers.flatMap((x) => x.objects).filter(isProductLayerObject);
}

export function pickATProductLayerObjects(
  template: AdminTemplate,
  layerSetId: string
): ATProductLayerObject[] {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return template.layerSets
    .find((x) => x.id === layerSetId)!
    .layers.flatMap((x) => x.objects)
    .filter(isATProductLayerObject);
}

import { MovementBoundaries } from '@domain/template';
import { useCallback } from 'react';

import { Range } from 'shared/components/Slider/Range';
import { Slider } from 'shared/components/Slider/Slider';
import { SliderInput } from 'shared/components/Slider/SliderInput';
import { ClassesProp } from 'shared/style';

import css from './InsertionPointControls.module.scss';
import { RightPanelProps } from './RightPanel';

type Props = {
  sceneWidth: number;
  sceneHeight: number;
  movementBoundaries: MovementBoundaries;
  insertionPointDepth: number;
  objectId: string;
  projectId: string;
} & ClassesProp<'root'> &
  Pick<RightPanelProps, 'updateObject'>;

export function InsertionPointControls(props: Props) {
  const {
    classes,
    movementBoundaries,
    sceneHeight,
    sceneWidth,
    updateObject,
    objectId,
    projectId,
    insertionPointDepth,
  } = props;
  const [left, up, right, down] = movementBoundaries;

  const handleHorizontalMovementBoundariesChange = useCallback(
    ([newLeft, newRight]: [number, number]) => {
      updateObject({
        objectId,
        projectId,
        movementBoundaries: [newLeft, up, newRight, down],
      });
    },
    [updateObject, objectId, projectId, up, down]
  );

  const handleVerticalMovementBoundariesChange = useCallback(
    ([newUp, newDown]: [number, number]) => {
      updateObject({
        objectId,
        projectId,
        movementBoundaries: [left, newUp, right, newDown],
      });
    },
    [updateObject, objectId, projectId, left, right]
  );

  const handleInsertionPointDepthChange = useCallback(
    (newDepth: number) => {
      updateObject({
        objectId,
        projectId,
        insertionPointDepth: newDepth,
      });
    },
    [updateObject, objectId, projectId]
  );

  return (
    <div className={classes?.root}>
      <h4 className={css.title}>Adjust movement boundaries</h4>
      <h6 className={css.controlTitle}>Horizontal Movement (left/right)</h6>
      <Range
        value={[left, right]}
        min={-sceneWidth}
        max={sceneWidth}
        step={1}
        onChange={(value) =>
          handleHorizontalMovementBoundariesChange(value as [number, number])
        }
      />
      <h6 className={css.controlTitle}>Vertical Movement (up/down)</h6>
      <Range
        value={[up, down]}
        min={-sceneHeight}
        max={sceneHeight}
        step={1}
        onChange={(value) =>
          handleVerticalMovementBoundariesChange(value as [number, number])
        }
      />
      <h6 className={css.controlTitle}>Depth</h6>
      <Slider
        min={0.1}
        max={1}
        step={0.01}
        value={insertionPointDepth}
        onChange={handleInsertionPointDepthChange}
        Input={SliderInput}
        inputValueType="percent-placeholder"
      />
    </div>
  );
}
